import { Component, Input } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.css']
})
export class ProfileButtonComponent {
  @Input()
  accountInfo!: AccountInfo | null;

}
