import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration-service-explorer',
  templateUrl: './configuration-service-explorer.component.html',
  styleUrls: ['./configuration-service-explorer.component.css']
})
export class ConfigurationServiceExplorerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href = "https://portal.azure.com/#@payfusion.onmicrosoft.com/resource/subscriptions/97b80fa5-3f9d-4e7d-a854-d912147dfa5e/resourceGroups/digital-menu-board-resource/providers/Microsoft.AppConfiguration/configurationStores/flowhub-menu-app-configuration/kvs";
  }

}
