<div class="container">
  <form [formGroup]="configForm" name="configForm" *ngIf="configForm" (ngSubmit)="onSubmit($event)">
   
    <div class="form-group row">
      <div class="col-lg-4">
        <label for="apiBaseURl">API Base URL:</label>
        <input class="form-control" name="apiBaseUrl" formControlName="apiBaseUrl" placeholder="http://host">
      </div>
      <div class="col-lg-4">
        <label for="productApiPath">Product API Path:</label>
        <input class="form-control" name="productApiPath" formControlName="productApiPath" placeholder="/api">
      </div>

      <div class="col-lg-4">
        <label for="apiKey">API Key:</label>
        <input class="form-control" name="apiKey" formControlName="apiKey" placeholder="">
      </div>
    </div>
    
    <div class="form-group row">
      <div class="col-lg-4">
        <label for="clientId">Client ID:</label>
        <input class="form-control" name="clientId" formControlName="clientId" placeholder="">
      </div>

      <div class="col-lg-4">
        <label for="locationId">Location ID:</label>
        <input class="form-control" name="locationId" formControlName="locationId" placeholder="">
      </div>
    </div>
    
    <div class="form-group row">
       <br>
    </div>
    
    <div class="form-group row">
      <button class="btn btn-primary btn-lg btn-block" type="submit">Save</button>
    </div>
  </form>
</div>