import { Component, Input, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Profile } from '../../models/profile';
import { AccountInfo } from '@azure/msal-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public profile: Profile = {};
  public photoUrl: string = "";
  protected accounts: AccountInfo[] = [];

  @Input()
  parent!: ModalDirective;

  constructor(private authService: MsalService  ) { }

  ngOnInit() {
    this.loadAccounts();
  }

  loadAccounts() {
    this.accounts = this.authService.instance.getAllAccounts();
  }

  logout() {
    this.authService.logoutRedirect();
    this.close();
  }

  loginUser(account: AccountInfo | null) {
    this.authService.instance.setActiveAccount(account);
    this.authService.instance.loginRedirect();
    this.close();

  }

  showMyAccount(): void {
    window.open("https://myaccount.microsoft.com/");
    this.close();
  }

  close() {
    this.parent.hide();
  }
}
