import { Configuration } from '../../models/config/configuration';

export const MockConfig: Configuration =  {
   authConfig:{
    logLevel: "Info",
    configConnectString: "Endpoint=https://cheap-weed-menus-appconfig.azconfig.io;Id=tyjA;Secret=1FgL95lHkXViZX4Qf2GcRqn26mhTYDVYany8ToXpTnO68AzrdUUEJQQJ99AHAC8vTInIcYexAAACAZACsteF",
    clientId: "af1486e0-a27f-4c8d-8503-0752d90ce72d",
    tenantId: "445012c4-563a-4795-84d0-f7473a3197e0",
    authority: "https://login.microsoft.com/445012c4-563a-4795-84d0-f7473a3197e0",
    scopes: "openid,email,profile",
    redirect_uri: "https://www.cheapweedmenu.com",
    logout_redirect_uri: "https://www.cheapweedmenu.com",
    prompt: "login"
   },
    flowHubConfig:{ 
      apiBaseUrl: "https://api.dev.cheapweedmenu.com",
      productApiPath: "/api",
      apiKey: "8b66d117-5d23-4b81-8c2f-52142c67d0cd",
      clientId: "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      locationId: "10f19fc5-31d0-4d76-bad4-ad593c9803ae"
    },
    menuBoardConfig: {
      amountToScroll: 1,
      autoScrollTimeout: 1,
      dataAgeThreshold: 300000,
    },
    multiColumnMenuConfig: {
      columnsPerPage: 2,
      productsPerPage: 60,
      delayPerPage: 30,
      fontSize: "small",
      maxDataAge: 300,
    },
    footerCarouselConfig: {
      slidesToShow: 3, 
      slidesToScroll: 1, 
      dots: false,
      infinite: true, 
      autoplay: true, 
      autoplaySpeed: 10000, 
      speed: 500, 
      arrows: false
    },
    footerCarouselSlideConfig: [
      {image: "../../../assets/images/sunday.png", title: "CBD Sunday", description: "20% off all CBD products & house pre-rolls", highlighted: false, textColor: "white", backgroundColor: "black"},
      {image: "../../../assets/images/new-monday.jpg", title: "Edible Monday", description: "10% off edibles", highlighted: false, textColor: "white", backgroundColor: "black"},
      {image: "../../../assets/images/tuesday.png", title: "Doobie Tuesday", description: "10% off all pre-rolls & packs<", highlighted: false, textColor: "black", backgroundColor: "white"},
      {image: "../../../assets/images/wednesday.png", title: "Weed Crush Wednesday", description: "10% off all jar bud", highlighted: false, textColor: "black", backgroundColor: "white"},
      {image: "../../../assets/images/thursday.png", title: "Thirsty Thursday", description: "10% off Tinctures, RSO & cartridges", highlighted: false, textColor: "black", backgroundColor: "white"},
      {image: "../../../assets/images/friday.png", title: "Fire Friday", description: "10% off all jar bud", highlighted: false, textColor: "black", backgroundColor: "white"},
      {image: "../../../assets/images/saturday.png", title: "Shatterday Saturday", description: "10% off all concentrate", highlighted: false, textColor: "white", backgroundColor: "black"}
    ]
  }