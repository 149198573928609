import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductApiResponse } from '../models/product-api-response';
import { firstValueFrom, Observable, of } from 'rxjs';
import { Product } from '../models/product';
import { map } from 'rxjs/operators';
import { countBy, keys }  from 'lodash';
import { ConfigService } from './config.service';
import { MockProductApiResponse } from './mocks/mock-productapi-response';
import { Configuration } from '../models/config/configuration';
import { FlowHubConfig } from '../models/config/flowHub-config';

@Injectable()

export class FlowHubApiService {

  private readonly mockData: boolean = false;

  protected getHttpHeaders(flowHubConfig: FlowHubConfig): HttpHeaders {
    return new HttpHeaders()
    .set('key', flowHubConfig.apiKey)
    .set('clientId', flowHubConfig.clientId)
    .set('locationId', flowHubConfig.locationId);
  }

  protected getApiUrl(flowHubConfig: FlowHubConfig): string {
    return flowHubConfig.apiBaseUrl + flowHubConfig.productApiPath;
  }
  
  constructor(protected configService: ConfigService, protected httpClient: HttpClient) {} 

  async getProductsAsync1(): Promise<ProductApiResponse> {
    const flowHubConfig: FlowHubConfig = (await this.configService.getConfigAsync()).flowHubConfig;
    return new Promise((resolve, reject) => {
      if(this.mockData){
        resolve(MockProductApiResponse);
      } else {
        this.httpClient.get<ProductApiResponse>(this.getApiUrl(flowHubConfig), { headers: this.getHttpHeaders(flowHubConfig) }).subscribe(
            (response: ProductApiResponse) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
        );
      }
    });
  }

  async getObservableProductsAsync(): Promise<Observable<ProductApiResponse>> {
    const config: Configuration = await this.configService.getConfigAsync();
    const apiUrl = config.flowHubConfig.apiBaseUrl + config.flowHubConfig.productApiPath;
    const headers = new HttpHeaders()
      .set('key', config.flowHubConfig.apiKey)
      .set('clientId', config.flowHubConfig.clientId)
      .set('locationId', config.flowHubConfig.locationId);
    return this.httpClient.get<ProductApiResponse>(apiUrl, { headers: headers });
  }
  
  async getProductsAsync(): Promise<ProductApiResponse> {
    const config: Configuration = await this.configService.getConfigAsync();
    const apiUrl = config.flowHubConfig.apiBaseUrl + config.flowHubConfig.productApiPath;
    const headers = new HttpHeaders()
      .set('key', config.flowHubConfig.apiKey)
      .set('clientId', config.flowHubConfig.clientId)
      .set('locationId', config.flowHubConfig.locationId);
    return firstValueFrom(this.httpClient.get<ProductApiResponse>(apiUrl, { headers: headers }));
  }
  
  getObservableProducts(): Observable<ProductApiResponse> {
    let configResponse!: Configuration;
    this.configService.getConfigAsync().then((response: Configuration) => { configResponse = response;});
    const flowHubConfig: FlowHubConfig = configResponse.flowHubConfig;
    const apiUrl = flowHubConfig.apiBaseUrl + flowHubConfig.productApiPath;
    const headers = new HttpHeaders()
      .set('key', flowHubConfig.apiKey)
      .set('clientId', flowHubConfig.clientId)
      .set('locationId', flowHubConfig.locationId);
    return this.httpClient.get<ProductApiResponse>(apiUrl,{ headers: headers });
  }
  

  async getProductsByCategoryAsync(category: string) : Promise<Product[]> {
    return firstValueFrom(this.getObservableProducts().pipe(
      map(inventory => {
        return inventory.data.filter((product: Product) => product.category === category);
    })));
  }
  
  getProductsByCategory(category: string, inventoryResponse : ProductApiResponse | null): Observable<Product[]> {
    if(inventoryResponse != null){
      return of(inventoryResponse?.data.filter((product: Product) => product.category === category));
    }
    else{
      return this.getObservableProducts().pipe(
        map(inventory => {
          return inventory.data.filter((product: Product) => product.category === category);
        })
      )
    }
  }

  async getProductsByBrandAsync(brand: string, inventoryResponse : ProductApiResponse | null): Promise<Product[]> {
    if(inventoryResponse != null){
      return inventoryResponse?.data.filter((product: Product) => product.brand === brand);
    }
    else{
      return firstValueFrom(this.getObservableProducts().pipe(
        map(inventory => {
          return inventory.data.filter((product: Product) => product.brand === brand);
        })));
    }
  }
  
  getProductsByBrand(brand: string, inventoryResponse : ProductApiResponse | null): Observable<Product[]> {
    if(inventoryResponse != null){
      return of(inventoryResponse?.data.filter((product: Product) => product.brand === brand));
    }
    else{
      return this.getObservableProducts().pipe(
        map(inventory => {
          return inventory.data.filter((product: Product) => product.brand === brand);
        })
      )
    }
  }
  
  async getObservableCategoriesAsync(): Promise<Observable<string[]>>{
    const productApiResponse: ProductApiResponse = await this.getProductsAsync();
    return of(keys(countBy(productApiResponse.data, (product: Product) => product.category)));
  }

  async getCategoriesAsync(): Promise<string[]>{
    const productApiResponse: ProductApiResponse = await this.getProductsAsync();
    return keys(countBy(productApiResponse.data, (product: Product) => product.category));
  }
  
  async getObservableBrandsAsync(): Promise<Observable<string[]>> { 
    const productApiResponse: ProductApiResponse = await this.getProductsAsync();   
    return of(keys(countBy(productApiResponse.data, (product: Product) => product.brand)));
  }
  async getBrandsAsync(): Promise<string[]> { 
    const productApiResponse: ProductApiResponse = await this.getProductsAsync();   
    return keys(countBy(productApiResponse.data, (product: Product) => product.brand));
  }
}
