/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/modules/app.module';
import { enableProdMode } from '@angular/core';

//if (false === false) {
    enableProdMode();
  //}
  
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));