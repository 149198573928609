import { AfterViewInit, Component, Input } from '@angular/core';
import { CarouselConfig } from '../../models/config/carousel-config';
import { ConfigService } from '../../services/config.service';
import { FormGroup, FormBuilder, Validators } from  '@angular/forms';
import { Configuration } from 'src/app/models/config/configuration';

@Component({
  selector: 'app-footer-carousel-config',
  templateUrl: './footer-carousel-config.component.html',
  styleUrls: ['./footer-carousel-config.component.css']
})
export class FooterCarouselConfigComponent  implements AfterViewInit{
  configForm!: FormGroup;
  
  @Input()
  public footerCarouselConfig!: CarouselConfig;

  constructor(private formBuilder: FormBuilder, private configService: ConfigService) { }
  ngAfterViewInit(): void {
    this.createConfigForm(); 
  }

  createConfigForm()   {
    this.configForm = this.formBuilder.group({
      slidesToShow: [this.footerCarouselConfig.slidesToShow, Validators.required],
      slidesToScroll: [this.footerCarouselConfig.slidesToScroll, Validators.required],
      dots: [this.footerCarouselConfig.dots, Validators.required],
      infinite: [this.footerCarouselConfig.infinite, Validators.required],
      autoplay: [this.footerCarouselConfig.autoplay, Validators.required],
      autoplaySpeed: [this.footerCarouselConfig.autoplaySpeed, Validators.required],
      speed: [this.footerCarouselConfig.speed, Validators.required],
      arrows: [this.footerCarouselConfig.arrows, Validators.required]
    });
  }

  async onSubmit($event: SubmitEvent) {
    $event.preventDefault();
    this.footerCarouselConfig.slidesToShow =  this.configForm.controls['slidesToShow'].value;
    this.footerCarouselConfig.slidesToScroll =  this.configForm.controls['slidesToScroll'].value;
    this.footerCarouselConfig.dots =  this.configForm.controls['dots'].value;
    this.footerCarouselConfig.infinite =  this.configForm.controls['infinite'].value;
    this.footerCarouselConfig.autoplay =  this.configForm.controls['autoplay'].value;
    this.footerCarouselConfig.autoplaySpeed =  this.configForm.controls['autoplaySpeed'].value;
    this.footerCarouselConfig.speed =  this.configForm.controls['speed'].value;
    this.footerCarouselConfig.arrows =  this.configForm.controls['arrows'].value;
    
    const config: Configuration = await this.configService.getConfigAsync();
    Object.assign(config.footerCarouselConfig, this.footerCarouselConfig);
    await this.configService.saveConfigAsync(config);
  }
}

