import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FlowHubApiService } from '../../services/flowhub-api.service';
import { Slide } from '../../models/slide';
import { CarouselConfig } from '../../models/config/carousel-config';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Observable, of, Subscription } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-category-carousel',
  templateUrl: './category-carousel.component.html',
  styleUrls: ['./category-carousel.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [ trigger('fade', [
    transition('* => *', [
      style({ opacity: 0 }),
      animate(1000, style({ opacity: 1 }))
    ]),
    transition('* => *', [
      animate(50, style({ opacity: 0 }))
    ])
  ])
 ]
})
export class CategoryCarouselComponent implements OnInit, OnDestroy {
  @ViewChild('slickModal') 
  slickModal!: SlickCarouselComponent;
  
  @ViewChildren("slideElement")
  slideElements: ElementRef[] = [];
  
  @Input() 
  ShowCarousel: boolean = false;
  @Input() 
  showCarousel: Observable<boolean> = of(this.ShowCarousel);
  showCarouselSubscription!: Subscription;
  
  @Input()
  public get value(): string { return this._category;}
  public set value(value: string) { this._category = value; }
  private _category: string = "";
  public categorySubscription?: Subscription;
  
  @Input()
  public slideConfig: CarouselConfig = {
    "slidesToShow": 10, 
    "slidesToScroll": 1, 
    "dots": false,
    "infinite": true, 
    "autoplay": false, 
    "autoplaySpeed": 10000, 
    "speed": 500, 
    "arrows": true
  };
  
  // Slides
  public slides: Slide[] = [];
  slideSubscription!: Subscription;
  //Slides$!: BehaviorSubject<Slide[]>; // Our observable/subject is the return data projected into slides from the service
  
  constructor(private flowhubApiService: FlowHubApiService) { }
  
  async ngOnInit(): Promise<void> {
    this.showCarouselSubscription = this.showCarousel.subscribe(async (showCarousel: boolean) => {
      
      this.ShowCarousel = showCarousel;
      if(showCarousel) {
        this.slideSubscription = (await this.flowhubApiService.getObservableCategoriesAsync())
          .subscribe( (categories: string[]) => { 
            this.slides = [];
            categories.forEach((category: string) => {
              const slide: Slide = {
                image: "/assets/images/" + category.toLowerCase() + '.avif',
                title: category,
                description: '',
                highlighted: false,
                textColor: 'black',
                backgroundColor: 'white'
              };
              this.slides.push(slide);
            });
          });
      }
      else{
        if (this.slideSubscription) this.slideSubscription.unsubscribe();
      }
    });
  }
   
  ngOnDestroy(): void {
    if (this.slideSubscription) this.slideSubscription.unsubscribe();
    if (this.showCarouselSubscription) this.showCarouselSubscription.unsubscribe();
  }

  getSlideStyle(slide: Slide) {
    return {
      'color': slide.textColor,
      'background-color': slide.backgroundColor,
      'background-image': slide.image ? `url(${slide.image})` : 'none',
    };
  }
  
  onSelectSlide(selectedSlide: HTMLDivElement)  {
    this.slideElements.forEach(element => { 
      if (element.nativeElement.classList.contains('category-slide-selected'))
        element.nativeElement.classList.remove('category-slide-selected');
    });
    if (selectedSlide.classList.contains('category-slide'))                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
      selectedSlide.classList.remove('category-slide')
    selectedSlide.classList.add('category-slide-selected');
    this.value = selectedSlide.firstElementChild?.textContent || "";
  }
}
