<div class="container">
  <form [formGroup]="configForm" name="configForm" *ngIf="configForm" (ngSubmit)="onSubmit($event)">
    <div class="form-group row">
      <ngx-datatable class="table table-striped" #dataTable id="dataTable" [loadingIndicator]="showBusyIndicator"
        [rows]="slides" [columnMode]="'force'" [headerHeight]="55" [footerHeight]="55" [scrollbarV]="true"
        [scrollbarH]="true" [rowHeight]="55">
        <ngx-datatable-column name="#" [width]="50">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            {{ rowIndex + 1 }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Image" [width]="250">
          <ng-template width="100%" let-row="row" ngx-datatable-cell-template>
            <input type="url" class="table-column" value="{{ row?.image }}" />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Title" [width]="150">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <input type="text" class="table-column" value="{{ row?.title }}" />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Description" [width]="300">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <input type="text" class="table-column" value="{{ row?.description }}" />"
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Highlight">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <input type="checkbox" class="table-column" value="{{ row?.highlighted }}" />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Text">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <input type="color" class="table-column" value="{{ row?.textColor }}" />
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Background">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <input type="color" class="table-column" value="{{ row?.backgroundColor }}" />
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <div class="form-group row">
      <button class="btn btn-primary btn-lg btn-block" type="submit">
        Save Configuration
      </button>
    </div>
  </form>
</div>