import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '../components/main/main.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { OnlineMenuBoardComponent } from '../components/online-menu-board/online-menu-board.component';
import { DigitalMenuBoardComponent } from '../components/digital-menu-board/digital-menu-board.component';
import { ConfigurationComponent } from '../components/configuration/configuration.component';
import { MultiColumnMenuComponent } from '../components/multi-column-menu/multi-column-menu.component';
import { AnimationMenuComponent } from '../components/animation-menu/animation-menu.component';
import { ConfigurationServiceExplorerComponent } from '../components/configuration-service-explorer/configuration-service-explorer.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { WellKnownComponent } from '../components/well-known/well-known.component';

const routes: Routes = [
  { path: 'home', component: MainComponent },
  { path: 'main', component: MainComponent },
  { path: '.well-known/microsoft-identity-association.json ', component: WellKnownComponent },
  { path: 'autoscroll', component: DigitalMenuBoardComponent, canActivate: [MsalGuard]  },
  { path: 'multicolumn', component: MultiColumnMenuComponent, canActivate: [MsalGuard]  },
  { path: 'animation', component: AnimationMenuComponent, canActivate: [MsalGuard] },
  { path: 'online', component: OnlineMenuBoardComponent, canActivate: [MsalGuard]  },
  { path: 'web', component: OnlineMenuBoardComponent, canActivate: [MsalGuard]  },
  { path: 'setup', component: ConfigurationComponent, canActivate: [MsalGuard]  },
  { path: 'config', component: ConfigurationServiceExplorerComponent, canActivate: [MsalGuard]  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
]
//const isIframe = window !== window.parent && !window.opener;


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
