import { AfterViewInit, Component, Input } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { MenuBoardConfig } from '../../models/config/menu-board-config';
import { FormGroup, FormBuilder, Validators } from  '@angular/forms';
import { Configuration } from 'src/app/models/config/configuration';

@Component({
  selector: 'app-digital-menu-board-config',
  templateUrl: './digital-menu-board-config.component.html',
  styleUrls: ['./digital-menu-board-config.component.css']
})
export class DigitalMenuBoardConfigComponent  implements AfterViewInit{
  configForm!: FormGroup;
  
  @Input()
  public menuBoardConfig!: MenuBoardConfig;

  constructor(private formBuilder: FormBuilder, private configService: ConfigService) { }
  ngAfterViewInit(): void {
    this.createConfigForm(); 
  }

  createConfigForm()   {
    this.configForm = this.formBuilder.group({
      amountToScroll: [this.menuBoardConfig.amountToScroll, Validators.required],
      autoScrollTimeout: [this.menuBoardConfig.autoScrollTimeout, Validators.required],
      dataAgeThreshold: [this.menuBoardConfig.dataAgeThreshold, Validators.required],
    });
  }

  async onSubmit($event: SubmitEvent) {
    $event.preventDefault();
    this.menuBoardConfig.amountToScroll =  this.configForm.controls['amountToScroll'].value;
    this.menuBoardConfig.autoScrollTimeout =  this.configForm.controls['autoScrollTimeout'].value;
    this.menuBoardConfig.dataAgeThreshold =  this.configForm.controls['dataAgeThreshold'].value;

    const config: Configuration = await this.configService.getConfigAsync();
    Object.assign(config.menuBoardConfig, this.menuBoardConfig);
    await this.configService.saveConfigAsync(config);
  }
}

