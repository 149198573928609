import { ProductApiResponse } from "../../models/product-api-response";

export const MockProductApiResponse: ProductApiResponse  = {
  "status": 200,
  "data": [
    {
      "brand": "Portland Cannabis Market",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 25.25,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.25
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "createdAt": "2024-05-06T21:41:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "88caa95f-8a25-4960-ae1f-0894d86e8f02",
      "parentProductName": "Royal Hindu Truffle by Upper Rogue Terraces",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "df363dba-1cd3-402e-b6c3-b3697abed6ee",
      "productName": "Royal Hindu Truffle by Upper Rogue Terraces",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-30T02:42:59.621977Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 146.89,
      "regulatoryId": "1A4010300000D4A000001042",
      "sku": "zddCdpqRvw",
      "speciesName": "Indica",
      "supplierName": "Portland Cannabis Market",
      "terpenes": null,
      "type": null,
      "variantId": "b0ed44e0-68df-44f3-a34a-f05bb5bd8ae0",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ],
      costInMinorUnits: 0
    },
    {
      "brand": "Garden First",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.5
        },
        {
          "lowerRange": 0.1,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.1
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 374,
      "createdAt": "2024-06-14T21:25:49Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "798c79ac-7b88-425d-bd2f-c8684fdac27f",
      "parentProductName": "Donny Burger by Garden First",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "db3b1fdc-f0b3-4936-afae-c0a828abfbaf",
      "productName": "Donny Burger by Garden First",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-30T02:42:36.303028Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 95.8,
      "regulatoryId": "1A4010300016ECD000037910",
      "sku": "FSS0cRLR6G",
      "speciesName": "Indica",
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": null,
      "variantId": "c3beca27-92a6-413a-938f-f8cf699d1b86",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 35.41,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 35.41
        },
        {
          "lowerRange": 0.13,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.13
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-10T21:55:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "dce21699-edbe-4d1b-b5ea-8ed40bb83bc0",
      "parentProductName": "Red Pop Runtz House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "eb130d64-5c51-46fa-8e09-2b7b93218c57",
      "productName": "Red Pop Runtz House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-30T02:20:02.076196Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000071B1000003055",
      "sku": "2lKxnEnwEV",
      "speciesName": "Hybrid",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "da0a17c0-0ad2-4756-83d8-cf5e1b74bdc7",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 460,
      "createdAt": "2024-06-26T22:35:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "dfd5f4d2-e894-45e5-97d3-334808da0bc2",
      "parentProductName": "Mule Extracts NW Berry Kicker 1:1:1 THC/CBD/CBN 100mg",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "f978c416-e0ec-48f4-a691-ef0c1058df78",
      "productName": "Mule Extracts NW Berry Kicker 1:1:1 THC/CBD/CBN 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-30T01:03:30.041873Z",
      "productWeight": 24.5,
      "purchaseCategory": "rec",
      "quantity": 40,
      "regulatoryId": "1A401030001CC8D000248287",
      "sku": "248287",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "57270bc1-44ef-4080-8ba9-f1cb17338b32",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Millerville Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 26.34,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.34
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1540,
      "createdAt": "2024-06-29T03:57:48Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "56523f55-0cae-4c71-b196-ce19d9863373",
      "parentProductName": "Blueberry OG by Alta Gardens",
      "postTaxPriceInPennies": 3501,
      "preTaxPriceInPennies": 2917,
      "priceInMinorUnits": 2917,
      "productDescription": "",
      "productId": "02d805f9-d051-4780-88e8-38b885f0479c",
      "productName": "Blueberry OG by Alta Gardens - Half oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-29T03:57:48.284289Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 29,
      "regulatoryId": "1A401030000A85E000022114",
      "sku": "235EX5Rs4F",
      "speciesName": "Indica",
      "supplierName": "The Dub Wholesale",
      "terpenes": null,
      "type": null,
      "variantId": "abcbd879-f73a-4ff3-8552-ff8841859f5f",
      "variantName": "Half oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 208
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 729
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 1458
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 2917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 5833
        }
      ]
    },
    {
      "brand": "Siren",
      "cannabinoidInformation": [
        {
          "lowerRange": 24.22,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.22
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1400,
      "createdAt": "2024-06-29T02:09:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7f1a0b54-eb07-48bd-aa00-0e01a5eb0b97",
      "parentProductName": "Glazed Apricot Gelato by Siren",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "62464f82-4760-4e9b-966f-6b552f3a689d",
      "productName": "Glazed Apricot Gelato by Siren - Half Oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-29T02:09:55.34084Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300018A25000019887",
      "sku": "rcwD1EIxRa",
      "speciesName": "Sativa",
      "supplierName": "Siren",
      "terpenes": null,
      "type": null,
      "variantId": "ae54e45c-5a25-4de2-b9c7-564eca7fc66b",
      "variantName": "Half Oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams (1g)",
          "pricePerUnitInMinorUnits": 178
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth (3.5g)",
          "pricePerUnitInMinorUnits": 624
        },
        {
          "gramAmount": 7,
          "name": "Quarter (7g)",
          "pricePerUnitInMinorUnits": 1249
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce (14g)",
          "pricePerUnitInMinorUnits": 2500
        },
        {
          "gramAmount": 28,
          "name": "Ounce (28g)",
          "pricePerUnitInMinorUnits": 5000
        }
      ]
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 74.28,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.28
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-05T21:11:19Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bbe8693c-6d8e-4a11-8466-6d3943b3fdbf",
      "parentProductName": "Easy - Dr. Who - 1g Live Resin",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "f0a6fe01-7957-4e78-a205-bdc41e8f9e92",
      "productName": "Easy - Dr. Who - 1g Live Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-28T00:49:01.837149Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000003201",
      "sku": "3201",
      "speciesName": "Indica",
      "supplierName": "Easy Extracts",
      "terpenes": null,
      "type": "Live Resin",
      "variantId": "6d84a8d9-bc91-4473-9cfc-928fe6f3d0ac",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Highsman",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.48,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.48
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 352,
      "createdAt": "2024-06-27T23:11:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cd613427-4ee3-4d92-9b1d-03dbd6afa264",
      "parentProductName": "Dank Prescott",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "b8d19baf-bf14-4fff-84f6-9bbc488417fb",
      "productName": "Dank Prescott",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-27T23:16:47.662072Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 119.5,
      "regulatoryId": "1A4010300044CC9000002312",
      "sku": "sDiJ2V3JJQ",
      "speciesName": "Indica",
      "supplierName": "ASAP BUDS",
      "terpenes": null,
      "type": null,
      "variantId": "1808a104-138e-40ec-8713-730f428c3c65",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 33.09,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 33.09
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 132,
      "createdAt": "2024-06-27T22:28:41Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d7f976a0-57d4-43dc-9f54-827449caac67",
      "parentProductName": "Dunkaloha House Preroll",
      "postTaxPriceInPennies": 650,
      "preTaxPriceInPennies": 542,
      "priceInMinorUnits": 542,
      "productDescription": "",
      "productId": "e5310463-81e9-4f2f-a549-118735185ecb",
      "productName": "Dunkaloha House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T22:28:41.517644Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 35,
      "regulatoryId": "1A40103000071B1000003104",
      "sku": "REmd2p0PUI",
      "speciesName": "Sativa",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "6011341c-df5e-4bdf-bd23-81c7c956f2f0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.13,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.13
        },
        {
          "lowerRange": 27,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 132,
      "createdAt": "2024-06-27T22:22:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e0fff631-d81b-4687-8be3-a333c18e115d",
      "parentProductName": "Super Mario Sunshine House Preroll",
      "postTaxPriceInPennies": 550,
      "preTaxPriceInPennies": 458,
      "priceInMinorUnits": 458,
      "productDescription": "",
      "productId": "3dcb29a6-831c-4692-92a4-f51c2808709f",
      "productName": "Super Mario Sunshine House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T22:22:08.59135Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 53,
      "regulatoryId": "1A40103000071B1000003103",
      "sku": "puMi7SrYLj",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "7d20d46c-2cd5-42b6-a032-05919f3d6157",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-27T22:10:17Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fbb0c479-1cd8-4d9c-80aa-33c1cf9446c8",
      "parentProductName": "Drops Watermelon Chill Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "00915e43-3752-48ea-8188-44ca6240240b",
      "productName": "Drops Watermelon Chill Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T22:10:17.41601Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300032E76000354997",
      "sku": "354997",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "3f595b29-36fc-4df9-9c16-cdd1780443e7",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-27T22:09:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b8c37dfc-c0e1-4519-a2d8-d52d4e8cfc03",
      "parentProductName": "Drops Orange Creative Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "bc2b915f-ff4a-45e1-974e-863c5e50ad61",
      "productName": "Drops Orange Creative Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T22:09:07.187017Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 17,
      "regulatoryId": "1A4010300032E76000354998",
      "sku": "354998",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "719def4b-4b39-46d7-9d78-6213af06fb94",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-27T22:08:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "70d5421f-1106-422a-8afe-c17986fec1e1",
      "parentProductName": "Drops Lime Balanced Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "5515ea39-1456-4a87-a705-0cd364e19e4e",
      "productName": "Drops Lime Balanced Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T22:08:07.575042Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300032E76000353157",
      "sku": "353157",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "a02c8f72-8528-41ea-8a5c-8ba7c6467e66",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 50,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 50
        },
        {
          "lowerRange": 200,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 200
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-27T22:07:01Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "289ac41a-405d-4fd2-b564-482f1d103c56",
      "parentProductName": "Drops Blueberry Relief 4:1 CBD Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "c81b83b2-c339-4550-8943-4da644c7b5fa",
      "productName": "Drops Blueberry Relief 4:1 CBD Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T22:07:01.695732Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A4010300032E76000353153",
      "sku": "353153",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "b0ae76c7-ac6a-4e06-adc4-db9b521c2a03",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 200,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 200
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:55:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c5676857-b4ca-4b92-83b6-d560ee4e4d95",
      "parentProductName": "Drops Blackberry Heavy 2:1 CBD 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "78c39a56-59e8-4a0c-b1c3-c26e3cca00f7",
      "productName": "Drops Blackberry Heavy 2:1 CBD 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:55:54.323702Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A4010300032E76000354996",
      "sku": "354996",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "a66d5d2c-17a0-4cce-9753-0996f2680e8a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 200,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 200
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:54:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "25a769b6-e7d7-4285-ba9e-e36be087fbd8",
      "parentProductName": "Drops Blueberry Relief 4:1 CBD 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "20d330d1-3b65-4d26-a0c4-e88e684b5c32",
      "productName": "Drops Blueberry Relief 4:1 CBD 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:54:43.080898Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300032E76000354995",
      "sku": "354995",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "4772370b-5ee5-4cb0-9646-ca5dec4712bf",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 50,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 50
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:53:26Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5a9934eb-b518-43a2-8e4e-c4150637f780",
      "parentProductName": "Drops Raspberry Rosy 1:2 CBD 20 pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "81282b97-4260-4549-8851-782bb7265370",
      "productName": "Drops Raspberry Rosy 1:2 CBD 20 pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:53:26.036051Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300032E76000354994",
      "sku": "354994",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "d2b0bd0f-e93b-492f-bf98-fb9dc796c0e5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 25,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 25
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:52:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "11038d19-8332-4845-97f3-f05df553abc9",
      "parentProductName": "Drops Strawberry Awake 1:4 CBD 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "cc80d247-1b7f-4ee4-951f-346464528359",
      "productName": "Drops Strawberry Awake 1:4 CBD 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:52:12.553284Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300032E76000354993",
      "sku": "354993",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "78eee1aa-bdca-4cfd-8eb9-f339d360782f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:50:42Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "361a7090-616f-4cd5-b50b-2783d7162c5c",
      "parentProductName": "Drops Lemon Active 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "5caf4819-c83e-4899-9c72-47370f2b7f89",
      "productName": "Drops Lemon Active 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:50:42.455061Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A4010300032E76000354992",
      "sku": "354992",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "13ccf749-3811-4775-a873-996e24b608fa",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:49:37Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "914c78c4-aa7c-44b4-8ee5-0d129689c2d2",
      "parentProductName": "Drops Orange Creative 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "77159824-db25-4dd7-8775-5c1e8607bc7c",
      "productName": "Drops Orange Creative 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:49:37.455553Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A4010300032E76000354991",
      "sku": "354991",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "998c17c0-e32b-4f11-99bb-699bdec66189",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T21:48:06Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2e899620-f6e6-4f46-8d81-5b183e0d25d2",
      "parentProductName": "Drops Lime Balanced 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "a745807b-be53-4a55-8b27-0635f36d93ba",
      "productName": "Drops Lime Balanced 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T21:48:06.17495Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300032E76000354990",
      "sku": "354990",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "8f3002b2-a7c4-414a-9f9a-bd7d45d307de",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Arise Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 21.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.5
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T20:01:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ed544d5f-ca7d-484a-bbaf-a7d97abfc847",
      "parentProductName": "Paper Airplanes - Watermelon Zkittlez - 0.5g 10pk",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "9a7cf49d-236e-447e-b43d-03996f6339e1",
      "productName": "Paper Airplanes - Watermelon Zkittlez - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T20:01:08.859899Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300056221000000544",
      "sku": "0544",
      "speciesName": "Indica",
      "supplierName": "Arise Cannabis",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "e8657068-e93d-429b-b7b7-a8c7b38d8cb0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Arise Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 30.73,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 30.73
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T19:56:59Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "36c3607b-521d-4b19-b7ac-3c65da31b211",
      "parentProductName": "Paper Airplanes - GMO Cookies - 0.5g 10pk",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "44fa17a0-4abe-4461-aa58-4442ddde2259",
      "productName": "Paper Airplanes - GMO Cookies - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:56:59.461279Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A4010300027DE5000028684",
      "sku": "28684",
      "speciesName": "Indica",
      "supplierName": "Arise Cannabis",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "17a0cb97-26d9-44db-9c45-05a5653c1181",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Arise Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 20.54,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.54
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-27T19:52:59Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f362afad-93e2-4a23-9b13-54a4ec88d25d",
      "parentProductName": "Paper Airplanes - Nigerian Silver - 0.5g 10pk",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "725ca602-c03e-4943-aea6-0f1e05534a48",
      "productName": "Paper Airplanes - Nigerian Silver - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:52:59.021663Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300056221000000516",
      "sku": "0516",
      "speciesName": "Sativa",
      "supplierName": "Arise Cannabis",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "5422e566-c41e-4011-bc3c-0d883b7474af",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.45,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.45
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-27T19:10:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f2bad911-1678-45b7-8bf7-4273407e4207",
      "parentProductName": "Chronic Extracts - Orion's Belt - 1g shatter",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "ed92d0f9-8e38-4f68-9006-2bbed9b34845",
      "productName": "Chronic Extracts - Orion's Belt - 1g shatter",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:10:12.147743Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A4010300021E31000004003",
      "sku": "4003",
      "speciesName": "Indica",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Shatter",
      "variantId": "5f5853ea-7161-4137-b301-ac620b872dc2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.8
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-27T19:08:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "868d359c-a4c8-4c3f-9f21-61413c8ef6ed",
      "parentProductName": "Chronic Extracts - Purple Rain - 1g shatter",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "b9bc2daa-bba5-4023-aad3-2784efba2c5a",
      "productName": "Chronic Extracts - Purple Rain - 1g shatter",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:08:54.519076Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300021E31000004005",
      "sku": "4005",
      "speciesName": "Indica",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Shatter",
      "variantId": "169a2763-dc66-4fb9-afb7-5c293850bc51",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 71.46,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.46
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-27T19:06:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bd6be11f-5df4-4ae0-a51b-7375673f36ee",
      "parentProductName": "Chronic Extracts -GMO Cookies - 2g badder",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "567ff5b4-8ce2-4579-ac5c-cc0dc5b1e497",
      "productName": "Chronic Extracts -GMO Cookies - 2g badder",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:06:10.955772Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300021E31000004000",
      "sku": "4000",
      "speciesName": "Indica",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": null,
      "variantId": "16b2f801-24f7-456d-9bf2-f6444247ac96",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.26
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-27T19:04:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9a51a730-3db4-4762-a655-8317aadae894",
      "parentProductName": "Chronic Extracts -MAC Dream - 2g badder",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "8c7687eb-4876-4ce8-be44-fe579bfd0ea6",
      "productName": "Chronic Extracts -MAC Dream - 2g badder",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:04:22.615789Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300021E31000004001",
      "sku": "4001",
      "speciesName": "Hybrid",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "badder",
      "variantId": "f938c3cf-8bdc-4214-a47e-df29b2dd50d4",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 62.09,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 62.09
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-27T19:03:17Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "59378c04-4a44-4b92-8728-fda4822460b5",
      "parentProductName": "Chronic Extracts - Wedding Cake - 2g badder",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "750b407b-017f-4c4f-8539-bbd395cb53d8",
      "productName": "Chronic Extracts - Wedding Cake - 2g badder",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:03:17.292809Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300021E31000004007",
      "sku": "4007",
      "speciesName": "Hybrid",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": null,
      "variantId": "ec1f8d32-a9e7-44b9-8e67-de4674270e22",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.63,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.63
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-27T19:00:41Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f3f8a361-c027-4af6-a835-6db9ee2a25f5",
      "parentProductName": "Chronic Extracts - Tropicana Punch - 1g crumble",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "a03358b1-50f0-40ad-9d76-af7cc878e23a",
      "productName": "Chronic Extracts - Tropicana Punch - 1g crumble",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T19:00:41.882346Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A4010300021E31000004006",
      "sku": "4006",
      "speciesName": "Sativa",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Crumble",
      "variantId": "994f0a38-f8d7-45d9-a74e-cec3ff9b9d76",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 69.99,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 69.99
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-27T18:59:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fc5c04bd-c1d9-424e-9a3b-54969115f623",
      "parentProductName": "Chronic Extracts - Muffin Top - 1g crumble",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "666f4e98-499f-42a6-a024-041420060a00",
      "productName": "Chronic Extracts - Muffin Top - 1g crumble",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T18:59:21.233595Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A4010300021E31000004002",
      "sku": "4002",
      "speciesName": "Hybrid",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Honeycomb",
      "variantId": "99e37f6b-d871-4d0a-85da-097c4ac7aacb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.8
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-27T18:56:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1ac83336-a507-4e4c-be95-dfcfdc29a81b",
      "parentProductName": "Chronic Extracts - Purple Rain - 1g crumble",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "58a878bb-44f0-45d7-8c06-9224eb74a52b",
      "productName": "Chronic Extracts - Purple Rain - 1g crumble",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T18:56:46.605975Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300021E31000004004",
      "sku": "004004",
      "speciesName": "Indica",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Honeycomb",
      "variantId": "32de5309-2409-4f47-aade-5b6bfb52617c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.33,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.33
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-27T16:43:39Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4030632d-fa3b-42b7-a0a0-d5a60f59e159",
      "parentProductName": "Project Gary House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "605ae8ea-f7a8-4058-b2f8-e4b73c6c052b",
      "productName": "Project Gary House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T16:43:39.895707Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000071B1000003102",
      "sku": "gn80QXjAYz",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "bfe80086-5dda-46ab-8bfe-92df4460a031",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "PCM",
      "cannabinoidInformation": [
        {
          "lowerRange": 23.97,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.97
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1078,
      "createdAt": "2024-06-27T16:37:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bb8bdfcf-ffd4-49d0-b726-5ac1f59396ea",
      "parentProductName": "Kold Brew by Blissful Botanicals",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "522a74d2-fd10-49f2-a005-fe9ca0c4017a",
      "productName": "Kold Brew by Blissful Botanicals - Half oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-27T16:41:18.693832Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A40103000010CD000002623",
      "sku": "13co0dsYKe",
      "speciesName": "Indica",
      "supplierName": "Portland Cannabis Market",
      "terpenes": null,
      "type": null,
      "variantId": "3816629e-7f36-4386-87e8-d51763fc3f11",
      "variantName": "Half oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 148
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 520
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 1041
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 2083
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 4167
        }
      ]
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 16.54,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 16.54
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": "21.05,23.54",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "21.05,23.54"
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-27T16:33:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "df52da0c-f660-4066-a139-d917ee6dea12",
      "parentProductName": "Soar House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "c6c21a63-26dc-495d-9821-ed34cbe38935",
      "productName": "Soar House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T16:33:25.600158Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000071B1000003101",
      "sku": "EBCcqxMTdT",
      "speciesName": null,
      "supplierName": "Portland Pot Shop",
      "terpenes": [],
      "type": "House Preroll",
      "variantId": "0c413f1b-4f06-4674-bc97-efbc16ff53fd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 22.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.5
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 44,
      "createdAt": "2024-06-27T16:27:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "186ee931-e0e9-4755-9c85-3a57c3981572",
      "parentProductName": "Trop Cherry House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "3b09f553-6672-4eeb-a229-75a0d139674c",
      "productName": "Trop Cherry House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T16:27:38.903519Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A40103000071B1000003100",
      "sku": "BJQlojmKrx",
      "speciesName": "Sativa",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "322ed159-8902-4850-a84d-4bb58f3c086a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 23.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.26
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 44,
      "createdAt": "2024-06-27T16:22:18Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ad43eb2e-6805-4dc1-8c8f-e0b94c5834a1",
      "parentProductName": "Dosi Punch House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "84e862d9-03d0-4f88-a7a0-4f5312f4d0a1",
      "productName": "Dosi Punch House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T16:22:18.265182Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 22,
      "regulatoryId": "1A40103000071B1000003099",
      "sku": "ud3jmcUt4M",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "076afbdd-1504-4ca7-9bfb-4ac95da2c03a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 34.66,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 34.66
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 198,
      "createdAt": "2024-06-27T16:15:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "279e743b-744e-4f6e-9216-5a03c6edfb19",
      "parentProductName": "Platinum Garlic Cookies House Preroll",
      "postTaxPriceInPennies": 650,
      "preTaxPriceInPennies": 542,
      "priceInMinorUnits": 542,
      "productDescription": "",
      "productId": "d752b2ac-984c-4297-b9cc-2e2b742cd1d2",
      "productName": "Platinum Garlic Cookies House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-27T16:15:10.876992Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A40103000071B1000003098",
      "sku": "PRq3bN9ZCU",
      "speciesName": "Platinum Garlic Cookies by LOWD",
      "supplierName": "Portland Pot Shop",
      "terpenes": [],
      "type": "House Preroll",
      "variantId": "274a34c7-65f1-47a4-bc61-acec7bd2fce3",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Gorge Grown",
      "cannabinoidInformation": [
        {
          "lowerRange": 81.23,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.23
        },
        {
          "lowerRange": 0.15,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.15
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 725,
      "createdAt": "2024-06-03T20:40:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4bc82974-fd41-466d-86fc-0d0d65d1a24e",
      "parentProductName": "Gorge Grown - Garlic Noize Complaint - 2g sugar wax",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "b0a74b9a-ada0-412c-8d99-9e74053045eb",
      "productName": "Gorge Grown - Garlic Noize Complaint - 2g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:57:49.207343Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030003AD69000003660",
      "sku": "3660",
      "speciesName": "Inidca",
      "supplierName": "Gorge Grown",
      "terpenes": null,
      "type": null,
      "variantId": "c9af71c7-baab-4f57-a64f-d1f799f3a7e2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-26T22:40:28Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d840e43b-20b6-42ad-aa1e-c2af273f89c2",
      "parentProductName": "Mule - Dragonfruit 1:1 - 100mg gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "d9b83012-4e2e-4c8b-b67a-6461909b414d",
      "productName": "Mule - Dragonfruit 1:1 - 100mg gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:40:28.062578Z",
      "productWeight": 24.5,
      "purchaseCategory": "rec",
      "quantity": 28,
      "regulatoryId": "1A401030001CC8D000248289",
      "sku": "248289",
      "speciesName": "Hybrid",
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "3476b34c-dd0d-40df-be5a-3e8e91a95ef2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-26T22:38:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "49f9022e-d3da-4500-a8a4-11118622851e",
      "parentProductName": "100mg Twisted Citrus Kicker (Indica)",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "2ce6a7ea-901b-4c7f-9fbe-1b205d7b95a2",
      "productName": "100mg Twisted Citrus Kicker (Indica)",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:38:32.197619Z",
      "productWeight": 12.25,
      "purchaseCategory": "rec",
      "quantity": 49,
      "regulatoryId": "1A401030001CC8D000248286",
      "sku": "248286",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "d7f6c5f3-1b37-4ced-bea5-92800bb6380c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 95.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 95.1
        },
        {
          "lowerRange": 96.6,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 96.6
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T22:30:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "03bb9ba2-6d9f-4366-8934-9597b4d35f7e",
      "parentProductName": "Mule Extracts - Strawberry Lemonade Kicker 1:1:1:1",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "3e74f1a2-6fd5-42e4-85d7-826ba060e4c7",
      "productName": "Mule Extracts - Strawberry Lemonade Kicker 1:1:1:1",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:30:25.830558Z",
      "productWeight": 12.25,
      "purchaseCategory": "rec",
      "quantity": 37,
      "regulatoryId": "1A401030001CC8D000248288",
      "sku": "248288",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "de368106-8b08-43be-b420-5c428c7a58b2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-26T22:26:27Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "343f638c-b4bb-41b8-824a-4661820d6f03",
      "parentProductName": "100mg Pineapple Dreams Mule Kicker (Sativa)",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "dfcdfefe-84ca-4652-9b73-7134ac584910",
      "productName": "100mg Pineapple Dreams Mule Kicker (Sativa)",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:26:27.079906Z",
      "productWeight": 1.23,
      "purchaseCategory": "rec",
      "quantity": 48,
      "regulatoryId": "1A401030001CC8D000248285",
      "sku": "248285",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "20b773fd-20e8-4e56-b1db-885f179c923d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Lifted Northwest",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.2,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.2
        },
        {
          "lowerRange": 75.02,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 75.02
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T22:23:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "857e002e-7bf4-482c-81b2-01f5b40f1acd",
      "parentProductName": "Lifted - Space Cadet - 1G Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "3dd7b081-f113-4cef-b3db-156d34b6d064",
      "productName": "Lifted - Space Cadet - 1G Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:23:57.797152Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A4010300042ACF000038184",
      "sku": "038184",
      "speciesName": "Hybrid",
      "supplierName": "Lifted Northwest",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "43ffcbbd-9cf6-4274-8809-10ec595236f8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Lifted Northwest",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.11,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.11
        },
        {
          "lowerRange": 74.31,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.31
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T22:18:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a3a840ce-2630-4a62-a7b3-1fc1bc3224fb",
      "parentProductName": "Lifted - Gelonade - 1g",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "c0cd1154-1ab9-4272-8ebc-12fc2a3d91ab",
      "productName": "Lifted - Gelonade - 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:18:57.732729Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A4010300042ACF000038183",
      "sku": "038183",
      "speciesName": null,
      "supplierName": "Lifted Northwest",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "19da8713-5640-46fb-a2ac-ea3b9f074dfe",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Lifted Northwest",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.53,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.53
        },
        {
          "lowerRange": 69.95,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 69.95
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T22:16:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "86a2aa7f-6384-4194-845f-1a1e4d65b194",
      "parentProductName": "Lifted - Garlic Scouts - 1G Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "0dac02cf-6328-4c69-ac81-f24e0dd093ac",
      "productName": "Lifted - Garlic Scouts - 1G Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T22:16:12.043598Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300042ACF000038195",
      "sku": "038195",
      "speciesName": "Indica",
      "supplierName": "Lifted Northwest",
      "terpenes": null,
      "type": "Cured Resin Sauce",
      "variantId": "694f7419-1a28-4d55-90c9-958119152e46",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Red Eye Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 78.83,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 78.83
        },
        {
          "lowerRange": 0.13,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.13
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T21:58:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9f7f19a5-d8bc-4ab2-ac7d-d5facdc76112",
      "parentProductName": "Red Eye Extracts - Mimosa - 1G Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "62c2e678-dbfe-4abc-b1b3-b35937e465f6",
      "productName": "Red Eye Extracts - Mimosa - 1G Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:58:32.069822Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 20,
      "regulatoryId": "1A4010300044689000099693",
      "sku": "099693",
      "speciesName": "Sativa",
      "supplierName": "Emerald Valley Wholesale",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "2fa5e2f4-afe5-4d71-9970-f0cab74ef904",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Red Eye Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.13,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.13
        },
        {
          "lowerRange": 73.87,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 73.87
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T21:53:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4d1ff307-af68-4dd9-9e23-a5079c566718",
      "parentProductName": "Red Eye Extracts - Sherb Crasher - 1G Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "6cd38149-88a1-430b-9977-907fa89c31ab",
      "productName": "Red Eye Extracts - Sherb Crasher - 1G Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:53:25.728592Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 15,
      "regulatoryId": "1A4010300044689000099811",
      "sku": "099811",
      "speciesName": null,
      "supplierName": "Emerald Valley Wholesale",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "ef2c4639-494d-4f2d-81c5-6105d9a6444f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Red Eye Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 70.37,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 70.37
        },
        {
          "lowerRange": 5.78,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 5.78
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-26T21:39:00Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c63716bb-d6c5-4311-b9a0-869a68245bf3",
      "parentProductName": "Red Eye Extracts - Forbidden Runtz - 1G Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "6f80ce7a-cdab-48e2-addb-3e8040728192",
      "productName": "Red Eye Extracts - Forbidden Runtz - 1G Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:39:00.968002Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 18,
      "regulatoryId": "1A4010300044689000099722",
      "sku": "099722",
      "speciesName": "indica",
      "supplierName": "Emerald Valley Wholesale",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "7021cbd6-3117-4c42-9485-4c262f18bd83",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Boujee Blendz",
      "cannabinoidInformation": [
        {
          "lowerRange": 896.3,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 896.3
        },
        {
          "lowerRange": 89.63,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 89.63
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-26T21:31:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e476ae5a-97b3-475f-92d1-654a90837b22",
      "parentProductName": "Boujee Blendz - Tutti Frutti - 1g",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "8eb8e71a-7bf5-4261-8973-ddd5071ab584",
      "productName": "Boujee Blendz - Tutti Frutti - 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:31:03.966182Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 20,
      "regulatoryId": "1A4010300044689000101008",
      "sku": "101008",
      "speciesName": null,
      "supplierName": "Emerald Valley Wholesale",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "fd4f69d9-6a35-447a-898a-1bca4f361b0d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Boujee Blendz",
      "cannabinoidInformation": [
        {
          "lowerRange": 900.6,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 900.6
        },
        {
          "lowerRange": 90.06,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 90.06
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-26T21:28:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c1505e44-cf9d-4e33-bba4-de478c8cbff0",
      "parentProductName": "Boujee Blendz Grape Cartridge 1g",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "4127fc35-9708-419e-8359-0d91e4a59fd4",
      "productName": "Boujee Blendz Grape Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:28:47.782209Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 18,
      "regulatoryId": "1A4010300044689000091470",
      "sku": "091470",
      "speciesName": null,
      "supplierName": "Emerald Valley Wholesale",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "8056bf1a-f0a4-4e6a-b532-ae6c2d4c0222",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.16,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.16
        },
        {
          "lowerRange": 76.76,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 76.76
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-26T21:13:13Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "00b3560b-1349-4404-bd63-685c8b8889d0",
      "parentProductName": "White Label Extracts - Donny Burger - Nug Run Honeycomb - 1g",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "e1d827c8-2d94-44a5-9eb9-97f360ee556c",
      "productName": "White Label Extracts - Donny Burger - Nug Run Honeycomb - 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:13:13.159494Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300032AF1000260075",
      "sku": "260075",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Honeycomb",
      "variantId": "4bd67c21-44f7-4a85-b253-547ed7f6ef52",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 71.94,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.94
        },
        {
          "lowerRange": 0.17,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.17
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-26T21:06:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "40a29ec6-9031-4917-947d-e66922dfb130",
      "parentProductName": "WLE -Super Boof - 1g Honeycomb",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "51b28515-7f6f-4ec3-aa5e-02da99e45875",
      "productName": "WLE -Super Boof - 1g Honeycomb",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T21:06:38.889759Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300032AF1000260078",
      "sku": "260078",
      "speciesName": "Sativa",
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Honeycomb",
      "variantId": "5fb27eaa-3e53-467e-8cec-6338119e6a0f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 74.45,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.45
        },
        {
          "lowerRange": 0.11,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.11
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-26T20:57:19Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5a08da4a-f866-4bdf-b7aa-cd61db045afc",
      "parentProductName": "WLE - Anirado - 1g cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "a040383e-c89d-4de0-ac1e-913836a869cb",
      "productName": "WLE - Anirado - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:57:19.004412Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300032AF1000260073",
      "sku": "260073",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "15d1308e-dce7-4d6a-843d-ccbdc8e87a6f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 72.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.9
        },
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-26T20:55:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2f9a8420-c7a7-4f3f-ade0-e699c4164482",
      "parentProductName": "WLE - Sluricane - 1g  cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "88ba1c12-ee13-4e3a-ada6-301bddc7d350",
      "productName": "WLE - Sluricane - 1g  cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:55:21.820335Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300032AF1000260077",
      "sku": "260077",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "b7f62346-f396-480c-b21b-a179e3a41fc0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 77.93,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 77.93
        },
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-26T20:52:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "db8f3e2b-bdf3-4076-a35d-6c72cbc05a8e",
      "parentProductName": "WLE - Cuban Linx - 1g cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "91563686-54be-4df1-8a26-ad1de550e5d8",
      "productName": "WLE - Cuban Linx - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:52:54.782979Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300032AF1000260074",
      "sku": "260074",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "25165e37-b071-48b9-b754-bc8fe20ffcc6",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 71.91,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.91
        },
        {
          "lowerRange": 0.11,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.11
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-26T20:50:40Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "55e7927e-5ff6-4b05-a722-80a2d9460b5d",
      "parentProductName": "WLE - Kush Mints - 1g cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "d0257a6a-de24-4cf8-8846-8d3be6569a2f",
      "productName": "WLE - Kush Mints - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:50:40.216612Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300032AF1000260076",
      "sku": "260076",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "a989a830-9144-467b-816f-0f6d1c1401b7",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chromatic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 81.36,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.36
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-26T20:47:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6f76a9d5-20c4-4ded-a289-b4f10b59b07b",
      "parentProductName": "Chromatic Extracts - Peanut Butter Breath - 1g disposable",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "b85150eb-394e-4f4c-80d9-de49f185a675",
      "productName": "Chromatic Extracts - Peanut Butter Breath - 1g disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:47:32.651538Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300032AF1000260071",
      "sku": "260071",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Disposable Cartridge",
      "variantId": "f6b96b06-600f-48d6-ad1d-f60517feef5b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chromatic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 82.65,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82.65
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-26T20:40:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1b961d53-c3ab-48d8-a287-bf4b6786cda1",
      "parentProductName": "Chromatic Extracts - Runtz Killa - 1g cured resin cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "cbe716c6-2640-4bd3-8f71-ad438b30c8fb",
      "productName": "Chromatic Extracts - Runtz Killa - 1g cured resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:40:21.955945Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300032AF1000260072",
      "sku": "260072",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "423bf965-503e-4840-952e-5539ed8f6004",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chromatic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 73.7,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 73.7
        },
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-26T20:37:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fc1f8531-7f43-4f7f-b70f-8e538658153b",
      "parentProductName": "Chromatic Extracts - Grandi Guava - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "fabe075d-e321-4262-aee1-f892242a6785",
      "productName": "Chromatic Extracts - Grandi Guava - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:37:21.860196Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300032AF1000260069",
      "sku": "260069",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "46d7545a-f3eb-43be-85fc-87bca0e367dd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chromatic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 72.23,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.23
        },
        {
          "lowerRange": 0.15,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.15
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-26T20:35:00Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e95f98cb-c2df-4777-a65d-156ae4682af2",
      "parentProductName": "Chromatic Extracts - Cadillac Rain - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "c7a3510c-aa72-46f9-93a0-3bd250a9a186",
      "productName": "Chromatic Extracts - Cadillac Rain - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:35:00.843497Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300032AF1000260068",
      "sku": "260068",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "0f2773f9-7c77-4d64-92df-e1257a0040d2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chromatic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 77.69,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 77.69
        },
        {
          "lowerRange": 0.12,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.12
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-26T20:33:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "530ace55-8dee-4469-a4be-e455e3943648",
      "parentProductName": "Chromatic Extracts - Orange Apricot Smoothie - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "5646dd2a-b5f4-4985-9957-283ab2f6f0ab",
      "productName": "Chromatic Extracts - Orange Apricot Smoothie - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T20:33:52.937296Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A4010300032AF1000260070",
      "sku": "260070",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "a18c2ca9-74b6-4ae1-8813-1d7117c0cc23",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "PCM",
      "cannabinoidInformation": [
        {
          "lowerRange": 21.08,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.08
        },
        {
          "lowerRange": 0.05,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.05
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-26T19:37:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0604c0d8-c05e-44f9-88c9-866394f05dea",
      "parentProductName": "Sunday Vibes - Funky Skunk - 0.5g 10pk",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "13956662-dc1c-4f34-86f0-c367fb91b91c",
      "productName": "Sunday Vibes - Funky Skunk - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T19:37:05.907039Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A40103000345E5000025597",
      "sku": "25597",
      "speciesName": "Sativa",
      "supplierName": "Portland Cannabis Market",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "948e2f67-ccf5-4a78-9f6f-cad64e4c2aaf",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "PCM",
      "cannabinoidInformation": [
        {
          "lowerRange": 27.42,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27.42
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-26T19:33:19Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a5e6a301-fbc0-4cb9-b854-88fbc3aec5fb",
      "parentProductName": "Sunday Vibes - Kush Mintz - 0.5g 10 pk",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "6cdea2c6-3517-4def-822e-196042ea2e8e",
      "productName": "Sunday Vibes - Kush Mintz - 0.5g 10 pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T19:33:19.517329Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A40103000345E5000025598",
      "sku": "25598",
      "speciesName": "Indica Hybrid",
      "supplierName": "Portland Cannabis Market",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "83e06949-4b46-448a-9b03-158fef5907ae",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        },
        {
          "lowerRange": 31.3,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 31.3
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 165,
      "createdAt": "2024-06-26T18:48:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5bf2d132-7962-4bf2-8c39-93628c0bddb8",
      "parentProductName": "Lemon Royale House Preroll",
      "postTaxPriceInPennies": 650,
      "preTaxPriceInPennies": 542,
      "priceInMinorUnits": 542,
      "productDescription": "",
      "productId": "481c6549-50b6-479f-a657-c72c719b45e8",
      "productName": "Lemon Royale House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T18:48:57.567251Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 13,
      "regulatoryId": "1A40103000071B1000003097",
      "sku": "KQMWP4s0fR",
      "speciesName": "SATIVA",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "058af9cf-3dc7-4b8f-865d-b26c6a527e90",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.05,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.05
        },
        {
          "lowerRange": 19.85,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 19.85
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 35,
      "createdAt": "2024-06-26T18:43:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a4c3f561-3e32-40cc-aa94-e09da3e32353",
      "parentProductName": "Sour Patch Kidz House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "77c750cb-81b3-4f62-81cb-9fd7b3ad7ffc",
      "productName": "Sour Patch Kidz House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T18:43:21.346623Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000071B1000003096",
      "sku": "LetlmhW0Vk",
      "speciesName": "",
      "supplierName": "Portland Pot Shop",
      "terpenes": [],
      "type": "House Preroll",
      "variantId": "c7d6b3a7-531c-4406-826a-8e945d9b123c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 25.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.6
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 39,
      "createdAt": "2024-06-26T18:37:41Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4f7cb57f-3a2e-4a30-ba18-1d23fe726d86",
      "parentProductName": "Tangelo House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "2c1f9426-bd4a-48f7-8b84-4cea982b68bb",
      "productName": "Tangelo House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-26T18:37:41.671796Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A40103000071B1000003095",
      "sku": "dTjelzMpMQ",
      "speciesName": "Sativa",
      "supplierName": "Portland Pot Shop",
      "terpenes": [],
      "type": "House Preroll",
      "variantId": "f6e0353d-420e-4a40-a123-5eec3220ae01",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "HQ Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 24.69,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.69
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-06-25T20:27:14Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "474fb29a-3ed8-44ab-b2f2-50fbfe50898b",
      "parentProductName": "Strip Tease by HQ Farms",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "f9ab7270-6e37-45b7-9a95-46a55349a108",
      "productName": "Strip Tease by HQ Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-26T17:29:15.27627Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 103.28,
      "regulatoryId": "1A4010300008B15000003691",
      "sku": "sS61tnCsOu",
      "speciesName": "Sativa",
      "supplierName": "HQFarms",
      "terpenes": null,
      "type": null,
      "variantId": "20227d1b-85cf-4bcd-bb7b-58185f5fed32",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "Zen Reserve",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 20.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.8
        },
        {
          "lowerRange": "23.49,23.49",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "23.49,23.49"
        },
        {
          "lowerRange": "0.21,0.21",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0.21,0.21"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1540,
      "createdAt": "2024-06-26T04:36:02Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2f360075-e6d8-4030-93a6-5a3c339e1fd2",
      "parentProductName": "Mr. Lean by Zen Reserve",
      "postTaxPriceInPennies": 3501,
      "preTaxPriceInPennies": 2917,
      "priceInMinorUnits": 2917,
      "productDescription": "Blue Dream x Cindy '99",
      "productId": "d44700cd-fcf0-43d7-8788-f6ee97a8a19b",
      "productName": "Mr. Lean by Zen Reserve - Half Oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-26T04:36:02.546969Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300022FC5000002015",
      "sku": "0tJHQH7Isz",
      "speciesName": "Sativa",
      "supplierName": "Zen Rezerve",
      "terpenes": [],
      "type": "Flower",
      "variantId": "24ed2817-c7e4-48b9-b7f9-662bc9da6b17",
      "variantName": "Half Oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 208
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 729
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 1458
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 2917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 5833
        }
      ]
    },
    {
      "brand": "Highsman",
      "cannabinoidInformation": [
        {
          "lowerRange": 20.85,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.85
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-25T21:58:16Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ead43ec0-6af6-4a4b-b941-ca6bec76bb31",
      "parentProductName": "Freak Genetixs - Tropical Runtz - 0.5g 10pk",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "0152290b-6c06-480f-823c-08a6e9f8630c",
      "productName": "Freak Genetixs - Tropical Runtz - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:58:31.547274Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 21,
      "regulatoryId": "1A4010300044CC9000002313",
      "sku": "2313",
      "speciesName": "Indica",
      "supplierName": "ASAP BUDS",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "4fa9d7c3-a9a9-493d-853e-07e33b013e78",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Highsman",
      "cannabinoidInformation": [
        {
          "lowerRange": 23.71,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.71
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-25T21:55:01Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ec4520ca-0b61-4985-a35e-16e02ff60011",
      "parentProductName": "Freak Genetixs - Nascar Pretty - 0.5g 10pk",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "3833dafa-0828-49d3-ad69-f1a4eed5c93a",
      "productName": "Freak Genetixs - Nascar Pretty - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:55:01.002091Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 24,
      "regulatoryId": "1A4010300044CC9000002165",
      "sku": "2165",
      "speciesName": "Sativa",
      "supplierName": "ASAP BUDS",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "7b542d98-b937-4cb0-9dc2-c493630282db",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "PDX Organics",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.8
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 363,
      "createdAt": "2024-06-25T21:34:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d2b453a7-c3a7-4527-9b0f-9995683e6acb",
      "parentProductName": "MACmosa by PDX Organix",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "40de35dc-51d2-489e-8fa3-a291850c0bd1",
      "productName": "MACmosa by PDX Organix",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-25T21:34:52.660833Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 126.3,
      "regulatoryId": "1A401030000F80D000026745",
      "sku": "26745",
      "speciesName": "Sativa",
      "supplierName": "PDX ORGANICS",
      "terpenes": null,
      "type": null,
      "variantId": "2955ab36-6349-4ba2-8c61-cc9b1fdfe10c",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "Siren",
      "cannabinoidInformation": [
        {
          "lowerRange": 31.17,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 31.17
        },
        {
          "lowerRange": 0.09,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.09
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1100,
      "createdAt": "2024-06-25T21:28:16Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5beb7d1b-6e36-497d-a06f-ec16e416c314",
      "parentProductName": "Siren - Gummiez - 0.5g 10pk",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "5756fa7f-1f58-4164-a935-db828fb31507",
      "productName": "Siren - Gummiez - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:28:16.588572Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A4010300018A25000019607",
      "sku": "019607",
      "speciesName": "hybrid",
      "supplierName": "Siren",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "f90d6a01-b609-475a-8016-b1c2faf984a1",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Siren",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.29,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.29
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1100,
      "createdAt": "2024-06-25T21:25:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6a856e89-b462-4588-bba5-856258834e14",
      "parentProductName": "Siren - Compound Z - 0.5g 10pk",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "64443693-e1fa-45ac-be5b-1714e7bf6006",
      "productName": "Siren - Compound Z - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:25:52.783468Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A4010300018A25000019631",
      "sku": "019631",
      "speciesName": "Indica",
      "supplierName": "Siren",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "e443fb75-4a59-4e9f-a67a-8cc62a83f0cc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Siren",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.88,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.88
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1100,
      "createdAt": "2024-06-25T21:22:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3040aaea-f95d-4036-9ab8-d74f2bf1b55b",
      "parentProductName": "Siren - Glazed Apricot Gelato - 0.5g 10pk",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "b93b73a1-ab0f-4b8f-beef-bbcb1ab5796a",
      "productName": "Siren - Glazed Apricot Gelato - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:22:45.102102Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300018A25000019191",
      "sku": "019191",
      "speciesName": "Sativa",
      "supplierName": "Siren",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "8f762c7a-a47f-4295-a754-6f7433f877d2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 73.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 73.9
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-25T21:17:48Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ccde471d-b859-4d34-8b5c-7b6b15dfaed2",
      "parentProductName": "NW Kind - Lodi Dodi -1g cured resin cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "3d61fa2b-0cb3-409d-823c-b032b1b3ff49",
      "productName": "NW Kind - Lodi Dodi -1g cured resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:17:48.664888Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030000746A000163108",
      "sku": "163108",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "c5399197-ee56-482a-8065-3953d22dfd26",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.21,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.21
        },
        {
          "lowerRange": 83.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.8
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-25T21:15:19Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f8bce83f-026b-48f3-ae10-84784f793ad8",
      "parentProductName": "NW KIND - Sunset Sherbert - 1G Cured Resin Cart",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "9a351a08-ef12-4576-8e95-ca26d511b112",
      "productName": "NW KIND - Sunset Sherbert - 1G Cured Resin Cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:15:19.912204Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030000746A000163111",
      "sku": "163111",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "c76e2ab8-81d0-4eef-840f-9a877f87b6ed",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.168,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.168
        },
        {
          "lowerRange": 65,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 65
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-25T21:10:48Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "35bb09dc-3e6b-4a55-92c0-0394fb18dfca",
      "parentProductName": "NW KIND - Strawberry Gary - 1G Cured Resin Cart",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "5c69276c-4d1b-4c7c-a3ad-8d3f180f014f",
      "productName": "NW KIND - Strawberry Gary - 1G Cured Resin Cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:10:48.774712Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030000746A000163110",
      "sku": "163110",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "fbf580c2-2bd1-43e6-aa04-98c61dcaa5d3",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 2.12,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 2.12
        },
        {
          "lowerRange": 83.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.8
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-25T21:05:40Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a1d8ee49-608d-4c32-a24d-a2159087d5ef",
      "parentProductName": "NW Kind - Glazed Apricot Gelato - 1g Cured Resin",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "4b5dbf6e-076f-4c74-b62b-228b6df5d198",
      "productName": "NW Kind - Glazed Apricot Gelato - 1g Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:05:40.668068Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000746A000163105",
      "sku": "63105",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "983a6c11-4845-4dcd-a556-241a53cbee06",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 71.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.8
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-25T21:02:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d5237f9d-b64f-483a-b0e0-71417b509e6d",
      "parentProductName": "NW Kind - Ghost Train Haze - 1g Live Resin Cart",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "b5dd0b77-e2f2-4347-a938-1bd74354365d",
      "productName": "NW Kind - Ghost Train Haze - 1g Live Resin Cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T21:02:46.856401Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000746A000163104",
      "sku": "163104",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Live Resin Cartridge",
      "variantId": "00cf37fa-2042-4455-9f0d-c76eb15d9bc3",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 71.16,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.16
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-25T20:57:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b8c6327a-1db0-4b61-97bf-fe9a858fb4e7",
      "parentProductName": "NW Kind - Grease Bucket - 1g Live Resin Cartridge",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "a58d0f6c-6c78-4562-b409-088232450fc8",
      "productName": "NW Kind - Grease Bucket - 1g Live Resin Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T20:57:43.738766Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030000746A000163107",
      "sku": "163107",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Live Resin Cartridge",
      "variantId": "a3d5bf24-f84b-4b4a-a06c-07f6882373fd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 68.39,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 68.39
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-25T20:54:27Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3b2f2ef9-43b8-48f3-9d76-64d369826f1e",
      "parentProductName": "NW Kind - Orange Sherbet Crasher - 1g live resin cartridge",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "797868dd-d713-4592-8330-d4de26e5dfe7",
      "productName": "NW Kind - Orange Sherbet Crasher - 1g live resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T20:54:27.563959Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000746A000163109",
      "sku": "163109",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Live Resin Cartridge",
      "variantId": "e10bcc8d-8867-4d78-a2fb-3a2d5f1648e8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Garden First",
      "cannabinoidInformation": [
        {
          "lowerRange": 397.93,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 397.93
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-25T17:58:26Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-09-25",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "32647621-27c9-46b9-ae92-fb7ebc1924b6",
      "parentProductName": "Garden First - Grape Cream Cake - 1g infused preroll",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "72ff8f86-e12d-4b9e-8a8a-6d8478aa250f",
      "productName": "Garden First - Grape Cream Cake - 1g infused preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T17:58:26.517171Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300055BE1000000037",
      "sku": "00037",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "d1abcca7-8454-44ef-883d-b716ce99c6ad",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sticky Trees",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.44,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.44
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 242,
      "createdAt": "2024-05-23T16:47:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b9d82de3-255a-4ccd-b828-3178c7603df2",
      "parentProductName": "Cadillac Rainbows by Sticky Trees Farms",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "eb9f08ab-141c-4368-848c-c7e6b7a9318a",
      "productName": "Cadillac Rainbows by Sticky Trees Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-25T17:13:21.145729Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 176.08,
      "regulatoryId": "1A4010300013179000014754",
      "sku": "1hQ6NaIZF8",
      "speciesName": "Indica",
      "supplierName": "Demeter Gardens",
      "terpenes": null,
      "type": null,
      "variantId": "dcdef0d4-aacd-4091-9fe7-e1a0746df799",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "Virgin",
      "cannabinoidInformation": [
        {
          "lowerRange": 23.7,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.7
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": "0,0",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-06-18T17:42:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ab5d3599-bd4e-4f1a-9e35-4792246d0c36",
      "parentProductName": "Sparkly Biscuits by Virgin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "Slurricane x Monkey Biscuits",
      "productId": "66ff697b-ac93-4013-af38-3a6eb80b1e47",
      "productName": "Sparkly Biscuits by Virgin - BOTH - Bulk",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-25T17:12:59.880169Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 104,
      "regulatoryId": "1A401030002A4F5000026301",
      "sku": "cCa9ES2lUB",
      "speciesName": "Indica-dom",
      "supplierName": "Virgin Cannabis, Wolf Creek Cannabis",
      "terpenes": null,
      "type": "Flower",
      "variantId": "f852dcf1-e086-4bb5-b2f4-f0bfa48ab1d8",
      "variantName": "BOTH - Bulk",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Ananda Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 31.32,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 31.32
        },
        {
          "lowerRange": 0.09,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.09
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-04-18T16:31:11Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5e6be89e-e35f-4ce9-a8a6-711507de9c83",
      "parentProductName": "Tally Mon by Ananda Farms",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "cc897a54-3557-4e81-bd80-7afc75cf51e9",
      "productName": "Tally Mon by Ananda Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-25T17:05:38.557503Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 100.7,
      "regulatoryId": "1A4010300000836000009472",
      "sku": "HBqKcHk3lP",
      "speciesName": "Sativa",
      "supplierName": "Ananda Farms",
      "terpenes": null,
      "type": null,
      "variantId": "660310d6-57fa-448a-9696-f2e3ace47d34",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "Cosmic Treehouse",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.05,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.05
        },
        {
          "lowerRange": 20.04,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.04
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 88,
      "createdAt": "2024-05-12T19:39:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "45e8bdc1-2ed8-445b-8840-bdb4dc0b3c37",
      "parentProductName": "Alaskan Thunder Fuck by Thunder Farms",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "43f96f17-040c-472d-a161-2a7fb20d2569",
      "productName": "Alaskan Thunder Fuck by Thunder Farms - bulk",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-25T17:01:48.848236Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 111.72,
      "regulatoryId": "1A4010300007D66000083613",
      "sku": "83613",
      "speciesName": "Sativa",
      "supplierName": "Cosmic Treehouse",
      "terpenes": null,
      "type": null,
      "variantId": "5356eb83-21c4-4e10-addb-803faff1fcb8",
      "variantName": "bulk",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": null,
      "cannabinoidInformation": [
        {
          "lowerRange": 25,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 198,
      "createdAt": "2024-05-30T19:36:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7abd3f65-d7ec-49b5-ba96-c7c3ac493f9e",
      "parentProductName": "Supersonic by Flyin' High Farms",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "03851a79-8779-464b-be51-6ba954396322",
      "productName": "Supersonic by Flyin' High Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-25T16:57:35.153338Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 118.42,
      "regulatoryId": "1A4010300042041000042693",
      "sku": "jfHtsDybz7",
      "speciesName": "Sativa",
      "supplierName": "HOODVIEW CANNABIS LLC",
      "terpenes": null,
      "type": null,
      "variantId": "c39f625f-3c53-4282-8b22-fc722c99a459",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": "Green State of Mind",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-17T22:25:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "99bab4e4-30cb-4da9-8428-7bd5c653d323",
      "parentProductName": "Botz - Marionberry - 100mg gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "bfec97c0-33ee-4e7a-a6b4-7a1e2cd52478",
      "productName": "Botz - Marionberry - 100mg gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-25T02:52:01.019222Z",
      "productWeight": 8,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300009029000041693",
      "sku": "41693",
      "speciesName": "Indica",
      "supplierName": "Green State of Mind, Inc.",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "69286b28-b90d-47ae-9c89-b24b7e70faab",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 180,
      "createdAt": "2024-06-24T22:33:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d674def5-674c-47a0-91c5-22edde2d1b5d",
      "parentProductName": "Portland Pot Shop Easy Reach Lighter",
      "postTaxPriceInPennies": 300,
      "preTaxPriceInPennies": 300,
      "priceInMinorUnits": 300,
      "productDescription": "",
      "productId": "0274c3bb-34b0-437e-81ee-bfa5be51d23d",
      "productName": "Portland Pot Shop Easy Reach Lighter",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T22:34:30.267013Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 77,
      "regulatoryId": "",
      "sku": "bn4cz45ewh",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Lighter",
      "variantId": "90b83177-2dc2-428d-95e8-46204a30924b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 34.32,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 34.32
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1020,
      "createdAt": "2024-06-24T21:16:31Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bb496418-044d-4548-ad5a-f4c5e5af4b2c",
      "parentProductName": "Toking Blunts - Do-Si-Dos - 2pk, 2.5g Blunts",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "a93a64b6-5e0f-468d-98e1-c5e1521c557d",
      "productName": "Toking Blunts - Do-Si-Dos - 2pk, 2.5g Blunts",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T21:16:31.708412Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000017D5000030105",
      "sku": "Nem8pckPdP",
      "speciesName": "Indica",
      "supplierName": "Toking Farms",
      "terpenes": null,
      "type": "Blunt Pack",
      "variantId": "b12841c1-5925-47ce-8906-fd5699b808a4",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "KITES",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 21.21,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.21
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-24T21:09:41Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "15b04720-621d-446f-ac4d-c05d49d4583f",
      "parentProductName": "KITES - Animal Sherb - 0.5g 10pk",
      "postTaxPriceInPennies": 1899,
      "preTaxPriceInPennies": 1583,
      "priceInMinorUnits": 1583,
      "productDescription": "",
      "productId": "12927a71-43de-4cd6-a4b1-e556aa3a2344",
      "productName": "KITES - Animal Sherb - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T21:09:41.03864Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A401030003C8C1000058334",
      "sku": "58334",
      "speciesName": "Indica",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "31d5c629-0578-4a81-94d6-521fa450bcd5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "KITES",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 25.04,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.04
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-24T21:07:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ab6dbfbd-0f16-492d-9098-cc49d9b1f4fc",
      "parentProductName": "KITES - multipack - Berry Pie / Ogee Crasher - 0.5g 10pk",
      "postTaxPriceInPennies": 1899,
      "preTaxPriceInPennies": 1583,
      "priceInMinorUnits": 1583,
      "productDescription": "",
      "productId": "ab57740b-3523-4ccc-965b-9e43acbe3ad0",
      "productName": "KITES - multipack - Berry Pie / Ogee Crasher - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T21:07:09.78929Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030003C8C1000076022",
      "sku": "76022",
      "speciesName": null,
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "c7796728-ca95-45b9-b519-30bf16000d89",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "KITES",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 24.1,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.1
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-24T21:05:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ffe97162-1e00-4205-a3f0-5b14385cdfda",
      "parentProductName": "KITES - XJ-13 - 0.5g 10pk",
      "postTaxPriceInPennies": 1899,
      "preTaxPriceInPennies": 1583,
      "priceInMinorUnits": 1583,
      "productDescription": "",
      "productId": "ea43fa65-89f1-4940-8e2d-e4230b61877b",
      "productName": "KITES - XJ-13 - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T21:05:12.501432Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030003C8C1000058308",
      "sku": "58308",
      "speciesName": "Sativa",
      "supplierName": "Nimble Distribution",
      "terpenes": [],
      "type": "Preroll Pack",
      "variantId": "847eb6aa-9bd3-4211-a924-6ddd6246c799",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "KITES",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 23.99,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.99
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-24T21:02:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fc80f75e-50c1-4ecf-afb1-8fba37403d28",
      "parentProductName": "KITES - Jealousy 0 0.5g 10pk",
      "postTaxPriceInPennies": 1899,
      "preTaxPriceInPennies": 1583,
      "priceInMinorUnits": 1583,
      "productDescription": "",
      "productId": "aaf755c8-bede-4fea-a389-8137fe244425",
      "productName": "KITES - Jealousy 0 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T21:02:56.484169Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A401030003C8C1000044248",
      "sku": "4248",
      "speciesName": "Hybrid",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "1c6549d8-d24f-4c45-ac3e-537baf5e829a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sauce",
      "cannabinoidInformation": [
        {
          "lowerRange": 770,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 770
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 77,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 77
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1800,
      "createdAt": "2024-06-24T20:58:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6efbfee1-70a2-4f8b-b10a-842be371a08a",
      "parentProductName": "Sauce - Grandaddy Purp - 1g Live Resin Disposable",
      "postTaxPriceInPennies": 3600,
      "preTaxPriceInPennies": 3000,
      "priceInMinorUnits": 3000,
      "productDescription": "",
      "productId": "84d21eda-5bb7-484e-9fa2-1750a786deaf",
      "productName": "Sauce - Grandaddy Purp - 1g Live Resin Disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T20:58:54.863093Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A401030003C8C1000076025",
      "sku": "76025",
      "speciesName": "Indica",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Disposable Cartridge",
      "variantId": "50ee4c6f-961e-478a-b97a-8195112d9871",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sauce",
      "cannabinoidInformation": [
        {
          "lowerRange": 770,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 770
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 77,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 77
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1800,
      "createdAt": "2024-06-24T20:57:37Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d8e94d15-afdc-4565-ac53-0148f87f2c93",
      "parentProductName": "Sauce - Gelato - 1g live resin disposable",
      "postTaxPriceInPennies": 3600,
      "preTaxPriceInPennies": 3000,
      "priceInMinorUnits": 3000,
      "productDescription": "",
      "productId": "74a95571-516e-4bba-b8bf-29cfac0951b7",
      "productName": "Sauce - Gelato - 1g live resin disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T20:57:37.017004Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A401030003C8C1000076024",
      "sku": "76024",
      "speciesName": "Hybrid",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Flavored Disposable",
      "variantId": "2737be7e-dd43-4384-a9cb-0908437f20de",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 71.96,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.96
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-14T20:34:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d6f7f998-4786-455a-bcbe-cfe87a6a3007",
      "parentProductName": "Chronic Extracts - Blueberry Muffin #4 - 1g crumble",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "a7db7007-4cf4-43dd-9efb-533c0059615a",
      "productName": "Chronic Extracts - Blueberry Muffin #4 - 1g crumble",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T20:51:52.151876Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300021E31000003947",
      "sku": "3947",
      "speciesName": "Indica",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Crumble",
      "variantId": "9f15bf00-fe75-4aff-a336-c306ee7d90f4",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sauce",
      "cannabinoidInformation": [
        {
          "lowerRange": 784,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 784
        },
        {
          "lowerRange": 78.4,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 78.4
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1800,
      "createdAt": "2024-06-24T19:18:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "59d7abf2-a637-4060-bcb1-328f7f370079",
      "parentProductName": "Sauce - Aloha Express - 1g Disposable",
      "postTaxPriceInPennies": 3600,
      "preTaxPriceInPennies": 3000,
      "priceInMinorUnits": 3000,
      "productDescription": "",
      "productId": "2803ffcb-c253-4d84-a0e0-64bb94914188",
      "productName": "Sauce - Aloha Express - 1g Disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T19:18:10.216199Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A401030003C8C1000076023",
      "sku": "76023",
      "speciesName": "Sativa",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Disposable",
      "variantId": "5d5a09ae-6e8b-4e9f-ab24-0659c2282b5d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 25.54,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.54
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 164,
      "createdAt": "2024-06-24T17:25:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e6bbed0f-793f-468f-9032-9152591f24ae",
      "parentProductName": "Sapphyre House Preroll",
      "postTaxPriceInPennies": 550,
      "preTaxPriceInPennies": 458,
      "priceInMinorUnits": 458,
      "productDescription": "",
      "productId": "217cde74-995e-4ebc-b423-f22722718c60",
      "productName": "Sapphyre House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T17:25:05.056987Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 46,
      "regulatoryId": "1A40103000071B1000003094",
      "sku": "5PkloXFLXQ",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "0b3041eb-0571-43d3-ae2b-1004fc39b35b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 24.83,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.83
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-24T17:19:15Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "af9b8eba-b8b4-403b-b951-8a930d2134e5",
      "parentProductName": "Airheadz House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "fbb07a07-dde9-4333-b4b9-266ab0114cd5",
      "productName": "Airheadz House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T17:19:15.09219Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A40103000071B1000003093",
      "sku": "Lct2B9hoS4",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "26dac7e8-1dce-4a17-b0b6-d0cb04fe1716",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 35.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 35.6
        },
        {
          "lowerRange": 0.09,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.09
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 132,
      "createdAt": "2024-06-24T17:13:15Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2b4ad41b-2a38-4ba6-9d46-06a2dfc458fa",
      "parentProductName": "Chocolate Runtz House Preroll",
      "postTaxPriceInPennies": 650,
      "preTaxPriceInPennies": 542,
      "priceInMinorUnits": 542,
      "productDescription": "",
      "productId": "919ea44d-0808-4760-bc0d-c2e26f50f1d7",
      "productName": "Chocolate Runtz House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T17:13:15.240631Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 35,
      "regulatoryId": "1A40103000071B1000003092",
      "sku": "SafhUCTFZF",
      "speciesName": "Hybrid",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "4c5d04c3-9170-4d7b-8222-b7a4c00efbf1",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.86,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.86
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 132,
      "createdAt": "2024-06-24T17:07:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3a98ebd6-6e78-485f-9c7b-0392fa42d391",
      "parentProductName": "Sour Garlic House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "9504fe9d-13f4-4001-80b2-50c4e01890a4",
      "productName": "Sour Garlic House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T17:07:57.942019Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000071B1000003091",
      "sku": "RGp5N28M4S",
      "speciesName": "Sativa",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "2dd715fa-c930-478d-aa03-6cc9a2a682cc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 23.04,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.04
        },
        {
          "lowerRange": 0.06,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.06
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 132,
      "createdAt": "2024-06-24T16:59:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5136cc2a-947e-4066-9f08-279280b1eb1d",
      "parentProductName": "Vegan Stepmom House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "00cf4d9c-2f7a-4083-8fe8-86223c2c9b14",
      "productName": "Vegan Stepmom House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T16:59:22.687954Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 12,
      "regulatoryId": "1A40103000071B1000003090",
      "sku": "lWtTzHfsHH",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "1d50359e-2a3e-4df3-81c3-7bca7d4f0a59",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 83.92,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.92
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-18T19:19:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "86385712-7dfc-40b1-9185-81ec165cbae5",
      "parentProductName": "National Cannabis Co. - Cookie Crunch - 1g Cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "32646bf2-348e-458d-83b5-664a0bbea98e",
      "productName": "National Cannabis Co. - Cookie Crunch - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T16:23:02.044273Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A401030000D995000034817",
      "sku": "34817",
      "speciesName": null,
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "5bb58873-6fd3-493e-a4a4-810daa8bf730",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Heights",
      "cannabinoidInformation": [
        {
          "lowerRange": 796.6,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 796.6
        },
        {
          "lowerRange": 39.83,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 39.83
        },
        {
          "lowerRange": 0.25,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.25
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 950,
      "createdAt": "2024-06-24T16:21:11Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cfdbea4a-0fce-40a0-9c5f-1c23489e1eb5",
      "parentProductName": "Moonrock Blunts - Blackberry Diesel - 2g Infused blunt",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "bc1eeea4-1612-48ea-ac78-37a8fac1a114",
      "productName": "Moonrock Blunts - Blackberry Diesel - 2g Infused blunt",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T16:21:11.230689Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 13,
      "regulatoryId": "1A401030000A5A2000218087",
      "sku": "218087",
      "speciesName": "Indica",
      "supplierName": "Dreamfield Transfer Services",
      "terpenes": null,
      "type": "Infused Blunt",
      "variantId": "6fad5a66-d59a-4989-a2b6-898c221d9d4b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Heights",
      "cannabinoidInformation": [
        {
          "lowerRange": 831.15,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 831.15
        },
        {
          "lowerRange": 2.07,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 2.07
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1050,
      "createdAt": "2024-06-24T16:18:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-09-24",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "67cde0d5-5277-4edc-880d-36e0034dad8b",
      "parentProductName": "Moonrock Blunts - Hazy Train - 2g infused blunt",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "ada786bb-3b90-4c4c-9e9e-c15ccc95bb2e",
      "productName": "Moonrock Blunts - Hazy Train - 2g infused blunt",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T16:18:05.218018Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 26,
      "regulatoryId": "1A401030000A5A2000218638",
      "sku": "218638",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Blunt",
      "variantId": "efbb73a0-e291-4201-b576-c066ffafbe31",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Heights",
      "cannabinoidInformation": [
        {
          "lowerRange": 855.89,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 855.89
        },
        {
          "lowerRange": 3.1,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 3.1
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1050,
      "createdAt": "2024-06-24T16:15:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-09-24",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "891d6eec-510b-4344-a0e1-e3727a24aeb0",
      "parentProductName": "Moonrock Blunts - Banana Pancakes - 2g",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "44577836-a072-44a2-8cf2-16902323eb99",
      "productName": "Moonrock Blunts - Banana Pancakes - 2g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T16:15:45.81064Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A401030000A5A2000218637",
      "sku": "218637",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Blunt",
      "variantId": "11586311-081b-4b85-b155-5515b2061601",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-24T15:59:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "caeb5b34-9d14-47f0-ae79-d316c0187407",
      "parentProductName": "Wyld Marionberry Indica Gummies 100mg",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "a054c54c-8096-4013-8315-abbd088742ef",
      "productName": "Wyld Marionberry Indica Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:59:33.194712Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 20,
      "regulatoryId": "1A40103000062D5000471177",
      "sku": "471177",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "42231d96-2140-4dd8-91c7-7a5c1b6dc117",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 105,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 105
        },
        {
          "lowerRange": 103.5,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 103.5
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 875,
      "createdAt": "2024-06-24T15:58:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a91bd250-a0bd-4ee2-9490-c664dca4136d",
      "parentProductName": "Wyld Pear 1:1 THC CBG Gummies",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "817d7f44-4fd2-46b3-a531-a5d1a0e8d5e6",
      "productName": "Wyld Pear 1:1 THC CBG Gummies",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:58:09.286161Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 20,
      "regulatoryId": "1A40103000062D5000473037",
      "sku": "473037",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "4490e129-58d7-4310-a92e-8ce8ea9a95ca",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-24T15:56:01Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6c5bd430-2393-444a-9217-0c0f885e8fd6",
      "parentProductName": "Wyld Raspberry Sativa Gummies 100mg",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "22feba6a-0fdb-4b9a-b781-5b68c7e48457",
      "productName": "Wyld Raspberry Sativa Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:56:01.31882Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 17,
      "regulatoryId": "1A40103000062D5000468718",
      "sku": "468718",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "701353c6-d0c0-41fb-a2df-40ac789440a1",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 99.2,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 99.2
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 97.8,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 97.8
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 875,
      "createdAt": "2024-06-24T15:54:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6bb69d05-2252-4c95-b348-c6eca3de1be4",
      "parentProductName": "Wyld Grapefruit THC : CBG : CBC 100mg",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "f7c6af77-d465-4296-87f6-f906833b8a91",
      "productName": "Wyld Grapefruit THC : CBG : CBC 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:54:43.190768Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A40103000062D5000496971",
      "sku": "496971",
      "speciesName": "Sativa",
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "CBD Edible",
      "variantId": "d2dcc09d-6d2c-43a4-8e4d-0d92edb9aa94",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-24T15:51:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ebfaf4ce-d484-4356-8a3f-c5fc3ae10ceb",
      "parentProductName": "Wyld Huckleberry Hybrid Gummies 100mg",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "5b01c6eb-ad10-4793-ab85-fcf00b7b853d",
      "productName": "Wyld Huckleberry Hybrid Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:51:07.458336Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 20,
      "regulatoryId": "1A40103000062D5000469510",
      "sku": "469510",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "1beb3dc9-66d7-4d8f-b89f-cca06915cdc3",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-24T15:48:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f8749e00-a7c9-4774-a4de-c0b5f5537851",
      "parentProductName": "Wyld Sour Peach Mango 100mg Single Gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "05797837-b5a1-4fe3-a51f-6d7198c185f0",
      "productName": "Wyld Sour Peach Mango 100mg Single Gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:48:43.839425Z",
      "productWeight": 10,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000062D5000470076",
      "sku": "470076",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "35d2cef7-9520-4c27-a1e4-bb6398f56a53",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-24T15:47:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7b611097-784c-4abb-a060-a71dbd954f8c",
      "parentProductName": "Wyld Strawberry Lemonnade 100mg Single Gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "ac7c4575-b655-4f03-9b5c-62a6af93e9a9",
      "productName": "Wyld Strawberry Lemonnade 100mg Single Gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:47:07.577825Z",
      "productWeight": 10,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A40103000062D5000471240",
      "sku": "471240",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "bfe0ab64-0198-4962-a319-5b19facd9842",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-24T15:45:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "67ca703e-671c-427a-9143-0f895b1a5ac1",
      "parentProductName": "Wyld Grape Indica 100mg Single Gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "14ed33cb-73cb-43d3-adbc-114f62f12a88",
      "productName": "Wyld Grape Indica 100mg Single Gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-24T15:45:45.791872Z",
      "productWeight": 10,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000062D5000475264",
      "sku": "475264",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "aadba267-719f-4232-9084-9e74dd88676f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Anthos",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 23.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.26
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1232,
      "createdAt": "2024-06-23T04:33:41Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cee2ca2e-60e4-44b8-8ec0-53350e96a9b4",
      "parentProductName": "Dosi Punch by Green Queen Farms",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "5ae5e79e-44d0-4bff-b030-b51ab4703669",
      "productName": "Dosi Punch by Green Queen Farms - Half oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-23T22:17:43.884732Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000405B3000024899",
      "sku": "I4L6Bj63xS",
      "speciesName": "Indica",
      "supplierName": "Anthos Distribution",
      "terpenes": null,
      "type": "half oz",
      "variantId": "d18f76dc-dbb0-4243-8d04-81a490cd38e8",
      "variantName": "Half oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams (1g)",
          "pricePerUnitInMinorUnits": 178
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth (3.5g)",
          "pricePerUnitInMinorUnits": 624
        },
        {
          "gramAmount": 7,
          "name": "Quarter (7g)",
          "pricePerUnitInMinorUnits": 1249
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce (14g)",
          "pricePerUnitInMinorUnits": 2500
        },
        {
          "gramAmount": 28,
          "name": "Ounce (28g)",
          "pricePerUnitInMinorUnits": 5000
        }
      ]
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 821.6,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 821.6
        },
        {
          "lowerRange": 82.16,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82.16
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-18T19:31:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "27acd59b-9cb8-4938-9693-d0d53870a0c0",
      "parentProductName": "Freshy Shirley Temple 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "2eeda2cf-d0bb-432e-80ba-2dcd7d88fcff",
      "productName": "Freshy Shirley Temple 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-23T03:50:05.057439Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030002259D000068876",
      "sku": "68876",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "387df18d-8fc1-4b19-85e4-aaa07efde5ec",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Anthos",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 22.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.5
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1232,
      "createdAt": "2024-06-22T16:55:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "90ad24fc-792e-4e90-b345-9f810f9f91de",
      "parentProductName": "Trop Cherry by Green Queen Farms",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "90161658-d2b1-42d6-aded-7be562c2abc1",
      "productName": "Trop Cherry by Green Queen Farms - Half oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-22T16:55:09.325936Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030000E22D000005459",
      "sku": "5459",
      "speciesName": "Sativa",
      "supplierName": "Anthos Distribution",
      "terpenes": null,
      "type": null,
      "variantId": "6861dc6b-5c94-4cbb-a7e7-517f66fe0af7",
      "variantName": "Half oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams (1g)",
          "pricePerUnitInMinorUnits": 178
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth (3.5g)",
          "pricePerUnitInMinorUnits": 624
        },
        {
          "gramAmount": 7,
          "name": "Quarter (7g)",
          "pricePerUnitInMinorUnits": 1249
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce (14g)",
          "pricePerUnitInMinorUnits": 2500
        },
        {
          "gramAmount": 28,
          "name": "Ounce (28g)",
          "pricePerUnitInMinorUnits": 5000
        }
      ]
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 80.89,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 80.89
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-20T21:23:53Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5681baaa-fbf3-48c2-be5b-390c7fc284b3",
      "parentProductName": "Bob's Bammer - Icy Papaya Punch - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "97e1361d-4555-49f2-9e74-3698d58f4300",
      "productName": "Bob's Bammer - Icy Papaya Punch - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T21:23:53.699678Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A40103000491ED000015947",
      "sku": "15947",
      "speciesName": null,
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "76615892-74a3-4d80-9019-2eb03dfa8533",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 81.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.26
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-20T21:21:53Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c364ba19-1cdf-48aa-8439-d83d3084244e",
      "parentProductName": "Bob's Nuclear FALLOUT 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "361b5698-29fb-41c0-8be0-6f57215419ef",
      "productName": "Bob's Nuclear FALLOUT 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T21:21:53.432987Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A40103000491ED000015945",
      "sku": "15945",
      "speciesName": null,
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "5b774a6e-97f6-4a7e-b1f4-a6db0399cea8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 34.66,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 34.66
        },
        {
          "lowerRange": 41.33,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 41.33
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-20T21:20:41Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6ad14094-7f25-4a2e-ad6a-c8e1234936de",
      "parentProductName": "Bob's Bammer - Wedding Mintz 1:1 - 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "4e81e14d-3b01-4dc8-afe6-b3d94ca01151",
      "productName": "Bob's Bammer - Wedding Mintz 1:1 - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T21:20:41.553346Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A40103000491ED000015946",
      "sku": "15946",
      "speciesName": null,
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "CBD Cart",
      "variantId": "82e7cdf9-eab8-4bc2-b36c-f00388c5217a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 84.28,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 84.28
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-20T21:17:01Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0925abc6-5398-42e0-8f66-f828310fda0f",
      "parentProductName": "Bob's Bammer - Zookies - 1g Disposable",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "71e4f4d3-7711-4a5f-ae17-808e9aff9492",
      "productName": "Bob's Bammer - Zookies - 1g Disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T21:17:01.127669Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A40103000491ED000015944",
      "sku": "15944",
      "speciesName": null,
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Disposable Cartridge",
      "variantId": "1dbf4bc0-1698-4ab9-827e-fc0ac2585165",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1150,
      "createdAt": "2024-06-20T20:55:35Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d3c145c7-c8e0-4cce-867e-38023f1df5a8",
      "parentProductName": "dab bat - Ghost - cloak battery",
      "postTaxPriceInPennies": 2400,
      "preTaxPriceInPennies": 2400,
      "priceInMinorUnits": 2400,
      "productDescription": "",
      "productId": "0dee3036-be50-40b0-8211-ee3616afa5a6",
      "productName": "dab bat - Ghost - cloak battery - Retro",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:55:35.496051Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 5,
      "regulatoryId": "",
      "sku": "P5daIzH7JO",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "f011d0c0-387f-4275-bbb1-5dfbe95a7e4c",
      "variantName": "Retro",
      "weightTierInformation": null
    },
    {
      "brand": "3 Lakes Distribution",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 74.11,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.11
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-20T20:52:40Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f347c781-a848-4465-b474-0a05961b683a",
      "parentProductName": "Vape Tree - Gasoline Lollipop - 1g cartridge",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "ce003dcb-6745-4cd5-8558-5d6d1f630ede",
      "productName": "Vape Tree - Gasoline Lollipop - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:52:40.447675Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A401030003833A000034295",
      "sku": "34295",
      "speciesName": null,
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "572d09e4-3085-49b3-b31e-89a2b0b97834",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "3 Lakes Distribution",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 72.45,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.45
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-20T20:47:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "08b52838-5bb6-4925-8a95-dc0b40b2e605",
      "parentProductName": "Vape Tree - Zoap - 1g cartridge",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "da49d537-758a-410e-9066-ae1c2323f892",
      "productName": "Vape Tree - Zoap - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:47:25.735908Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A401030003833A000034296",
      "sku": "34296",
      "speciesName": null,
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "88a7baec-c541-4672-ab6c-4a09476039d6",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "3 Lakes Distribution",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 72.96,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.96
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-20T20:37:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b8b498e2-9f41-481e-b457-88848521fe06",
      "parentProductName": "Vape Tree - Caribbean Breeze - 1g cartridge",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "0b0715d9-8cfe-44f3-9489-9187d1db7b8a",
      "productName": "Vape Tree - Caribbean Breeze - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:37:55.079368Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030003833A000034294",
      "sku": "34294",
      "speciesName": null,
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "3c746630-ac24-448a-96d2-f3b853b658e1",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 2.05,
          "name": "cbg",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 2.05
        },
        {
          "lowerRange": 80.2,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 80.2
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-20T20:28:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7a3305f8-1ecf-4854-8957-d315ee9c5d33",
      "parentProductName": "Altered Alchemy - Baby Yoda - 1g FECO",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "12801488-5a1a-4af4-9a04-6bcfccc77e86",
      "productName": "Altered Alchemy - Baby Yoda - 1g FECO",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:28:10.566482Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030003833A000034293",
      "sku": "34293",
      "speciesName": "Indica",
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "FECO",
      "variantId": "1e50e369-4327-4989-9820-ad608615168e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 81.1,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.1
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-20T20:24:15Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5b67b018-425c-41aa-8242-35c7627b78f0",
      "parentProductName": "Altered Alchemy - Jelly Frosting - 1g cured resin batter",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "0519676f-7b42-423d-8639-0b2eefa26fe9",
      "productName": "Altered Alchemy - Jelly Frosting - 1g cured resin batter",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:24:15.452235Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030003833A000034291",
      "sku": "34291",
      "speciesName": "Hybrid",
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "40fdd18a-d1af-4089-b562-a65e57ccdd8a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 82,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-20T20:22:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6020eb3a-ae46-43b2-85a7-3715bfab210d",
      "parentProductName": "Altered Alchemy - Maui Blood - 1g cured resin batter",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "ac0d5725-6a06-4370-add2-232aaf449223",
      "productName": "Altered Alchemy - Maui Blood - 1g cured resin batter",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T20:22:05.340106Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030003833A000034292",
      "sku": "34292",
      "speciesName": "Sativa",
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "78862461-b39b-4e2e-b144-f4f4661db3a5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "The Crop Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 26.4,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.4
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 441,
      "createdAt": "2024-06-20T19:28:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c3ff6df6-549f-41f5-a38e-795ec0211a0e",
      "parentProductName": "White Truffle by The Crop Shop",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "72c9e974-9ddd-4234-8ea9-3bca1f1130c1",
      "productName": "White Truffle by The Crop Shop",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T19:28:56.04498Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 61.7,
      "regulatoryId": "1A4010300033DB3000002468",
      "sku": "VHkWYHUhuH",
      "speciesName": "Indica",
      "supplierName": "The Crop Shop Cannabis",
      "terpenes": null,
      "type": null,
      "variantId": "19eb51f1-e1c5-408b-8cd0-af9549c26471",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "Cosmic Treehouse",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 25.06,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.06
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1540,
      "createdAt": "2024-06-20T18:27:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": false,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "156f56fe-0d9f-477b-a72a-db29a396bcc5",
      "parentProductName": "Black Cherry Gelato by Comsic Treehouse",
      "postTaxPriceInPennies": 3501,
      "preTaxPriceInPennies": 2917,
      "priceInMinorUnits": 2917,
      "productDescription": "",
      "productId": "0a729c06-9ea2-4d6e-92a9-4bbe13a25803",
      "productName": "Black Cherry Gelato by Comsic Treehouse - half oz",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T18:27:57.672108Z",
      "productWeight": 14,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300007D66000085935",
      "sku": "EV1uNd2rWB",
      "speciesName": "Indica",
      "supplierName": "Cosmic Treehouse",
      "terpenes": null,
      "type": null,
      "variantId": "c5010d7c-0977-43bd-857d-7f698ac33301",
      "variantName": "half oz",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 208
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 729
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 1458
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 2917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 5833
        }
      ]
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.52,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.52
        },
        {
          "lowerRange": 79.53,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 79.53
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-20T18:22:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0af2d17f-141a-477e-943d-237c65ed7317",
      "parentProductName": "Dr. Jolly's - Purple Sage - 1g live resin + distillate cart",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "1c7176f2-a92b-45ab-a05c-4aeb2b500dcb",
      "productName": "Dr. Jolly's - Purple Sage - 1g live resin + distillate cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T18:22:56.999808Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300042B95000015239",
      "sku": "15239",
      "speciesName": null,
      "supplierName": "Dr. Jolly's",
      "terpenes": null,
      "type": "Live Resin + Distillate cart",
      "variantId": "829a8768-ebca-40e6-833a-4f702d292220",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.44,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.44
        },
        {
          "lowerRange": 67.02,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.02
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-20T18:20:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a6b14b9e-76d4-4c30-860b-cb13b61b7970",
      "parentProductName": "Dr. Jolly's - Huckleberry Diesel - Live Resin + Distillate 1g cart",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "80192096-1f8d-49d2-817f-c64cf9fce466",
      "productName": "Dr. Jolly's - Huckleberry Diesel - Live Resin + Distillate 1g cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T18:20:58.783235Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300042B95000015238",
      "sku": "15238",
      "speciesName": null,
      "supplierName": "Dr. Jolly's",
      "terpenes": null,
      "type": "Live Resin + Distillate cart",
      "variantId": "67f4f622-fbff-4cd4-b371-43adab34ca60",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 7.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 7.9
        },
        {
          "lowerRange": 70.47,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 70.47
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-20T18:17:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1fcc5c05-9c55-4489-a9dd-8152bedb5b70",
      "parentProductName": "Dr. Jolly's House Blend 10:1  CBD:THC FECO",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "e87e04c1-1a2c-4017-9c6d-854a607b81b4",
      "productName": "Dr. Jolly's House Blend 10:1  CBD:THC FECO",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T18:17:38.019682Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300042B95000015237",
      "sku": "15237",
      "speciesName": null,
      "supplierName": "Dr. Jolly's",
      "terpenes": null,
      "type": "CBD FECO",
      "variantId": "f16efaaf-8d9e-432a-bb6c-f2ba0499e22f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 2.64,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 2.64
        },
        {
          "lowerRange": 32.65,
          "name": "cbg",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 32.65
        },
        {
          "lowerRange": 29.76,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.76
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-20T18:16:29Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "197ab0a8-c84e-4a1f-a6d7-588a5a3781f8",
      "parentProductName": "Dr. Jolly's House Blend 1:1 THC: CBG FECO",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "8e7393a3-ce4c-46a9-a4e7-c420f5b186de",
      "productName": "Dr. Jolly's House Blend 1:1 THC: CBG FECO",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T18:16:29.271057Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300042B95000015234",
      "sku": "15234",
      "speciesName": null,
      "supplierName": "Dr. Jolly's",
      "terpenes": null,
      "type": "CBD FECO",
      "variantId": "17cf58e2-0da9-4989-8040-fe4539100af3",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 40.81,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 40.81
        },
        {
          "lowerRange": 0.13,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.13
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-20T18:14:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b3ccc379-74ef-4fbd-b542-49b0f5f23b34",
      "parentProductName": "Dr. Jolly's House Blend 1:1 CBN:THC  FECO",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "ae648f79-a0b7-4a9c-bae1-2ba77bd9a3f6",
      "productName": "Dr. Jolly's House Blend 1:1 CBN:THC  FECO",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T18:14:46.454436Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300042B95000015235",
      "sku": "15235",
      "speciesName": null,
      "supplierName": "Dr. Jolly's",
      "terpenes": null,
      "type": "CBD FECO",
      "variantId": "7467084f-5b1c-4d73-822e-bc3bbcbe0c49",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 21.4,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.4
        },
        {
          "lowerRange": 21.61,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.61
        },
        {
          "lowerRange": 20.61,
          "name": "cbg",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.61
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-20T18:12:53Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2887f9cd-ab4c-44a8-8571-da3c58ca4e47",
      "parentProductName": "Dr. Jolly's House Blend 1:1:1:1 FECO",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "2affdd0f-715c-4228-88ce-bdaa1f369746",
      "productName": "Dr. Jolly's House Blend 1:1:1:1 FECO",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T18:12:53.14579Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300042B95000015236",
      "sku": "15236",
      "speciesName": null,
      "supplierName": "Dr. Jolly's",
      "terpenes": null,
      "type": "CBD FECO",
      "variantId": "75d0bac9-59c4-48a3-b10f-282e402cda0c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 21.58,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.58
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 38,
      "createdAt": "2024-06-20T17:07:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "964c65e2-d693-4f32-b24a-1fdf1c6654ce",
      "parentProductName": "Ghost Vapor OG House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "da9c5af4-23db-408b-95a2-7581abbcbcd2",
      "productName": "Ghost Vapor OG House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T17:07:20.290559Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000071B1000003088",
      "sku": "C5COtg3YZF",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "54895ac2-c119-4156-b762-2477488cc564",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 28.23,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.23
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": "23.7,23.7",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "23.7,23.7"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 110,
      "createdAt": "2024-04-24T16:46:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5a14f48a-c703-44c1-81c0-6b86c7471c29",
      "parentProductName": "Tomahawk by Oregon Roots",
      "postTaxPriceInPennies": 600,
      "preTaxPriceInPennies": 500,
      "priceInMinorUnits": 500,
      "productDescription": "GG4 x Stardawg",
      "productId": "cbf184ba-b886-44f9-962d-ea5a201a978e",
      "productName": "Tomahawk by Oregon Roots - BOTH - Bulk",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T16:47:34.359494Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 82.14,
      "regulatoryId": "1A40103000343F2000019871",
      "sku": "VfgxSz5EJn",
      "speciesName": "Indica-dom",
      "supplierName": "Oregon Roots",
      "terpenes": null,
      "type": "Flower",
      "variantId": "66ddf510-9f98-4f8d-b287-c3265c20a58b",
      "variantName": "BOTH - Bulk",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 500
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 1583
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 2833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 5167
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 9500
        }
      ]
    },
    {
      "brand": "Frontier Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 22,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22
        },
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 220,
      "createdAt": "2024-04-26T01:07:17Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e5a78cac-4b23-4ffa-8a00-23e27be1972c",
      "parentProductName": "Chocolate Chunk By Frontier",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "d48f2068-46d7-4434-a1c2-3f8620f1615e",
      "productName": "Chocolate Chunk By Frontier",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T16:47:12.394646Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 83.07,
      "regulatoryId": "1A4010300010BF9000040361",
      "sku": "0361",
      "speciesName": "Indica",
      "supplierName": "Frontier Farms - HQ",
      "terpenes": null,
      "type": "Bulk",
      "variantId": "5f18a4a7-3a55-4346-9c45-5d34c80c7dc1",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": "Kola Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.61,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.61
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-06-13T16:35:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f16a7794-272f-42b1-b4f1-0270a4f5f543",
      "parentProductName": "Animal Mintz by Caldwell Cannabis",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "c16700cd-f97c-4a31-bc3f-27caa46dc1dd",
      "productName": "Animal Mintz by Caldwell Cannabis",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T16:38:44.423651Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 20.1,
      "regulatoryId": "1A4010300034969000014800",
      "sku": "hLOzafjuPp",
      "speciesName": "Sativa",
      "supplierName": "Kola Brand",
      "terpenes": null,
      "type": null,
      "variantId": "913dfe27-e2cd-42b2-b0c1-6511b46dab0d",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Hoodview",
      "cannabinoidInformation": [
        {
          "lowerRange": 20.41,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.41
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 239,
      "createdAt": "2024-05-08T19:21:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a9ea0167-ce9a-483f-8a64-55ff041d7ecd",
      "parentProductName": "Hazy Train by Volcanic Roots",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "2dac50d1-40a3-40c7-945c-b9b7c4a1f4e9",
      "productName": "Hazy Train by Volcanic Roots",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T16:38:27.195141Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 77.8,
      "regulatoryId": "1A4010300042041000039834",
      "sku": "vFaNBemibu",
      "speciesName": "Sativa",
      "supplierName": "HOODVIEW CANNABIS LLC",
      "terpenes": null,
      "type": null,
      "variantId": "92f525cc-0313-47b7-aa02-136a890d47a6",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "virgin",
      "cannabinoidInformation": [
        {
          "lowerRange": 22.1,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.1
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": "0,0",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-05-29T18:35:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "34b836ff-085c-4405-b7be-30a94fa1928a",
      "parentProductName": "Kona Gold by Virgin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "40a3e3b2-3b8b-4bba-a139-0dceec2c967a",
      "productName": "Kona Gold by Virgin - BOTH - Bulk",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-20T16:38:07.539824Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 64.47,
      "regulatoryId": "1A401030002A4F5000025468",
      "sku": "vmQnnQTh5I",
      "speciesName": "Sativa-dom",
      "supplierName": "Virgin Cannabis, Wolf Creek Cannabis",
      "terpenes": null,
      "type": "Flower",
      "variantId": "0e8b8d92-8c63-40f5-9449-fc079af45050",
      "variantName": "BOTH - Bulk",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Better Edibles",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-20T16:21:23Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4ddf6a88-133a-44c1-b99b-a8d2a27d9474",
      "parentProductName": "Fifty-One Fifty By Better Edibles",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "2a486bff-f33f-4165-9f58-f862d0e3d020",
      "productName": "Fifty-One Fifty By Better Edibles",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T16:21:23.167407Z",
      "productWeight": 9,
      "purchaseCategory": "rec",
      "quantity": 31,
      "regulatoryId": "1A4010300016A1D000014027",
      "sku": "14027",
      "speciesName": null,
      "supplierName": "C&R Products, LLC",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "b6fa35b3-df98-4c09-aae2-8c3cdf561bfc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Better Edibles",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 100,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-20T16:20:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c513b5ab-ad91-4df4-a6c8-ab2c117648cd",
      "parentProductName": "Fifty-One  Fifty +CBG 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "ac20e799-19e5-4980-85d2-b10329fa88bb",
      "productName": "Fifty-One  Fifty +CBG 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T16:20:07.99728Z",
      "productWeight": 9,
      "purchaseCategory": "rec",
      "quantity": 45,
      "regulatoryId": "1A4010300016A1D000014028",
      "sku": "14028",
      "speciesName": null,
      "supplierName": "C&R Products, LLC",
      "terpenes": null,
      "type": "CBD Edible",
      "variantId": "c5388bb4-190d-4d19-b7cd-8a11b12e788e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 327.8,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 327.8
        },
        {
          "lowerRange": 0.45,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0.45
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 300,
      "createdAt": "2024-06-19T20:51:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-09-19",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cc00bf1d-6aed-432c-81bf-08814b37f9e1",
      "parentProductName": "Easy - GMO - 1g Infused Preroll",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "6344f144-99ac-4083-b6c8-f40a304e888a",
      "productName": "Easy - GMO - 1g Infused Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-20T15:35:20.179372Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000003281",
      "sku": "3281",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "8236dc7d-a464-4444-801d-17c4cf861f9a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 75.31,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 75.31
        },
        {
          "lowerRange": 0.47,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.47
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-19T20:46:51Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8a9eadca-69cd-4d04-a611-12555898872a",
      "parentProductName": "Easy - Sherb Crasher - 1g live resin cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "5be924d1-6ce5-4660-94ac-e6f32fcc07dc",
      "productName": "Easy - Sherb Crasher - 1g live resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-19T20:46:51.162918Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000003278",
      "sku": "3278",
      "speciesName": null,
      "supplierName": "Easy Extracts",
      "terpenes": null,
      "type": "Live Resin Cartridge",
      "variantId": "42f78d96-3546-4c3d-9b0c-6d24cb0b7f9c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 74.59,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.59
        },
        {
          "lowerRange": 0.4,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.4
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-19T20:44:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "42b3923c-a450-4c51-85e5-e9e45f0c226a",
      "parentProductName": "Easy - Sundae Driver - 1g live resin cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "e8f97b88-bee0-4b67-977e-854b07d4a71a",
      "productName": "Easy - Sundae Driver - 1g live resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-19T20:44:21.136961Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000003280",
      "sku": "3280",
      "speciesName": null,
      "supplierName": "Easy Extracts",
      "terpenes": null,
      "type": "Live Resin Cartridge",
      "variantId": "e263497e-8589-492d-8925-26101ed92a8c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 74.28,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.28
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-19T20:41:49Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bbe8693c-6d8e-4a11-8466-6d3943b3fdbf",
      "parentProductName": "Easy - Dr. Who - 1g Live Resin",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "42980858-aaf6-4d69-a4e1-b2f0a626b23e",
      "productName": "Easy - Dr. Who - 1g Live Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-19T20:41:49.014485Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000003274",
      "sku": "3274",
      "speciesName": "Indica",
      "supplierName": "Easy Extracts",
      "terpenes": null,
      "type": "Live Resin",
      "variantId": "6d84a8d9-bc91-4473-9cfc-928fe6f3d0ac",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Archive",
      "cannabinoidInformation": [
        {
          "lowerRange": 87.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 87.1
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-18T22:13:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "accbd07e-52ba-4151-853f-cf6c7a735ba5",
      "parentProductName": "Archive - Tangerine - 100mg Hash Rosin Gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "806466ad-4611-4409-a3e7-5da4a6d4cedf",
      "productName": "Archive - Tangerine - 100mg Hash Rosin Gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-19T17:11:25.043972Z",
      "productWeight": 36,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300011EBA000000729",
      "sku": "00729",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "ce346c2f-fc3e-4a65-a699-545fadab951a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 31.33,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 31.33
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 33,
      "createdAt": "2024-06-19T16:16:14Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "92997daa-154f-41a1-9bbd-72c35cc28bd5",
      "parentProductName": "Caribbean Cookie Cross House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "6bf053e6-a20e-4608-8a76-03af811f9f4d",
      "productName": "Caribbean Cookie Cross House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-19T16:16:14.492616Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000071B1000003087",
      "sku": "gJz4lKhi8q",
      "speciesName": "Sativa",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "22de6d90-fa3c-47f1-aa94-fa2b226ddc22",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-18T22:56:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ed6cbd34-2dc5-4053-bf68-f4f7cb7b1b77",
      "parentProductName": "Smokiez Sour Green Apple THC/CBG Multipack Gummiez 100mg",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "42aaf22f-151a-49dd-a0af-6083e3a28a6d",
      "productName": "Smokiez Sour Green Apple THC/CBG Multipack Gummiez 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:56:56.926199Z",
      "productWeight": 60,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A4010300011EBA000000734",
      "sku": "0734",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "173728da-b6bd-4a0f-b3ca-5884742b4460",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-18T22:55:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "282b7fe9-7ed0-4388-9697-93bfb916519f",
      "parentProductName": "Smokiez Sour Tropical CBD/THC Multipack Gummiez 100mg",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "561c0d30-1f3f-4996-8ab6-4e2ee6e815b7",
      "productName": "Smokiez Sour Tropical CBD/THC Multipack Gummiez 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:55:20.007691Z",
      "productWeight": 60,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A4010300011EBA000000736",
      "sku": "00736",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "0f4e546b-fd10-4842-a7fc-c349a6689ed0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-18T22:53:53Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b060be68-d3ab-43d8-9357-aca5ee9ba54d",
      "parentProductName": "Smokiez Sour Blackberry THC/CBN Multipack Gummiez 100mg",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "af9d27f7-0247-4dee-ae8d-9857359dab99",
      "productName": "Smokiez Sour Blackberry THC/CBN Multipack Gummiez 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:53:53.587423Z",
      "productWeight": 60,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300011EBA000000733",
      "sku": "0733",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "182c87b8-57a3-4aff-bdfa-31c81d06fb74",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 0,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-18T22:46:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "45aa8555-55b2-4b5a-ae51-21314f1fe02a",
      "parentProductName": "Smokiez - Sweet Passionfruit - Multipack gummies",
      "postTaxPriceInPennies": 901,
      "preTaxPriceInPennies": 750,
      "priceInMinorUnits": 750,
      "productDescription": "",
      "productId": "66df1be2-6fb3-4c36-930d-95c5736dbebe",
      "productName": "Smokiez - Sweet Passionfruit - Multipack gummies",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:46:57.814848Z",
      "productWeight": 36,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300011EBA000000735",
      "sku": "00735",
      "speciesName": "Sativa",
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "a16845cc-72d5-4d38-b780-09b9ede31e18",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-18T22:45:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "55c2cc85-e2f5-41f6-b0ee-bc72c00693b2",
      "parentProductName": "Smokiez - Sweet Guava Indica Live Resin Gummy -  Multipack",
      "postTaxPriceInPennies": 901,
      "preTaxPriceInPennies": 750,
      "priceInMinorUnits": 750,
      "productDescription": "",
      "productId": "97e37e88-a748-4d27-985a-c4662e60fc8e",
      "productName": "Smokiez - Sweet Guava Indica Live Resin Gummy -  Multipack",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:45:22.512073Z",
      "productWeight": 36,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A4010300011EBA000000731",
      "sku": "0731",
      "speciesName": "Indica",
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "c3c40a04-d2ef-4fef-8999-c141c5a6780c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 275,
      "createdAt": "2024-06-18T22:42:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8b6938c1-a5e4-44c5-8de4-8f39c0d8f679",
      "parentProductName": "Smokiez Sweet Blue Raspberry Indica Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "169babae-e45a-4d84-ac9b-f7c6eb624d73",
      "productName": "Smokiez Sweet Blue Raspberry Indica Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:42:12.360772Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300011EBA000000730",
      "sku": "0730",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "8efae42d-7b32-4970-bc30-3691f20fa030",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 275,
      "createdAt": "2024-06-18T22:40:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6fc1d7cc-3e19-43c2-a8d1-ecc02797765b",
      "parentProductName": "Smokiez - Sweet Peach single gummy - 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "ff19eb7a-c43f-4d2e-8412-59e44133318e",
      "productName": "Smokiez - Sweet Peach single gummy - 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:40:25.856891Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 12,
      "regulatoryId": "1A4010300011EBA000000732",
      "sku": "0732",
      "speciesName": "Sativa",
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "6d3b88c7-8ae5-4466-9eb6-397439eb5cdf",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 275,
      "createdAt": "2024-06-18T22:15:59Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d3a0cccf-0df1-4666-b9fa-2ad552fc16f1",
      "parentProductName": "Smokiez Sour Watermelon Hybrid Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "675e7d34-1ba7-49bd-8821-120d9fd980ab",
      "productName": "Smokiez Sour Watermelon Hybrid Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:15:59.465844Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 16,
      "regulatoryId": "1A4010300011EBA000000737",
      "sku": "00737",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "d10c8464-afd8-4499-9b0c-da9ac62ed173",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokiez",
      "cannabinoidInformation": [
        {
          "lowerRange": 89.8,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 89.8
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-18T22:11:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2ecd41b0-46dc-41a5-9cca-43be87d326e0",
      "parentProductName": "Archive - Blackberry - 100mg Hash Rosin Gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "6aa00625-58b6-40ef-928a-261b52847dc1",
      "productName": "Archive - Blackberry - 100mg Hash Rosin Gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T22:11:43.022792Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300011EBA000000728",
      "sku": "00728",
      "speciesName": null,
      "supplierName": "High Life Distribution",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "2ed9ddcf-6961-47ed-a903-aa9255b33d9f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 24.46,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.46
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 44,
      "createdAt": "2024-06-18T20:41:37Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5003d020-3a9a-4b88-ab73-ad08c618c0c6",
      "parentProductName": "Lemon Fresh House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "1001daa4-eefe-4b0a-9819-f5835a9fc3d7",
      "productName": "Lemon Fresh House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T20:41:37.247277Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 48,
      "regulatoryId": "1A40103000071B1000003086",
      "sku": "2n5OkXaGJq",
      "speciesName": "Sativa",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "48dd1f0f-0608-430b-8be1-57bd9b1ce8d9",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 22.98,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.98
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-18T20:33:51Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "67f516f3-f361-4d6a-89b6-d5d79aaeb645",
      "parentProductName": "Wappa House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "c4da67b3-9e69-4b8f-86d3-26dcce00bf29",
      "productName": "Wappa House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T20:33:51.101585Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A40103000071B1000003085",
      "sku": "7CH9WoQiYS",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "4236629a-718c-4aca-b4ca-cb0655b3b842",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 817.3,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 817.3
        },
        {
          "lowerRange": 81.73,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.73
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-18T19:30:24Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0e2d678e-8bd5-4950-93f4-268865d215eb",
      "parentProductName": "Freshy Blueberry Burst Cartridge 1g",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "a570426f-4734-42ef-b7bf-b5720323a23e",
      "productName": "Freshy Blueberry Burst Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:30:24.307889Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030002259D000068872",
      "sku": "68872",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "17af8930-1ac7-4f04-bc35-b88aec0d5b5b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 811.8,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 811.8
        },
        {
          "lowerRange": 81.18,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.18
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-18T19:29:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "963a329d-f630-45ba-ba0e-b623e394ed9a",
      "parentProductName": "Freshy Watermelon Splash Cartridge 1g",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "b5356736-944e-477e-b153-8fea7a7313ec",
      "productName": "Freshy Watermelon Splash Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:29:30.657584Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030002259D000068874",
      "sku": "68874",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "25338da7-4e69-4374-a003-aefddd10dc20",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 816.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 816.1
        },
        {
          "lowerRange": 81.61,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.61
        },
        {
          "lowerRange": 0.16,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.16
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-18T19:28:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "412bcaf8-a6a7-4d79-9635-f3ec3076c555",
      "parentProductName": "Freshy - Banana Candy - 1g Live Resin cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "73b612b3-9926-4ffa-aa13-aee6c4444eb2",
      "productName": "Freshy - Banana Candy - 1g Live Resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:28:32.790229Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A401030002259D000068875",
      "sku": "68875",
      "speciesName": "Sativa",
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "247f51b7-f93b-4a9a-81d5-c199f58ac81f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 792.7,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 792.7
        },
        {
          "lowerRange": 79.27,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 79.27
        },
        {
          "lowerRange": 0.91,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.91
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-18T19:27:29Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "770989c6-b1c6-4b7e-923e-9e3b7255df4b",
      "parentProductName": "Freshy Cartridge Strawberry Fields 4Ever 1g",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "b4170bc9-a324-4a2f-a62d-9ac720c7001b",
      "productName": "Freshy Cartridge Strawberry Fields 4Ever 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:27:29.94495Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030002259D000068873",
      "sku": "68873",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "a3bec800-9f91-4271-bbaf-11022c6e1928",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 807.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 807.1
        },
        {
          "lowerRange": 80.71,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 80.71
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-18T19:26:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c32492ba-94fa-4d6f-a819-2bf5ce6ebd8d",
      "parentProductName": "Freshy Pineapple Blast Cartridge 1g",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "20f8e943-79af-4e7e-9bb8-6fbc82d7bafa",
      "productName": "Freshy Pineapple Blast Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:26:36.327868Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030002259D000068871",
      "sku": "68871",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "3ddc58d2-e420-43d6-a67b-d8832a67648c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 317.7,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 317.7
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 333,
      "createdAt": "2024-06-18T19:24:15Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-09-19",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "226d9afc-c944-48e3-aa7f-f0484b2cc6c2",
      "parentProductName": "National Cannabis - Blackberry Cobbler - 1g Infused",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "3c8a843b-2740-4e29-8a23-9549728daa6c",
      "productName": "National Cannabis - Blackberry Cobbler - 1g Infused",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:24:15.139932Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 21,
      "regulatoryId": "1A401030000D995000034564",
      "sku": "34564",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "09afc89c-78ce-435d-9d79-6c28c6d67b44",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 332.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 332.1
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 333,
      "createdAt": "2024-06-18T19:21:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-09-18",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3aae3cef-2c1f-40e9-be93-0ad9a24354bd",
      "parentProductName": "National Cannabis - Mighty Quinn - 1g Infused",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "5f399cb1-766c-4495-8c29-715c0d0dc00a",
      "productName": "National Cannabis - Mighty Quinn - 1g Infused",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:21:56.741654Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 21,
      "regulatoryId": "1A401030000D995000035277",
      "sku": "35277",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "74788425-21e4-46ab-882e-ebe0b411189f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 84.2,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 84.2
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-06-18T19:17:44Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bdc9e497-f30b-40d2-a0ef-c71a646086d7",
      "parentProductName": "National Cannabis Co - Mimosa x Lemon Diesel - 1g cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "683e2ce1-5711-4c57-aea0-1bd2f1977c1a",
      "productName": "National Cannabis Co - Mimosa x Lemon Diesel - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:17:44.13174Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030000D995000034665",
      "sku": "34665",
      "speciesName": null,
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "c42d4a8a-7322-493b-b200-17b7f1d3ecf2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 844.9,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 844.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 84.49,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 84.49
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-18T19:16:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "686a62c8-3c3f-470e-82fd-db357134430a",
      "parentProductName": "National Cannabis Co. - Mango Mimosa - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "da4d111e-0ae6-4d76-b7e5-b780f65b9ec1",
      "productName": "National Cannabis Co. - Mango Mimosa - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:16:10.067035Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A401030000D995000035373",
      "sku": "35373",
      "speciesName": "Sativa",
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "79e28675-821d-4b69-b424-1d8ad6ac364d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 820.9,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 820.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 82.09,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82.09
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-18T19:14:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2224efa6-c9cd-4557-bebb-6fa10b14e7db",
      "parentProductName": "National Cannabis Co. - Guava - 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "5ccd65c8-ec05-4b07-b423-9a9c8548d5cd",
      "productName": "National Cannabis Co. - Guava - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:14:03.277149Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A401030000D995000035092",
      "sku": "35092",
      "speciesName": null,
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "c46b2711-2184-4fc7-a2aa-307f2b408ed6",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 180,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 180
        },
        {
          "lowerRange": 19.54,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 19.54
        },
        {
          "lowerRange": 18,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 18
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 375,
      "createdAt": "2024-06-18T19:12:02Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cad27c06-4e04-4993-888f-4ad8cd1f349c",
      "parentProductName": "National Cannabis Co - Blackberry Kush - 1:1 infused preroll",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "7e312eae-32cb-4884-a96e-753ebb3ba213",
      "productName": "National Cannabis Co - Blackberry Kush - 1:1 infused preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:12:02.724325Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A401030000D995000033118",
      "sku": "33118",
      "speciesName": null,
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": "CBD Infused",
      "variantId": "9d8ca5dd-6cf2-48f4-be62-4999ae3e4709",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "B3",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1600,
      "createdAt": "2024-06-18T19:05:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7946541f-e45a-4079-ad81-cd9c092eeca8",
      "parentProductName": "Lookah Bear",
      "postTaxPriceInPennies": 3200,
      "preTaxPriceInPennies": 3200,
      "priceInMinorUnits": 3200,
      "productDescription": "",
      "productId": "78bc6772-8ca7-4be9-a12d-3ab15acd59e1",
      "productName": "Lookah Bear",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:05:57.75578Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 1,
      "regulatoryId": "",
      "sku": "JLBKTBf7oa",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "e9af6c4b-8f68-4f41-8bf5-3195053eb77a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "B3",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-18T19:05:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f28a570a-23e4-4063-90cf-4623594d27c2",
      "parentProductName": "YoCan - ARI Slim",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "c5e0a251-386f-4a4c-8ba5-791bd2fa3945",
      "productName": "YoCan - ARI Slim",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:05:43.697568Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 17,
      "regulatoryId": "",
      "sku": "EswynqosVJ",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "1abbc004-82af-4432-9567-8b801c7b2790",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "B3",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-18T19:05:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2611b9ce-8dd0-475a-a5fe-8cbc7e15f805",
      "parentProductName": "Yocan - Kodo Pro",
      "postTaxPriceInPennies": 1500,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "e77f68ab-7bd5-4b2d-b6a2-cb66286e6cf9",
      "productName": "Yocan - Kodo Pro",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-18T19:05:25.151396Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 17,
      "regulatoryId": "",
      "sku": "SP6yF8XjSW",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "62e478ea-ae07-4cd0-b2c0-585adb666b22",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Koru Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 35.47,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 35.47
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 220,
      "createdAt": "2024-06-18T17:27:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "59a0c6eb-befc-41c3-9d29-82bfc7cca61d",
      "parentProductName": "Purple Push Pop by Koru Cannabis",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "940a31d4-333c-411b-b8f6-c248b4d39ad5",
      "productName": "Purple Push Pop by Koru Cannabis",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-18T17:27:20.990981Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 116.39,
      "regulatoryId": "1A40103000542E1000004422",
      "sku": "z5XWhA6yhu",
      "speciesName": "Indica",
      "supplierName": "Koru Cannabis Wholesale",
      "terpenes": null,
      "type": null,
      "variantId": "f2109931-bae1-4595-a05b-5d8d9effb738",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "Green State of Mind",
      "cannabinoidInformation": [
        {
          "lowerRange": 108,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 108
        },
        {
          "lowerRange": 107,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 107
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-17T22:28:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6164ec0e-63f0-4b96-b8a5-61a2f79b6158",
      "parentProductName": "Botz - Watermelon 1:1 THC:CBD - 100mg gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "d84f39f3-21c4-46ff-a9b5-3de163fd5e08",
      "productName": "Botz - Watermelon 1:1 THC:CBD - 100mg gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T22:28:38.784958Z",
      "productWeight": 8,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300009029000041695",
      "sku": "41695",
      "speciesName": "Hybrid",
      "supplierName": "Green State of Mind, Inc.",
      "terpenes": null,
      "type": "CBD Edible",
      "variantId": "d9474dfb-aa6e-4637-94db-38f8fa8a37b8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green State of Mind",
      "cannabinoidInformation": [
        {
          "lowerRange": 103.76,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 103.76
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-17T22:20:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4e2c0ed3-f128-484d-b408-5ff2e60dc10a",
      "parentProductName": "Botz - Mango - 100mg gummy",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "b103f417-0dce-446d-aaa2-20fea9f831d2",
      "productName": "Botz - Mango - 100mg gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T22:20:21.355843Z",
      "productWeight": 8,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A4010300009029000041692",
      "sku": "41692",
      "speciesName": "Indica",
      "supplierName": "Green State of Mind, Inc.",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "f8ee9909-c614-4802-8f1b-38bf928d1f40",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green Dragon Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 37.2,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 37.2
        },
        {
          "lowerRange": 39.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 39.8
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-17T20:13:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e6f44346-0524-4290-ab32-889905df4b62",
      "parentProductName": "Green Dragon -Critical Strawberry 1:1 - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "8405c94a-6ac9-415c-8d88-e09156051095",
      "productName": "Green Dragon -Critical Strawberry 1:1 - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T20:13:36.783594Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A40103000405B3000028257",
      "sku": "28257",
      "speciesName": null,
      "supplierName": "Green Dragon Wholesale",
      "terpenes": null,
      "type": "CBD Cart",
      "variantId": "4d435361-6e89-4392-9110-94e5f40c2774",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green Dragon Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 1.33,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 1.33
        },
        {
          "lowerRange": 82.2,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82.2
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-17T20:11:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cc7f80af-0629-4d1b-b83e-0e5ec3916eda",
      "parentProductName": "Green Dragon - LA Kush Cake - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "55a32b9b-21b8-4782-b73e-f34d76b7d562",
      "productName": "Green Dragon - LA Kush Cake - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T20:11:25.294243Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A40103000405B3000028258",
      "sku": "28258",
      "speciesName": null,
      "supplierName": "Green Dragon Wholesale",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "db44b6e2-881c-46ba-a10b-d6f30eba85a7",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green Dragon Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.35,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.35
        },
        {
          "lowerRange": 82.01,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82.01
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-17T20:10:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "98616fbe-5107-4da0-ad9b-62c6a23dba56",
      "parentProductName": "Green Dragon - Tokyo Snow - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "ba818796-cbef-432b-852c-294d66db6cbb",
      "productName": "Green Dragon - Tokyo Snow - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T20:10:22.220658Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A40103000405B3000028259",
      "sku": "28259",
      "speciesName": null,
      "supplierName": "Green Dragon Wholesale",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "d64c434a-ba24-427f-86bf-4072d4008c07",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 24.48,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.48
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 198,
      "createdAt": "2024-06-17T17:59:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "69b78f7c-1adc-4d74-8f3c-1bd45b8a17ed",
      "parentProductName": "Crystal Cookies House Preroll",
      "postTaxPriceInPennies": 550,
      "preTaxPriceInPennies": 458,
      "priceInMinorUnits": 458,
      "productDescription": "",
      "productId": "ed0afe3c-8a18-4e29-8779-a0d1ee430125",
      "productName": "Crystal Cookies House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T17:59:05.861973Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 34,
      "regulatoryId": "1A40103000071B1000003083",
      "sku": "tggbKc27QK",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "594fa22b-7481-4eee-ab78-a8c36caaa3dc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 22.94,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.94
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 288,
      "createdAt": "2024-06-17T17:54:16Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "53bf7dc3-85be-4c01-835c-f48508d24979",
      "parentProductName": "Hash Burger House Preroll",
      "postTaxPriceInPennies": 650,
      "preTaxPriceInPennies": 542,
      "priceInMinorUnits": 542,
      "productDescription": "",
      "productId": "baeb07cb-8e92-45ef-9d41-3169e34aac00",
      "productName": "Hash Burger House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T17:54:16.721876Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 12,
      "regulatoryId": "1A40103000071B1000003082",
      "sku": "2HWumdeK0W",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "b68a3320-4751-4047-8740-3c367ffb4505",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 22.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.5
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 187,
      "createdAt": "2024-06-17T17:40:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "855096a3-e680-40ba-9e2e-05b7d2e3be1c",
      "parentProductName": "Platinum GSC House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "3fc0a5a1-e3e4-40f4-8110-9c7dd4273ce9",
      "productName": "Platinum GSC House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T17:40:45.919423Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 21,
      "regulatoryId": "1A40103000071B1000003080",
      "sku": "pPmfeatEXd",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "9daea719-348a-463a-9601-80b4a971304b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green State of Mind",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 100,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 300,
      "createdAt": "2024-06-12T20:18:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fcca73a4-ee87-49b8-8b81-dbed654b3848",
      "parentProductName": "Botz - Sour Apple 1:1 THC:CBG - 100mg gummy",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "240ca307-dd92-46a2-a81e-45a3ae1da480",
      "productName": "Botz - Sour Apple 1:1 THC:CBG - 100mg gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T15:02:01.90144Z",
      "productWeight": 8,
      "purchaseCategory": "rec",
      "quantity": 29,
      "regulatoryId": "1A4010300009029000041606",
      "sku": "41606",
      "speciesName": "Hybrid",
      "supplierName": "Green State of Mind, Inc.",
      "terpenes": null,
      "type": "CBD Edible",
      "variantId": "a1897a93-44dc-4f79-ab7c-44d2e893d336",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Feel Good",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0.32,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0.32
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-05-30T22:53:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "af48aa77-8ccf-484b-9b06-08d60ad92cb3",
      "parentProductName": "Dr. Feel Good - Blue Raspberry Live Resin Hybrid - 100mg 10pk",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "20ad5fbf-51eb-47fa-9310-7a6cc32b468f",
      "productName": "Dr. Feel Good - Blue Raspberry Live Resin Hybrid - 100mg 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T14:59:01.702593Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300042681000003149",
      "sku": "3149",
      "speciesName": "Hybrid",
      "supplierName": "Green Beehive",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "880d0f95-a057-4065-9792-6fca8e1c1d69",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 107.5,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 107.5
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-05-11T17:28:31Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "bd719961-b464-4ef9-b866-5adf97e468d3",
      "parentProductName": "CHOMPD - Blue Razz - 100mg 10pk",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "a885ea66-1843-4fc5-9044-98d562290113",
      "productName": "CHOMPD - Blue Razz - 100mg 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-17T04:46:25.694999Z",
      "productWeight": 27,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A401030002DADD000015074",
      "sku": "15074",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "4f43bc13-0dcd-40c7-9e5d-8f2978d85e11",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.58,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.58
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-14T20:36:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1499fda2-5cd2-4aa1-81d1-e7e945f34e3b",
      "parentProductName": "Chronic Extracts - Pineapple Banner - 2g badder",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "85c40221-734e-49be-ba77-58411d19aeb0",
      "productName": "Chronic Extracts - Pineapple Banner - 2g badder",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T20:36:30.18774Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300021E31000003949",
      "sku": "3949",
      "speciesName": "Sativa",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "badder",
      "variantId": "d77a36d9-d01b-4b93-be5d-c0eae28a1cca",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Chronic Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.58,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.58
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-14T20:30:02Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "294747fd-0983-4d7c-a15e-0950aab5c56c",
      "parentProductName": "Chronic Extracts - Pineapple Banner - 1g shatter",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "081aa8d0-e0c4-4beb-8d03-e1504a47426f",
      "productName": "Chronic Extracts - Pineapple Banner - 1g shatter",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T20:30:02.607192Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300021E31000003716",
      "sku": "3716",
      "speciesName": "Sativa",
      "supplierName": "Chronic Extracts",
      "terpenes": null,
      "type": "Shatter",
      "variantId": "3f0b6d52-8439-4057-bf62-313877f35e7e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.11,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.11
        },
        {
          "lowerRange": 32.93,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 32.93
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 198,
      "createdAt": "2024-06-14T18:27:15Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f622fcfc-3819-4649-861e-5cf92975fbc5",
      "parentProductName": "GMO Zkittles House Preroll",
      "postTaxPriceInPennies": 550,
      "preTaxPriceInPennies": 458,
      "priceInMinorUnits": 458,
      "productDescription": "",
      "productId": "de082f02-df41-4f02-acc7-1ae27a6cb17c",
      "productName": "GMO Zkittles House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T18:27:15.254693Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A40103000071B1000003077",
      "sku": "mCDeeZ3ubc",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "a947f6f6-7c00-4346-a613-cd522d25074d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.06,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.06
        },
        {
          "lowerRange": 23.04,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.04
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 198,
      "createdAt": "2024-06-14T18:13:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a8a0ee26-523e-458b-bc62-23145cac1fd3",
      "parentProductName": "Mendo Ultraviolet House Preroll",
      "postTaxPriceInPennies": 550,
      "preTaxPriceInPennies": 458,
      "priceInMinorUnits": 458,
      "productDescription": "",
      "productId": "01d55a19-6078-4e1c-ac10-cd9fa6bcbca8",
      "productName": "Mendo Ultraviolet House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T18:13:10.857949Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A40103000071B1000003076",
      "sku": "QmpSP0NTq4",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "0a004030-5f84-4f87-bfb3-a43943cb0edb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 23.74,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.74
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 287,
      "createdAt": "2024-06-14T18:06:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b6dc2202-c577-4d0d-ab22-0363fd1808a5",
      "parentProductName": "Cake Mintz House Preroll",
      "postTaxPriceInPennies": 550,
      "preTaxPriceInPennies": 458,
      "priceInMinorUnits": 458,
      "productDescription": "",
      "productId": "c76e2d19-1246-42a4-98d1-9e2df40a1b0c",
      "productName": "Cake Mintz House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T18:06:12.858598Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 21,
      "regulatoryId": "1A40103000071B1000003075",
      "sku": "RDwPhjjTnu",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "72783ea3-e701-4f87-bc61-9d667e4f464f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Keef",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Liquid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-09T00:05:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "daa74c95-7557-4cff-b6e4-826019bba4b6",
      "parentProductName": "Blue Razz 100mg Soda by Keef",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "93de0fca-8290-4976-bc66-d402dfd86d7f",
      "productName": "Blue Razz 100mg Soda by Keef",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T16:11:46.439627Z",
      "productWeight": 12,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A4010300010BFA000276626",
      "sku": "276626",
      "speciesName": null,
      "supplierName": "Good Day Distro",
      "terpenes": null,
      "type": "Soda",
      "variantId": "fb1ab4ac-3da2-42cf-857a-2f24a4da6afd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crescendo Concentrates",
      "cannabinoidInformation": [
        {
          "lowerRange": 46.57,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 46.57
        },
        {
          "lowerRange": 46.57,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 46.57
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-14T16:08:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2f480762-e13f-4e37-8a4c-2e6791b8e19a",
      "parentProductName": "Crescendo Concentrates - Critical Breath - 1g Bubble Hash",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "6bec5f40-dd3d-4fb9-92d0-34f5b47c08fb",
      "productName": "Crescendo Concentrates - Critical Breath - 1g Bubble Hash",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T16:08:25.556009Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A4010300051AA5000000863",
      "sku": "00863",
      "speciesName": "Indica",
      "supplierName": "Bubble's Hash",
      "terpenes": null,
      "type": "Bubble Hash",
      "variantId": "6789aac0-6bfc-45e7-b16d-0bb37f91a1c5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crescendo Concentrates",
      "cannabinoidInformation": [
        {
          "lowerRange": 48.81,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 48.81
        },
        {
          "lowerRange": 0.27,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.27
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-14T16:06:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ece7722c-4e3d-45c6-9cf6-2aec47ef6f20",
      "parentProductName": "Crescendo Concentrates - Zookies - 1g Bubble Hash",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "282839cd-3293-4598-90d0-ebbc1b9d1e25",
      "productName": "Crescendo Concentrates - Zookies - 1g Bubble Hash",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T16:06:57.496573Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A4010300051AA5000000862",
      "sku": "00862",
      "speciesName": "Hybrid",
      "supplierName": "Bubble's Hash",
      "terpenes": null,
      "type": "Bubble Hash",
      "variantId": "9090665e-ed34-435f-950c-2d3d0d56387c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crescendo Concentrates",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 49.25,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 49.25
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-14T16:05:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9cb13bd4-35bf-4eb3-9c35-727033821142",
      "parentProductName": "Crescendo Concentrates - Jellyfish - 1g Bubble Hash",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "abf23578-aa62-491a-b2cf-8739ca67be20",
      "productName": "Crescendo Concentrates - Jellyfish - 1g Bubble Hash",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T16:05:22.007282Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 24,
      "regulatoryId": "1A4010300051AA5000000861",
      "sku": "00861",
      "speciesName": "Sativa",
      "supplierName": "Bubble's Hash",
      "terpenes": null,
      "type": "Bubble Hash",
      "variantId": "85d17efe-a7a2-40db-bc46-446b68ae9ebf",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Liquid Loud",
      "cannabinoidInformation": [
        {
          "lowerRange": 991.38,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 991.38
        },
        {
          "lowerRange": 3.2,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 3.2
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2024-06-14T15:56:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "feb63ea0-65b9-4255-af84-1984e9fa436f",
      "parentProductName": "Liquid Loud - Strawberry Apricot  - 1000mg THC Mouth Spray",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 3333,
      "priceInMinorUnits": 3333,
      "productDescription": "",
      "productId": "680e7e40-7525-431c-8fbc-5aff702fe6e2",
      "productName": "Liquid Loud - Strawberry Apricot  - 1000mg THC Mouth Spray",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T15:57:09.711149Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A40103000543A9000001405",
      "sku": "01405",
      "speciesName": null,
      "supplierName": "Element 02 LLC",
      "terpenes": null,
      "type": "Mouth Spray",
      "variantId": "9f0d42a3-50d6-44e6-a247-c9d1236c2cfb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Liquid Loud",
      "cannabinoidInformation": [
        {
          "lowerRange": 991.38,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 991.38
        },
        {
          "lowerRange": 3.2,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 3.2
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2024-06-14T15:53:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "34eec6fb-7470-4a45-9a45-29c6834db863",
      "parentProductName": "Liquid Loud - Exotic Peppermint - 1000mg THC Mouth Spray",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 3333,
      "priceInMinorUnits": 3333,
      "productDescription": "",
      "productId": "19f5311c-6895-47e3-8555-e2e16deabbef",
      "productName": "Liquid Loud - Exotic Peppermint - 1000mg THC Mouth Spray",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T15:53:46.903471Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A40103000543A9000001404",
      "sku": "1404",
      "speciesName": null,
      "supplierName": "Element 02 LLC",
      "terpenes": null,
      "type": "Mouth Spray",
      "variantId": "8adb2e51-455a-4d1c-affe-a0d49d23aa96",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Arise",
      "cannabinoidInformation": [
        {
          "lowerRange": 78.2,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 78.2
        },
        {
          "lowerRange": 0.65,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.65
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-14T15:47:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "db688c13-7951-4e97-af6e-857468b3bd0a",
      "parentProductName": "Arise Labs - Candy Crush - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "592bc29f-e34e-4c31-b8e1-1d972a69cf14",
      "productName": "Arise Labs - Candy Crush - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T15:47:07.215467Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A40103000357DD000007655",
      "sku": "7655",
      "speciesName": null,
      "supplierName": "Arise Cannabis",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "188e47a4-6f04-4858-a789-ade1f5ded350",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Arise",
      "cannabinoidInformation": [
        {
          "lowerRange": 84.72,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 84.72
        },
        {
          "lowerRange": 0.26,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.26
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-06-14T15:45:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d0f722eb-2cdb-4bf6-a1e2-e7fa9560cee7",
      "parentProductName": "Arise Labs - Lava Cake - 1g Cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "9bd5b457-15e1-4d58-bd42-2ec8924a8769",
      "productName": "Arise Labs - Lava Cake - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-14T15:45:54.936301Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000357DD000007740",
      "sku": "7740",
      "speciesName": null,
      "supplierName": "Arise Cannabis",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "9b3f2e19-68f4-41fe-a757-7f0f707f784d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 502,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 502
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-13T22:41:02Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "89de806c-27db-48c8-a6ee-ce3529950ad9",
      "parentProductName": "Crown B Alchemy - Blackberry - 500mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "e5a21b63-2d3f-48d2-abab-4434eaeb9ee1",
      "productName": "Crown B Alchemy - Blackberry - 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:41:02.761927Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300022B79000021490",
      "sku": "21490",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "Syrup",
      "variantId": "5c7371d5-4752-4879-a467-e593b1bb3081",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 500,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 500
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-13T22:37:57Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9440a32a-9a03-459d-8b23-9d9c4cce5ed1",
      "parentProductName": "Crown B Alchemy - Blue Raspberry - 500mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "90e58a12-a3ba-40d3-a7b6-11c33bf1c8d6",
      "productName": "Crown B Alchemy - Blue Raspberry - 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:37:57.284552Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300022B79000021491",
      "sku": "21491",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "Syrup",
      "variantId": "d32673b5-9b46-4d65-9f7e-0013c80eec24",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 250,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 250
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-13T22:33:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ee7099ca-340f-4620-acdb-e9ce11c95555",
      "parentProductName": "Crown B Alchemy - Green Apple - 250mg",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "32aa3195-0af7-439e-a7e0-a8c98d4bc2e4",
      "productName": "Crown B Alchemy - Green Apple - 250mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:33:43.292693Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A4010300022B79000021492",
      "sku": "21492",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "Syrup",
      "variantId": "2e72b4c0-2775-43d8-bb63-0cc4e25fdd59",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 214,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 214
        },
        {
          "lowerRange": 215,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 215
        },
        {
          "lowerRange": 215,
          "name": "cbn",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 215
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-06-13T22:28:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fef6a77c-93fa-4053-8cf2-4e2c2ee129fa",
      "parentProductName": "Moonlight Serenade 1:1:1 600mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "d5769e22-d6dc-4516-9176-ba011f95a627",
      "productName": "Moonlight Serenade 1:1:1 600mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:28:12.98426Z",
      "productWeight": 4,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300022B79000021493",
      "sku": "21493",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "CBD Syrup",
      "variantId": "00350cca-87f4-44c5-a5db-4d1cc40afe5f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "ERB",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.18,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.18
        },
        {
          "lowerRange": 61.86,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 61.86
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1400,
      "createdAt": "2024-06-13T22:20:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9443ea83-bff1-461b-ae35-e5ab1ab861a4",
      "parentProductName": "ERB - Chem Dog - 1g Rosin",
      "postTaxPriceInPennies": 2800,
      "preTaxPriceInPennies": 2333,
      "priceInMinorUnits": 2333,
      "productDescription": "",
      "productId": "5c7ab780-ef7f-46e2-999b-42b10a7920f5",
      "productName": "ERB - Chem Dog - 1g Rosin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:20:45.882788Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030002D119000002282",
      "sku": "2282",
      "speciesName": "Indica",
      "supplierName": "Foggy Mountain Refinery",
      "terpenes": null,
      "type": "Rosin",
      "variantId": "fc621147-d3ed-4cc2-8a13-88e8f9eb5aa6",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Millerville",
      "cannabinoidInformation": [
        {
          "lowerRange": 45,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 45
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 625,
      "createdAt": "2024-06-13T22:19:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "37f436ab-92b7-4798-90ad-1a74a675c88b",
      "parentProductName": "Space Puffs - Pistachio - 50mg",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "ab439b4c-d788-4bca-b036-24dc59edc8d9",
      "productName": "Space Puffs - Pistachio - 50mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:19:33.583863Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A401030002D119000002281",
      "sku": "2281",
      "speciesName": null,
      "supplierName": "Foggy Mountain Refinery",
      "terpenes": null,
      "type": "Edible",
      "variantId": "a8f99063-18ef-45d0-86ed-65efe4248f5d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Kleen Karma Gardens",
      "cannabinoidInformation": [
        {
          "lowerRange": 28.79,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.79
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 375,
      "createdAt": "2024-06-13T22:03:51Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9bb92385-d0f6-4d44-81dc-de6cac7b4a74",
      "parentProductName": "Kleen Karma Gardens - Death Star - 1g 2pk",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "765420e4-8773-471a-9c38-0fa82a751a3f",
      "productName": "Kleen Karma Gardens - Death Star - 1g 2pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:03:51.334422Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A401030000CBE9000015402",
      "sku": "15402",
      "speciesName": "sativa",
      "supplierName": "Kleen Karma Gardens Inc.",
      "terpenes": null,
      "type": "2 in a tube",
      "variantId": "e53fe5bc-7c3b-40cc-8938-a6274adea8db",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Kleen Karma Gardens",
      "cannabinoidInformation": [
        {
          "lowerRange": 33.34,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 33.34
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 375,
      "createdAt": "2024-06-13T22:01:49Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "303b9f0a-d64f-4b5e-8ccc-3ed565dad367",
      "parentProductName": "Kleen Karma Gardens - Banana Cream x Jealousy - 1g 2pk",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "76af57f1-dd26-4afd-a65a-59450add77ed",
      "productName": "Kleen Karma Gardens - Banana Cream x Jealousy - 1g 2pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T22:01:49.054174Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 13,
      "regulatoryId": "1A401030000CBE9000015401",
      "sku": "15401",
      "speciesName": "Indica",
      "supplierName": "Kleen Karma Gardens Inc.",
      "terpenes": null,
      "type": "2 in a tube",
      "variantId": "2394c601-fed4-48ed-989a-754abcd20ade",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.98,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.98
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-13T21:57:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "21a048da-22d7-493e-8406-ac87302c65cf",
      "parentProductName": "Oregon Roots - 9 Pound Hammer - 1g preroll",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "f7e06587-4403-4af3-a099-a2bf7ed9502b",
      "productName": "Oregon Roots - 9 Pound Hammer - 1g preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T21:57:22.188669Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 46,
      "regulatoryId": "1A40103000343F2000021015",
      "sku": "21015",
      "speciesName": "Indica",
      "supplierName": "Oregon Roots",
      "terpenes": null,
      "type": "Preroll",
      "variantId": "5ce5a7e2-6901-41c3-9079-b2b176da6bfd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.53,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.53
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-06-13T21:55:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b5056797-2810-4ca6-8edb-bea167e56408",
      "parentProductName": "Oregon Roots - Strawberry OG - 1g preroll",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "7abdc97e-d771-4e66-922f-bf4d3d124445",
      "productName": "Oregon Roots - Strawberry OG - 1g preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T21:55:09.616205Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 47,
      "regulatoryId": "1A40103000343F2000020287",
      "sku": "20287",
      "speciesName": "Sativa",
      "supplierName": "Oregon Roots",
      "terpenes": null,
      "type": "Preroll",
      "variantId": "4b5d886e-b59c-4730-8fc1-ce3edee957c0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 27.68,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27.68
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 260,
      "createdAt": "2024-06-13T21:53:14Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "296f39bc-11fd-485e-910d-dc342101f10e",
      "parentProductName": "Oregon Roots - Dosi Sherbet - 0.5g 2pk",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "bc045601-1c9c-40ed-9731-aa1528b5891c",
      "productName": "Oregon Roots - Dosi Sherbet - 0.5g 2pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T21:53:14.327453Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 47,
      "regulatoryId": "1A40103000343F2000021034",
      "sku": "21034",
      "speciesName": "Indica",
      "supplierName": "Oregon Roots",
      "terpenes": null,
      "type": "2 in a tube",
      "variantId": "7afaa703-33e7-4f34-9c8f-5d8e8192306f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 27.68,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27.68
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 260,
      "createdAt": "2024-06-13T21:51:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9eb2f203-356e-407b-9e2d-e4acf4d9759e",
      "parentProductName": "Oregon Roots - Blue Dream - 0.5g 2pk",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "0ab19ee6-36ba-4702-b050-fec099a9c6e2",
      "productName": "Oregon Roots - Blue Dream - 0.5g 2pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T21:51:03.00775Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 49,
      "regulatoryId": "1A40103000343F2000020860",
      "sku": "20860",
      "speciesName": "Sativa",
      "supplierName": "Oregon Roots",
      "terpenes": null,
      "type": "2 in a tube",
      "variantId": "4f3af35f-31a7-4e79-9fc5-b1e7abdb0aab",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.27,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.27
        },
        {
          "lowerRange": 79.71,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 79.71
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-13T21:48:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0d652b0d-86c9-46d2-8ebb-5591313df543",
      "parentProductName": "Oregon Roots Tomahawk Cartridge 1g",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "e6bf4a9e-68e2-4279-a069-4d31fccb45b0",
      "productName": "Oregon Roots Tomahawk Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T21:48:47.34574Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 14,
      "regulatoryId": "1A40103000343F2000021275",
      "sku": "21275",
      "speciesName": null,
      "supplierName": "Oregon Roots",
      "terpenes": null,
      "type": "CO2 Cartridge",
      "variantId": "ae584908-9b9e-45fd-8c76-ced48b1d3c84",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Gud Gardens",
      "cannabinoidInformation": [
        {
          "lowerRange": 28.46,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.46
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 110,
      "createdAt": "2024-04-15T17:20:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9021bac5-05a3-4cb4-938c-09f70b6683e8",
      "parentProductName": "Garlic Sherbet by Gud Gardens",
      "postTaxPriceInPennies": 600,
      "preTaxPriceInPennies": 500,
      "priceInMinorUnits": 500,
      "productDescription": "",
      "productId": "bafbe80a-480d-4557-9a3c-15bc69ff32ed",
      "productName": "Garlic Sherbet by Gud Gardens",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-13T17:03:35.784293Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 66.95,
      "regulatoryId": "1A401030003D609000018132",
      "sku": "ip37Z047AU",
      "speciesName": "Indica",
      "supplierName": "The Dub Wholesale",
      "terpenes": null,
      "type": null,
      "variantId": "cd3fba24-e608-4047-90c6-cfd7cd622c88",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 500
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 1583
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 2833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 5167
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 9500
        }
      ]
    },
    {
      "brand": null,
      "cannabinoidInformation": [
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        },
        {
          "lowerRange": 31.3,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 31.3
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 329,
      "createdAt": "2024-05-28T16:51:50Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cb93a4f6-ab7e-42f0-8a1c-2d4b0340411c",
      "parentProductName": "Lemon Royale by Makru Farms",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "a7230990-c5ac-48e2-aa4e-0d943bf42308",
      "productName": "Lemon Royale by Makru Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-13T17:01:08.403461Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 16.8,
      "regulatoryId": "1A4010300019B56000009527",
      "sku": "4tK3VRjAuQ",
      "speciesName": "sativa",
      "supplierName": "Makru Farms",
      "terpenes": null,
      "type": null,
      "variantId": "b30294de-c4f9-4dab-9118-0de13f9f81ca",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "PCM",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.06,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.06
        },
        {
          "lowerRange": 21.09,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.09
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 109,
      "createdAt": "2024-03-26T23:26:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "70a5d20e-9024-4651-9996-e1b898a5920e",
      "parentProductName": "Grape Milkshake by Knownot Farms",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "b059734f-6562-4503-ab65-6ea388218207",
      "productName": "Grape Milkshake by Knownot Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-13T16:57:40.85104Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 102.83,
      "regulatoryId": "1A40103000263B9000001955",
      "sku": "Hb9caYtgd4",
      "speciesName": "Indica",
      "supplierName": "Portland Cannabis Market",
      "terpenes": null,
      "type": null,
      "variantId": "bb079994-2ebb-4cfe-b574-92a9b8d27417",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 88.1,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 88.1
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 833,
      "createdAt": "2024-06-13T15:42:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "584b08ba-f8d5-4aff-9898-2dcf635cecf8",
      "parentProductName": "Portland Pot Shop - Jungle Juice - 1g Cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "fee71a70-8b98-448a-8946-781ee2debb82",
      "productName": "Portland Pot Shop - Jungle Juice - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-13T15:42:46.967395Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300008CA2000040880",
      "sku": "40880",
      "speciesName": null,
      "supplierName": "The CO2 Company",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "6774bb61-0f2f-4d7b-a38e-5fbd5f140487",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Boring Glory",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 26.81,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.81
        },
        {
          "lowerRange": "0,26.81",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,26.81"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2023-05-24T23:31:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3d76097a-f6ca-4a7d-80c7-adb1bbac3c32",
      "parentProductName": "Sparkling Donkey by Boring Glory",
      "postTaxPriceInPennies": 1600,
      "preTaxPriceInPennies": 1333,
      "priceInMinorUnits": 1333,
      "productDescription": "",
      "productId": "17376674-7078-4825-b737-2e6e600c3730",
      "productName": "Sparkling Donkey by Boring Glory - BOTH - 1.0g - Pre-Roll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-12T16:54:06.463333Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 27,
      "regulatoryId": "1A4010300028295000007310",
      "sku": "7310",
      "speciesName": "Indica-dom",
      "supplierName": "Boring Glory LLC",
      "terpenes": null,
      "type": "Flower",
      "variantId": "78c84e77-4196-4b4a-aa8c-2faec42b6266",
      "variantName": "BOTH - 1.0g - Pre-Roll",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 22.96,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.96
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 142,
      "createdAt": "2024-06-12T16:47:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6ba96636-1c31-4ed8-a63c-ca7ab98967fa",
      "parentProductName": "Gummiez House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "5433e910-7a07-48d3-b001-a3187bc1e742",
      "productName": "Gummiez House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-12T16:47:30.209216Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A40103000071B1000003067",
      "sku": "a7Ww6sEQNU",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "bc0a297e-ea0a-4ed7-987b-ae1b97f63246",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 23.54,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.54
        },
        {
          "lowerRange": "21.05,23.54",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "21.05,23.54"
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-11T21:35:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "df52da0c-f660-4066-a139-d917ee6dea12",
      "parentProductName": "Soar House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "b4de87c9-5853-4fab-a3a4-206e8d4aaf7b",
      "productName": "Soar House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-11T21:35:54.215379Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000071B1000003066",
      "sku": "lpaq3kTjQB",
      "speciesName": null,
      "supplierName": "Portland Pot Shop",
      "terpenes": [],
      "type": "House Preroll",
      "variantId": "0c413f1b-4f06-4674-bc97-efbc16ff53fd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Lifted Northwest",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.1,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.1
        },
        {
          "lowerRange": 76.44,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 76.44
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-04-17T22:47:50Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1a49da65-ce03-4241-abf1-3bedd57ae686",
      "parentProductName": "Lifted - Sour Face - 1g",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "6fd1dcf2-9846-4a9a-95a4-b1e47b3ea278",
      "productName": "Lifted - Sour Face - 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-10T22:52:09.838552Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A4010300042ACF000033373",
      "sku": "33373",
      "speciesName": null,
      "supplierName": "Lifted Northwest",
      "terpenes": null,
      "type": "Crumble",
      "variantId": "8966c579-0577-4a6f-af48-ec0622e2a895",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Siren",
      "cannabinoidInformation": [
        {
          "lowerRange": 29.29,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.29
        },
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1100,
      "createdAt": "2024-06-10T20:51:24Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6a856e89-b462-4588-bba5-856258834e14",
      "parentProductName": "Siren - Compound Z - 0.5g 10pk",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "5467fde7-98be-42fd-b4c2-eda139579a40",
      "productName": "Siren - Compound Z - 0.5g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-10T20:51:24.554999Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300018A25000019632",
      "sku": "19632",
      "speciesName": "Indica",
      "supplierName": "Siren",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "e443fb75-4a59-4e9f-a67a-8cc62a83f0cc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Makru Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        },
        {
          "lowerRange": 29.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.5
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 285,
      "createdAt": "2024-06-10T19:32:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6d2fa7e8-eb90-48c4-a505-300108bf0820",
      "parentProductName": "London Broil by Makru Farms",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "410657f0-54cc-4fb8-8808-98d721b2378b",
      "productName": "London Broil by Makru Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-10T19:32:54.97667Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 15.15,
      "regulatoryId": "1A4010300019B56000009420",
      "sku": "k7LewTlSyr",
      "speciesName": "Hybrid",
      "supplierName": "Makru Farms",
      "terpenes": null,
      "type": null,
      "variantId": "e4b18f59-2096-4ead-90fc-2deae3c2d355",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 23.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.6
        },
        {
          "lowerRange": 0.06,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.06
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 55,
      "createdAt": "2024-06-10T18:27:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "54776ab9-3422-4cd3-9b9b-3b5fdb2e7abc",
      "parentProductName": "Cosmic Punch House Preroll",
      "postTaxPriceInPennies": 351,
      "preTaxPriceInPennies": 292,
      "priceInMinorUnits": 292,
      "productDescription": "",
      "productId": "82f47e71-029e-4d71-929c-303e9a68ee85",
      "productName": "Cosmic Punch House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-10T18:27:55.504966Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A40103000071B1000003053",
      "sku": "SYt3xoMTjx",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "01751fde-c2d7-47a4-9893-2a9bf12c8206",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 675,
      "createdAt": "2024-06-10T16:39:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "013a657e-530a-48ee-b3de-09048e9125da",
      "parentProductName": "CHOMPD - Tropical + Rainbow - multipack gummies",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "657a5662-af0e-4c37-90ae-0bae6eec04eb",
      "productName": "CHOMPD - Tropical + Rainbow - multipack gummies",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-10T16:39:08.198364Z",
      "productWeight": 65,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030002DADD000015077",
      "sku": "15077",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "0cfe6edf-22b8-4559-aecb-b730f5cbddb8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 86.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 86.9
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 833,
      "createdAt": "2024-05-23T19:46:02Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "68fef86c-f062-4b98-8818-104e6a78ba73",
      "parentProductName": "Portland Pot Shop - Coconut Milk  - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "72ceb86c-af53-40f4-ae3f-da189e0fd452",
      "productName": "Portland Pot Shop - Coconut Milk  - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-10T16:38:05.818823Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300008CA2000040499",
      "sku": "40499",
      "speciesName": null,
      "supplierName": "The CO2 Company",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "57959f74-ffdd-4f62-ac3a-099331c028f0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Keef",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Liquid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-09T00:18:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e5f6e20c-7f90-4323-abab-b70284c43546",
      "parentProductName": "Keef - Cannabis Infused Classic Soda - Purple Passion - 100mg",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "67d349cf-7f54-4256-988a-f317004d411a",
      "productName": "Keef - Cannabis Infused Classic Soda - Purple Passion - 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-09T00:18:03.708726Z",
      "productWeight": 12,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300010BFA000276631",
      "sku": "276631",
      "speciesName": null,
      "supplierName": "Good Day Distro",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "c4082656-9053-4d0d-9767-0bbab6150377",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": null,
      "cannabinoidInformation": [
        {
          "lowerRange": 74.85,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.85
        },
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-06-08T22:55:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ff89b982-8ac8-46b5-80c3-fc4eb9383d85",
      "parentProductName": "WLE - Toga Party Punch - 1g Honeycomb",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "68f0fcef-67e0-4c56-a272-6d71cb30f38f",
      "productName": "WLE - Toga Party Punch - 1g Honeycomb",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:55:36.514468Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300032AF1000259303",
      "sku": "259303",
      "speciesName": "hybrid",
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": null,
      "variantId": "5d08499d-2ab3-4ca1-8e62-3031b1e7f813",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 787.6,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 787.6
        },
        {
          "lowerRange": 78.76,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 78.76
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 950,
      "createdAt": "2024-06-08T22:35:11Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d15b7866-5693-4ab8-83b6-1aeb815f6a0e",
      "parentProductName": "WLE - Pineapple Super Silver Haze - Natural Terp blend 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "e1688769-cf7f-49bc-88ad-32e9fd57f4a2",
      "productName": "WLE - Pineapple Super Silver Haze - Natural Terp blend 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:35:11.835249Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300032AF1000259302",
      "sku": "259302",
      "speciesName": "Sativa",
      "supplierName": "White Label Distribution",
      "terpenes": [],
      "type": "Cartridge",
      "variantId": "a4e1fae8-24c3-4a3c-80cd-24b2f18fb24b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "White Label Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 761.9,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 761.9
        },
        {
          "lowerRange": 76.19,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 76.19
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 950,
      "createdAt": "2024-06-08T22:33:31Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "af244b00-97ea-4b7f-9f2e-b15070131ce1",
      "parentProductName": "WLE -  Washington Apple Natural Terp Blend 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "38501003-f6b5-4d63-ba49-864b123482c8",
      "productName": "WLE -  Washington Apple Natural Terp Blend 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:33:31.029886Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300032AF1000259304",
      "sku": "259304",
      "speciesName": null,
      "supplierName": "White Label Distribution",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "73c57cd1-71a0-44a6-97b2-19d0a822bcfa",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Keef",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Liquid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-08T22:28:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "54a08368-265c-4100-a11d-fcdb5d04a649",
      "parentProductName": "Keef - Cannabis Infused Classic Soda - Pineapple X-Press   - 100mg",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "49a4c842-3343-4090-92e0-17cd0d1280b2",
      "productName": "Keef - Cannabis Infused Classic Soda - Pineapple X-Press   - 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:28:32.743961Z",
      "productWeight": 12,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300010BFA000276630",
      "sku": "276630",
      "speciesName": null,
      "supplierName": "Good Day Distro",
      "terpenes": null,
      "type": "Soda",
      "variantId": "a4177139-e2f9-4fa0-b63a-8d0b249d5e21",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Keef",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Liquid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-08T22:26:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6f3c2d37-645a-4a8f-9227-3df045abc939",
      "parentProductName": "Keef - Cannabis Infused Classic Soda - Orange Kush - 100mg",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "fc81a41e-0df7-478c-adc2-8a02ca413c50",
      "productName": "Keef - Cannabis Infused Classic Soda - Orange Kush - 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:26:54.529056Z",
      "productWeight": 12,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300010BFA000276629",
      "sku": "276629",
      "speciesName": null,
      "supplierName": "Good Day Distro",
      "terpenes": null,
      "type": "Soda",
      "variantId": "02c3720c-361e-440a-9d9c-09971fc35bc2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Keef",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Liquid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-08T22:25:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5353f074-0703-4cf6-8e95-f485339aa295",
      "parentProductName": "Keef - Mr. Puffer Cannabis Infused Soda",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "bf4bb1bf-cdcb-44c2-90f0-1f01ec950e78",
      "productName": "Keef - Mr. Puffer Cannabis Infused Soda",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:25:22.247861Z",
      "productWeight": 12,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300010BFA000276628",
      "sku": "276628",
      "speciesName": null,
      "supplierName": "Good Day Distro",
      "terpenes": null,
      "type": "Soda",
      "variantId": "e8289dc8-13f4-4d6a-8fca-10cc01ef4bcf",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Keef",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Liquid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 600,
      "createdAt": "2024-06-08T22:23:26Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ae0a4d6a-63b6-4c7d-a914-81193c24fdfa",
      "parentProductName": "Keef - Cannabis Infused Classic Soda - Bubba Kush Root Cola  - 100mg",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "283dcc2b-ddc5-41cd-9297-8b464a4180a3",
      "productName": "Keef - Cannabis Infused Classic Soda - Bubba Kush Root Cola  - 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-08T22:23:26.97556Z",
      "productWeight": 12,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300010BFA000276627",
      "sku": "276627",
      "speciesName": null,
      "supplierName": "Good Day Distro",
      "terpenes": null,
      "type": "Soda",
      "variantId": "1e70704c-18a8-4daa-a95b-7f7b7adf8ddc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Koru Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 1.82,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 1.82
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 220,
      "createdAt": "2024-04-29T17:25:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b73ad38a-2bdb-4eab-af55-fec836d8b971",
      "parentProductName": "Apples & Bananas by Koru",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "8ea3fcaa-799f-482c-96be-f7db7fc253e1",
      "productName": "Apples & Bananas by Koru",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-08T20:41:16.915204Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 99.72,
      "regulatoryId": "1A40103000542E1000003119",
      "sku": "EnKNAc8kIC",
      "speciesName": "Hybrid",
      "supplierName": "Koru Cannabis Wholesale",
      "terpenes": null,
      "type": null,
      "variantId": "ec8c54aa-cdb9-4dcc-b772-878b7faafff5",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 76.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 76.26
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-06-06T20:16:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "446d5e24-2801-475e-845c-d1fac81a7aa2",
      "parentProductName": "Calyx Crafts - Donny Burger - Diamonds & Sauce 1g",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "ee454590-39f5-43c3-85a7-c386001ea41f",
      "productName": "Calyx Crafts - Donny Burger - Diamonds & Sauce 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T20:16:54.061255Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A40103000576D5000004858",
      "sku": "4858",
      "speciesName": "Indica",
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Diamonds + Sauce",
      "variantId": "5546fd9c-e760-45b8-ab02-04057a65f1af",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 74.14,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.14
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-06T20:14:04Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "52cd3012-b180-4949-81d4-fd5c127ec965",
      "parentProductName": "Calyx Crafts - Peanut Butter Breath x Apricot - 1g live rosin",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "7eb1d081-8cb6-41ca-a7db-8aa73b3337d3",
      "productName": "Calyx Crafts - Peanut Butter Breath x Apricot - 1g live rosin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T20:14:04.018304Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A40103000491ED000015613",
      "sku": "15613",
      "speciesName": "Hybrid",
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Live Rosin",
      "variantId": "a2f19ac3-7b6d-4ea4-b9e3-3bd25b2db526",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 76.44,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 76.44
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-06-06T20:12:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "41d6ada1-9514-4d85-a4e3-08d2e2f39f96",
      "parentProductName": "Bob's Bammer - Cascade Gelonade - 1g Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "f78b8c8b-7a6f-4d94-8b1b-09088600d8ef",
      "productName": "Bob's Bammer - Cascade Gelonade - 1g Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T20:12:58.233381Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000576D5000004004",
      "sku": "4004",
      "speciesName": "Hybrid",
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Cured Resin",
      "variantId": "70fbd6d6-6d62-4863-b7d9-04e1bd632276",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 85.74,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 85.74
        },
        {
          "lowerRange": 0.17,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.17
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-06-06T20:09:17Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f37fc818-8de2-4191-ad53-fb8baf351c1c",
      "parentProductName": "Bob's Bammer - Blue Poison Pancakes - 2g sugar wax",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "6b3c98d1-60c9-4cd6-a9b8-afbf2510d02e",
      "productName": "Bob's Bammer - Blue Poison Pancakes - 2g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T20:09:17.995606Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A40103000491ED000015614",
      "sku": "15614",
      "speciesName": "Sativa",
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Cured Resin Budder",
      "variantId": "9168932f-9043-45b3-8071-ed0c3e36787c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 28.23,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.23
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 200,
      "createdAt": "2024-06-06T20:07:24Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8bd45d95-48aa-4f61-a2c5-ddd08627b56e",
      "parentProductName": "Bob's Bammer - Kimbo Kush - 1g  Preroll",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "bb09ee32-f70a-42cd-8918-b7c2e7833129",
      "productName": "Bob's Bammer - Kimbo Kush - 1g  Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T20:07:24.85722Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 24,
      "regulatoryId": "1A40103000491ED000015651",
      "sku": "15651",
      "speciesName": "Hybrid",
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Preroll",
      "variantId": "d160d5c0-4663-4504-a356-e713c9b9e58e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "NW Kind",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.29,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.29
        },
        {
          "lowerRange": 66.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 66.9
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1200,
      "createdAt": "2024-06-06T19:35:28Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3b72053f-4c73-4539-aa05-b6d3c04a7a36",
      "parentProductName": "NW Kind - Glitter Bomb - 1g cured resin cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "a3a17c57-9373-4f89-8650-aa4c2c613c4b",
      "productName": "NW Kind - Glitter Bomb - 1g cured resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T19:35:28.465036Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000746A000158955",
      "sku": "158955",
      "speciesName": null,
      "supplierName": "NW Kind",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "e1eec7dd-78cc-4fa8-8fe6-d223794b634c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Feel Good",
      "cannabinoidInformation": [
        {
          "lowerRange": 104,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 104
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-05-30T22:55:16Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b2527e13-85e0-4ae5-a94c-cf7bb7ed0659",
      "parentProductName": "Dr. Feel Good - Blue Raspberry Live Resin Indica - 100mg 10pk",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "59fd0671-6e5a-461f-8235-510593715c8a",
      "productName": "Dr. Feel Good - Blue Raspberry Live Resin Indica - 100mg 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T18:23:38.503892Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300042681000004367",
      "sku": "4367",
      "speciesName": "Indica",
      "supplierName": "Green Beehive",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "2d6f9de3-bbab-4210-a532-e99779c97e5e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokes by the Grow",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.139,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.139
        },
        {
          "lowerRange": 25.86,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.86
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-06T17:59:50Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "00d0b003-a2de-4869-8e5e-72af1a5e7004",
      "parentProductName": "Smokes By the Grow - Extreme Cream- .5g - 10pk",
      "postTaxPriceInPennies": 1600,
      "preTaxPriceInPennies": 1333,
      "priceInMinorUnits": 1333,
      "productDescription": "",
      "productId": "080d4101-4687-4133-9d33-95310c664a52",
      "productName": "Smokes By the Grow - Extreme Cream- .5g - 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T17:59:50.961369Z",
      "productWeight": 5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030003FBED000011794",
      "sku": "11794",
      "speciesName": "Indica",
      "supplierName": "SugarTop Buddery",
      "terpenes": [],
      "type": "Preroll Pack",
      "variantId": "238cc97d-cd7d-485c-9b60-d47ee4fac21c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Smokes by the Grow",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.089,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.089
        },
        {
          "lowerRange": 27.82,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27.82
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1100,
      "createdAt": "2024-06-06T17:51:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "64ed19cb-ad39-4d02-8b8f-dec10adfc852",
      "parentProductName": "Smokes by the Grow - Crack Cake - 1g 10pk",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "30fbc89d-a66f-4359-85be-167e31a91f53",
      "productName": "Smokes by the Grow - Crack Cake - 1g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-06T17:51:32.153948Z",
      "productWeight": 10,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030003FBED000013049",
      "sku": "13049",
      "speciesName": "Inidca",
      "supplierName": "SugarTop Buddery",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "43f236fb-dfac-466a-936f-b606f3a1ee70",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "TrueCare",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 25.21,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.21
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 154,
      "createdAt": "2024-06-06T15:45:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a600466a-4397-4c66-b927-e291b97898f5",
      "parentProductName": "Dreamsicle by TrueCare",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "eb5f2a69-59af-419a-b1de-a3308801a419",
      "productName": "Dreamsicle by TrueCare",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-06T16:07:43.761675Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 72.5,
      "regulatoryId": "1A401030001D4C1000007535",
      "sku": "8i5vp8oI1a",
      "speciesName": "Hybrid",
      "supplierName": "TrueCare Farms",
      "terpenes": null,
      "type": null,
      "variantId": "23b046f9-6a72-45a1-abda-dd649aa015b9",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": "Boujee Blendz",
      "cannabinoidInformation": [
        {
          "lowerRange": 920.7,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 920.7
        },
        {
          "lowerRange": 92.07,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 92.07
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-05T22:11:19Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c6815250-3e78-4326-8d25-bc5710c21e11",
      "parentProductName": "Boujee Blendz  Apricot Cartridge 1g",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "125c7d75-74e0-4a87-89e7-8e6c96746933",
      "productName": "Boujee Blendz  Apricot Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-05T22:11:19.437927Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300044689000088159",
      "sku": "88159",
      "speciesName": null,
      "supplierName": "Emerald Valley Wholesale",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "6ab8745d-c0d9-4e59-b51a-8b0e4434abcf",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 67.92,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.92
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-06-05T21:12:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5121fff3-4da4-4fd5-b433-a1eb2f708636",
      "parentProductName": "Easy - Elephant Cracker - 1g Live Resin",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "36b6f1bd-c05a-4033-84a4-4e86d73d9e97",
      "productName": "Easy - Elephant Cracker - 1g Live Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-05T21:12:38.543384Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000003202",
      "sku": "3202",
      "speciesName": "Hybrid",
      "supplierName": "Easy Extracts",
      "terpenes": null,
      "type": "Live Resin Dab",
      "variantId": "562cd800-8f14-4945-8a00-b5c2936704e9",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 50,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 50
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-04T21:20:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b728ff41-c13e-47fb-8f62-ae6b10f9812e",
      "parentProductName": "Drops Raspberry Rosy 1:2 CBD Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "d6115c0a-6884-4760-8fe8-dc90041ea7ec",
      "productName": "Drops Raspberry Rosy 1:2 CBD Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-04T21:20:38.667979Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 16,
      "regulatoryId": "1A4010300032E76000351122",
      "sku": "351122",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "66eb1d89-fc59-486c-9e71-d3aed3a29d0d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-04T21:19:24Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "349d9a26-2bfe-4191-ba6a-ac99e2effd3d",
      "parentProductName": "Drops Lemon Active Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "47a8a52f-9308-41c6-8d33-62d75a9e9e06",
      "productName": "Drops Lemon Active Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-04T21:19:24.957147Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300032E76000351125",
      "sku": "351125",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "7a1de7d0-5848-42c2-a4d3-cffb0e3ef16d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 25,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 25
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-06-04T21:18:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "38355485-5ac6-478a-9058-3f5117be97db",
      "parentProductName": "Drops Strawberry Awake 1:4 CBD Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "2162080d-1160-44bb-b46d-a2dd08133f4b",
      "productName": "Drops Strawberry Awake 1:4 CBD Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-04T21:18:22.602813Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300032E76000351123",
      "sku": "351123",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "bb1978db-ae52-46ac-a53f-bbbbea4968e0",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-06-04T21:15:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d847c200-049e-4ee5-97b1-f74407ac23d8",
      "parentProductName": "Drops Cherry Dreamy 20 Pack Gummies 100mg",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "8ea7ad5d-cfa6-476e-8388-0606b844131a",
      "productName": "Drops Cherry Dreamy 20 Pack Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-04T21:15:45.819742Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300032E76000351121",
      "sku": "351121",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "aa5b5bb1-1b1c-4b94-84da-e4a6e3d7f035",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 807.6,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 807.6
        },
        {
          "lowerRange": 80.76,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 80.76
        },
        {
          "lowerRange": 6.46,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 6.46
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-04T17:25:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c3246e02-92f6-4dcf-897b-6b3ecd631497",
      "parentProductName": "Freshy - Cran-Pom Punch - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "1004ff57-be19-47bb-9255-929e5309bc06",
      "productName": "Freshy - Cran-Pom Punch - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-04T17:25:08.362738Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002259D000062679",
      "sku": "62679",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "c626ee91-182d-4d04-812e-a86cdbb8f3fe",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Freshy",
      "cannabinoidInformation": [
        {
          "lowerRange": 798.4,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 798.4
        },
        {
          "lowerRange": 79.84,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 79.84
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 900,
      "createdAt": "2024-06-04T17:18:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "ae7f1d33-ef18-49c6-80f0-2baea5ccd9ea",
      "parentProductName": "Freshy Cartridge 1g Coconut Pina Colada",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "Coconut Pina Colada",
      "productId": "a76d783f-e09d-448e-ab7b-2fe382a150bb",
      "productName": "Freshy Cartridge 1g Coconut Pina Colada",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-04T17:18:21.0802Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030002259D000062678",
      "sku": "62678",
      "speciesName": null,
      "supplierName": "Elevate Holdings, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "c5e53ca3-7b46-426e-ad36-45c5ab9ed1a7",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": null,
      "cannabinoidInformation": [
        {
          "lowerRange": 77.19,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 77.19
        },
        {
          "lowerRange": 0.15,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.15
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 725,
      "createdAt": "2024-06-03T20:41:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "33dbbefa-52b3-40f7-b51e-e7bdb00b8abf",
      "parentProductName": "Gorge Grown - Gary Goofiesita - 2g sugar wax",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "22c1ce22-53da-47b1-860e-16442e0771ce",
      "productName": "Gorge Grown - Gary Goofiesita - 2g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-03T20:41:07.937464Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030003AD69000003661",
      "sku": "3661",
      "speciesName": "Hybrid",
      "supplierName": "Gorge Grown",
      "terpenes": null,
      "type": null,
      "variantId": "1360b4ea-ff76-4d15-a056-45031867eb9e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Gorge Grown",
      "cannabinoidInformation": [
        {
          "lowerRange": 72.99,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.99
        },
        {
          "lowerRange": 0.16,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.16
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 725,
      "createdAt": "2024-06-03T20:39:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d552ffea-118a-43bd-8c3e-9cf85221ea20",
      "parentProductName": "Gorge Grown - Lemon Lady Bird - 2g sugar wax",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "3ddf979e-1ebc-4e7f-ba7d-6786bafa8490",
      "productName": "Gorge Grown - Lemon Lady Bird - 2g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-03T20:39:08.021962Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A401030003AD69000003663",
      "sku": "3663",
      "speciesName": "Sativa",
      "supplierName": "Gorge Grown",
      "terpenes": null,
      "type": "Sugar Wax",
      "variantId": "2bf8c75c-01ea-4c59-8ec5-cf6722b368d6",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Gorge Grown",
      "cannabinoidInformation": [
        {
          "lowerRange": 83.99,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.99
        },
        {
          "lowerRange": 0.18,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.18
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-03T20:30:46Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "744a3cba-5014-4688-bd7d-2b8759b9813c",
      "parentProductName": "Gorge Grown - Perfect Pair Cake - 1g sugar wax",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "65762a75-6918-4fb5-b1d5-4fb51895ecdd",
      "productName": "Gorge Grown - Perfect Pair Cake - 1g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-03T20:30:46.155471Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030003AD69000003659",
      "sku": "3659",
      "speciesName": "Sativa",
      "supplierName": "Gorge Grown",
      "terpenes": null,
      "type": "cured resin",
      "variantId": "0e8ab9ac-5c1f-48a7-bcc6-022f4025c71c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Gorge Grown",
      "cannabinoidInformation": [
        {
          "lowerRange": 78.06,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 78.06
        },
        {
          "lowerRange": 0.18,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.18
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-06-03T20:19:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cd7ed303-e407-46db-b6dc-f13dd8cdce78",
      "parentProductName": "Gorge Grown - Zkittlez - 1g sugar wax",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "8920ef60-2abf-4dd9-a22c-d44c4b3ad682",
      "productName": "Gorge Grown - Zkittlez - 1g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-06-03T20:19:55.174459Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030003AD69000003658",
      "sku": "3658",
      "speciesName": "Hybrid",
      "supplierName": "Gorge Grown",
      "terpenes": null,
      "type": null,
      "variantId": "dc7f4810-d7f6-4beb-a5a5-c14a3ec7ab98",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "5 Point Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.24,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.24
        },
        {
          "lowerRange": 28,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28
        },
        {
          "lowerRange": "0,28",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,28"
        },
        {
          "lowerRange": "0,0.24",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0.24"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1694,
      "createdAt": "2024-06-03T18:12:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8de40b12-d0a9-4700-ace9-d69b81baf62a",
      "parentProductName": "Truffle Runtz by 5 Point Farms",
      "postTaxPriceInPennies": 2101,
      "preTaxPriceInPennies": 1750,
      "priceInMinorUnits": 1750,
      "productDescription": "",
      "productId": "a2a84546-8336-40f3-a2a1-98186f48e0e8",
      "productName": "Truffle Runtz by 5 Point Farms - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-03T18:12:20.614742Z",
      "productWeight": 7.7,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300022E99000000433",
      "sku": "RVX9Ry2vkA",
      "speciesName": "Indica-dom",
      "supplierName": "5 Points Farms",
      "terpenes": null,
      "type": "Flower",
      "variantId": "a942d4d9-8baa-4b94-8270-8326fd3d9890",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Earl Baker",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.06,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.06
        },
        {
          "lowerRange": 21.99,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.99
        },
        {
          "lowerRange": "0,21.99",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,21.99"
        },
        {
          "lowerRange": "0,0.06",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0.06"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1936,
      "createdAt": "2024-06-03T18:03:48Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "df394408-77ef-417e-bcb2-2c0bc1fdbe54",
      "parentProductName": "Donkey Butter by Earl Baker",
      "postTaxPriceInPennies": 2400,
      "preTaxPriceInPennies": 2000,
      "priceInMinorUnits": 2000,
      "productDescription": "",
      "productId": "aab84334-7f22-4c0f-823b-9be928bbdd47",
      "productName": "Donkey Butter by Earl Baker - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-06-03T18:03:48.762318Z",
      "productWeight": 5.885,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300037CF9000002073",
      "sku": "48l5FOxlSR",
      "speciesName": "Indica-dom",
      "supplierName": "Hunter Distro",
      "terpenes": null,
      "type": "Flower",
      "variantId": "d31aeecf-6aff-4724-b5f1-8b8ab065a820",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Pacific Grove",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.3,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.3
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": "0,0",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 374,
      "createdAt": "2024-05-31T21:09:06Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8afdaf3f-27ba-4830-811c-e4c40e3cde28",
      "parentProductName": "Grapricot Pie by Pacific Grove",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "58075249-b87e-422a-bbb9-f11e6719ea14",
      "productName": "Grapricot Pie by Pacific Grove - BOTH - Bulk",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-31T21:09:06.909638Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 31,
      "regulatoryId": "1A40103000200E5000023449",
      "sku": "3AIvAjsijY",
      "speciesName": "Indica-dom",
      "supplierName": "Pacific Grove",
      "terpenes": null,
      "type": "Flower",
      "variantId": "81c7ccec-c3f9-45c7-b5cb-eb7775440d43",
      "variantName": "BOTH - Bulk",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "KK Works",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        },
        {
          "lowerRange": 29.1,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.1
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 121,
      "createdAt": "2024-02-05T21:56:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9f2e5b82-7347-4614-84c7-7cf6c4506b3a",
      "parentProductName": "N.Y. Cheese by Self Made Farms",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "e3c44806-cc63-4466-9032-d3105b5bd67e",
      "productName": "N.Y. Cheese by Self Made Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-31T19:06:58.479598Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 148.26,
      "regulatoryId": "1A4010300003B63000041047",
      "sku": "QuoYnkwRdd",
      "speciesName": "Sativa",
      "supplierName": "Buzz Box",
      "terpenes": [],
      "type": null,
      "variantId": "7e0c434f-e36d-4c44-8dc9-27a1e051d776",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": "Decibel",
      "cannabinoidInformation": [
        {
          "lowerRange": 249.4,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 249.4
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-05-30T22:29:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-08-30",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d754a1d4-b979-4b53-bd28-55aca925e9f3",
      "parentProductName": "Basslines - Banana Poison #7 - 1g Infused",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "42e5b797-d689-4144-a604-42c709748726",
      "productName": "Basslines - Banana Poison #7 - 1g Infused",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T22:29:52.600875Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030001336D000081180",
      "sku": "81180",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "8e352d23-9849-4d59-b0a3-1f418d4d19b5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "decibelDABS",
      "cannabinoidInformation": [
        {
          "lowerRange": 65.31,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 65.31
        },
        {
          "lowerRange": 0.17,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.17
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1025,
      "createdAt": "2024-05-30T22:24:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4f6790ec-d120-443a-8315-442d4895b813",
      "parentProductName": "Forte - Kosmo Knot - 1g Live Rosin",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "ef4ca226-47ba-4d51-987c-eabce54dd2bd",
      "productName": "Forte - Kosmo Knot - 1g Live Rosin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T22:26:02.30072Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A401030001336D000080636",
      "sku": "80636",
      "speciesName": "Hybrid",
      "supplierName": "The Dub Wholesale",
      "terpenes": null,
      "type": "Live Rosin",
      "variantId": "31ae04ed-00ae-40c9-8e55-aef5d4ba8a0e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "decibelDABS",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.19,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.19
        },
        {
          "lowerRange": 67.32,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.32
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1025,
      "createdAt": "2024-05-30T22:23:17Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b4742195-e9f6-49ed-962b-f8955f1d7cf6",
      "parentProductName": "Forte - Artificial Red - 1g Live Rosin",
      "postTaxPriceInPennies": 2200,
      "preTaxPriceInPennies": 1833,
      "priceInMinorUnits": 1833,
      "productDescription": "",
      "productId": "3649a7c1-bff0-4a11-baf0-559eb133f541",
      "productName": "Forte - Artificial Red - 1g Live Rosin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T22:23:17.982565Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030001336D000080546",
      "sku": "80546",
      "speciesName": "Indica",
      "supplierName": "The Dub Wholesale",
      "terpenes": null,
      "type": "Live Rosin",
      "variantId": "2c8966a0-007b-47c2-9cc0-d8983e06e12c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Decibel",
      "cannabinoidInformation": [
        {
          "lowerRange": 222.3,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 222.3
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-05-30T22:22:06Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-08-30",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "06019189-abe3-43e0-a87b-537cd787b442",
      "parentProductName": "Basslines - Grape Runtz Pie - 1g Infused",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "b3f07cad-5e1f-4867-a902-4181e51cd991",
      "productName": "Basslines - Grape Runtz Pie - 1g Infused",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T22:22:06.581615Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030001336D000081908",
      "sku": "81908",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "b38c64ce-de22-4928-9821-c1c35906e94b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Decibel",
      "cannabinoidInformation": [
        {
          "lowerRange": 420,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 420
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 825,
      "createdAt": "2024-05-30T22:20:48Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-08-30",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a36f1517-2d4e-4cec-bae9-9cd59df82112",
      "parentProductName": "Basslines Duets - Tropical Jack & Cafe Durban - 0.5g 6pk",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "13ad51cb-7218-464b-8c01-40e419fc0898",
      "productName": "Basslines Duets - Tropical Jack & Cafe Durban - 0.5g 6pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T22:20:48.109889Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 15,
      "regulatoryId": "1A401030001336D000082217",
      "sku": "82217",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll Pack",
      "variantId": "c79f217f-44fe-4565-9cc3-5e0ae5ad1967",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Alta Gardens",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 24.56,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.56
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 110,
      "createdAt": "2024-04-24T20:12:53Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "82f7cc5f-f1d7-44f7-bd72-3edb4956f846",
      "parentProductName": "Supercharger by Alta Gardens",
      "postTaxPriceInPennies": 600,
      "preTaxPriceInPennies": 500,
      "priceInMinorUnits": 500,
      "productDescription": "",
      "productId": "48775fb2-283f-4e6e-b19f-10118c9edbf5",
      "productName": "Supercharger by Alta Gardens",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-30T20:10:41.58285Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 99.5,
      "regulatoryId": "1A401030000A85E000021169",
      "sku": "u8EvWr2lq5",
      "speciesName": "Sativa",
      "supplierName": "The Dub Wholesale",
      "terpenes": null,
      "type": null,
      "variantId": "c3dee334-4497-4819-8c98-fe455d9fa514",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 500
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 1583
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 2833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 5167
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 9500
        }
      ]
    },
    {
      "brand": "Lissa's Tasty Treats",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-05-30T19:27:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "90949490-f04f-4d13-90af-184915039244",
      "parentProductName": "Lissa's - Peanut Butter Cookie Bites - 100mg Ten Pack",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "a357594b-224a-492a-8386-c90d171faaf3",
      "productName": "Lissa's - Peanut Butter Cookie Bites - 100mg Ten Pack",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T19:27:58.552531Z",
      "productWeight": 80,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030002DADD000015450",
      "sku": "15450",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "e27e415e-f506-48f6-a9eb-d7f4b012571e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Lissa's Tasty Treats",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 400,
      "createdAt": "2024-05-30T19:27:00Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "38190298-a4a1-4476-aba8-6b619b376338",
      "parentProductName": "Lissa's - Gingersnap Cookie Bites - 100mg Ten Pack",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "ae743841-923a-4807-b9cd-6b555d52e2cf",
      "productName": "Lissa's - Gingersnap Cookie Bites - 100mg Ten Pack",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-30T19:27:00.404561Z",
      "productWeight": 80,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030002DADD000015449",
      "sku": "15449",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "7954f7fe-08c4-413a-b8ce-127ac355f169",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 64.8,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 64.8
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-05-29T20:59:17Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1babbdd8-b9a9-4dde-90c9-875c7e2c03b4",
      "parentProductName": "Altered Alchemy - Warheadz x Mai Tai - 1g live resin",
      "postTaxPriceInPennies": 1600,
      "preTaxPriceInPennies": 1333,
      "priceInMinorUnits": 1333,
      "productDescription": "",
      "productId": "e72fc18c-fa3b-4606-980f-7a08b364faa8",
      "productName": "Altered Alchemy - Warheadz x Mai Tai - 1g live resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-29T20:59:17.031577Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030003833A000032774",
      "sku": "32774",
      "speciesName": "Sativa",
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Live Resin Badder",
      "variantId": "0f93c761-c3f3-46ad-bab2-78f9e4b15392",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 73.19,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 73.19
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-05-29T20:51:31Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8dfdfc0a-8174-4eab-bb33-857f938abcec",
      "parentProductName": "Altered Alchemy - Animal Face - 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "0e771ef2-9acc-4a52-88eb-5b6dea502309",
      "productName": "Altered Alchemy - Animal Face - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-29T20:51:31.388492Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030003833A000032769",
      "sku": "32769",
      "speciesName": null,
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Live Resin + Distillate cart",
      "variantId": "7ddc204b-0c4b-4b57-b61e-574f171e3629",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 67.4,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.4
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-05-29T20:50:13Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "59b966d6-47e6-4288-8bb0-b0e063406cad",
      "parentProductName": "Altered Alchemy - Banana Bread - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "3dbbe771-df3d-4904-90ff-dde991dd0557",
      "productName": "Altered Alchemy - Banana Bread - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-29T20:50:13.62888Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030003833A000032770",
      "sku": "32770",
      "speciesName": null,
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Live Resin + Distillate cart",
      "variantId": "e0a43429-e8ba-474d-8b6d-458eec4efe66",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Altered Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 72.48,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.48
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-05-29T20:48:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5e9d1026-08e6-477c-ae32-01051b3259d1",
      "parentProductName": "Altered Alchemy - Georgia Cheesecake - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "84c06206-0751-4c8b-9f16-f30059c93244",
      "productName": "Altered Alchemy - Georgia Cheesecake - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-29T20:48:33.231541Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030003833A000032771",
      "sku": "32771",
      "speciesName": null,
      "supplierName": "Three Lakes Distribution, LLC",
      "terpenes": null,
      "type": "Live Resin + Distillate cart",
      "variantId": "f2751011-7641-481a-b9db-1b9db83fc729",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mana Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 802,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 802
        },
        {
          "lowerRange": 0.25,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.25
        },
        {
          "lowerRange": 80.2,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 80.2
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-05-28T19:20:16Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4392f029-d0c6-41d0-ba63-d2bcb0a9da59",
      "parentProductName": "Afterglow - Raspberry Rave - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "e219e613-da0d-486c-9c63-b1f53c35ca7d",
      "productName": "Afterglow - Raspberry Rave - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-28T19:20:16.485164Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300038402000026080",
      "sku": "26080",
      "speciesName": null,
      "supplierName": "Mana Distribution",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "5ce1a2b8-8e43-4818-adca-bcf27b4f8116",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mana Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 812,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 812
        },
        {
          "lowerRange": 3.5,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 3.5
        },
        {
          "lowerRange": 81.2,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.2
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-05-28T19:19:07Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "64acc1db-a78c-4bc7-82d1-1e6f2c90e5e1",
      "parentProductName": "After Glow - Golden Pineapple - 1g Flavored Cartirdge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "3744e79a-4638-4f04-ac87-36da9ec2c015",
      "productName": "After Glow - Golden Pineapple - 1g Flavored Cartirdge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-28T19:19:07.774523Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300038402000028948",
      "sku": "28948",
      "speciesName": null,
      "supplierName": "Mana Distribution",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "886ed8a9-eddd-45c2-972f-299c400571a4",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mana Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 885,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 885
        },
        {
          "lowerRange": 0.25,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.25
        },
        {
          "lowerRange": 88.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 88.5
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-05-28T19:17:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "84883a5c-ec6c-443e-982e-352eebcca01b",
      "parentProductName": "After Glow - Strawberry Shortcake - 1g Cart",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "4657f4f8-c1b9-4158-a6ec-76ceded8860d",
      "productName": "After Glow - Strawberry Shortcake - 1g Cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-28T19:17:12.32952Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300016E07000077699",
      "sku": "77699",
      "speciesName": "Sativa",
      "supplierName": "Mana Distribution",
      "terpenes": [],
      "type": "Flavored Cartridge",
      "variantId": "ebc12750-62f2-4261-9fcf-7f321784ebdb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Capital Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 851.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 851.1
        },
        {
          "lowerRange": 85.11,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 85.11
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-04-19T03:47:29Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4c2f008e-081c-47e6-be02-a9c4c0a1bd91",
      "parentProductName": "Capital Cannabis - Tangie - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "b96da6c2-dc97-4e20-866d-4a75e06b0c60",
      "productName": "Capital Cannabis - Tangie - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-27T18:38:34.94727Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300025991000016103",
      "sku": "6103",
      "speciesName": "Sativa",
      "supplierName": "Capital Cannabis",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "2b9acf97-c10c-4a69-817c-b73f43b171ce",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 200,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 200
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-05-27T17:05:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a5a654f3-7875-4223-b673-98dcd5195633",
      "parentProductName": "Mule - Sour P.O.G. 1:2 THC:CBG - 100mg kicker",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "d2577d67-fb6b-46be-9ef9-511ac4214a53",
      "productName": "Mule - Sour P.O.G. 1:2 THC:CBG - 100mg kicker",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-27T17:11:44.610967Z",
      "productWeight": 24.5,
      "purchaseCategory": "rec",
      "quantity": 21,
      "regulatoryId": "1A401030001CC8D000234694",
      "sku": "234694",
      "speciesName": "Sativa",
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "ad221cf2-1dd2-467a-83a5-e81f8359eb05",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 460,
      "createdAt": "2024-05-27T17:04:24Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "dfd5f4d2-e894-45e5-97d3-334808da0bc2",
      "parentProductName": "Mule Extracts NW Berry Kicker 1:1:1 THC/CBD/CBN 100mg",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "8a66ad31-271d-4db5-8a5a-2ec33290c36c",
      "productName": "Mule Extracts NW Berry Kicker 1:1:1 THC/CBD/CBN 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-27T17:04:24.179324Z",
      "productWeight": 24.5,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030001CC8D000234693",
      "sku": "234693",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "57270bc1-44ef-4080-8ba9-f1cb17338b32",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "cbg",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 95.1,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 95.1
        },
        {
          "lowerRange": 96.6,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 96.6
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-05-27T17:02:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "03bb9ba2-6d9f-4366-8934-9597b4d35f7e",
      "parentProductName": "Mule Extracts - Strawberry Lemonade Kicker 1:1:1:1",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "3dd9f3be-6ad7-495e-b19d-147dfe7a1355",
      "productName": "Mule Extracts - Strawberry Lemonade Kicker 1:1:1:1",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-27T17:02:54.294478Z",
      "productWeight": 12.25,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030001CC8D000234695",
      "sku": "234695",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "de368106-8b08-43be-b420-5c428c7a58b2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Cannabis Market",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 20.85,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 20.85
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 88,
      "createdAt": "2024-02-14T00:26:04Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5f5621a2-98e3-4f9e-88e1-36a6d5f3f5dd",
      "parentProductName": "Rumple Kushkins by Sow Canna",
      "postTaxPriceInPennies": 800,
      "preTaxPriceInPennies": 667,
      "priceInMinorUnits": 667,
      "productDescription": "",
      "productId": "26010dcd-b433-47d9-9b49-55b0c08adf58",
      "productName": "Rumple Kushkins by Sow Canna",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-26T16:58:24.385891Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 110.96,
      "regulatoryId": "1A4010300000515000001757",
      "sku": "XNLAfaXRwf",
      "speciesName": "Indica",
      "supplierName": "Portland Cannabis Market",
      "terpenes": null,
      "type": null,
      "variantId": "582bae68-abe6-4844-bcad-4e40108fcb6f",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 667
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2167
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 3833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 7000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 12917
        }
      ]
    },
    {
      "brand": "KK Works",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.09,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.09
        },
        {
          "lowerRange": 29.31,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.31
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 110,
      "createdAt": "2024-04-16T19:57:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cfd51e7c-b574-4a0b-9db8-7d2608919b67",
      "parentProductName": "Gush Mints by Self Made Farms",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "b83dc9a3-7a27-4160-92cc-9162b04e8f55",
      "productName": "Gush Mints by Self Made Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-26T16:58:03.206171Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 72.83,
      "regulatoryId": "1A4010300003B63000044254",
      "sku": "u7cjk7sCz0",
      "speciesName": "Indica",
      "supplierName": "Buzz Box",
      "terpenes": null,
      "type": null,
      "variantId": "69f4403f-8376-4ea7-a1f6-2b8f7366512b",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Ananda Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.87,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.87
        },
        {
          "lowerRange": 17.97,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 17.97
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-05-22T20:25:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "de07ccea-9938-4a13-a5f3-0cff74e76ab4",
      "parentProductName": "Critical Mass by Ananda Farms",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "02cecfd6-fc48-415b-8b10-f9761c5a7d50",
      "productName": "Critical Mass by Ananda Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-24T16:27:32.703395Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 79.5,
      "regulatoryId": "1A4010300000836000009827",
      "sku": "1QO9yS2IT2",
      "speciesName": "CBD Indica",
      "supplierName": "Ananda Farms",
      "terpenes": null,
      "type": "CBD flower",
      "variantId": "1c45fa5c-7a65-4672-b7a7-7751235a59a9",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Farmers First",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.17,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.17
        },
        {
          "lowerRange": 70.29,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 70.29
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-05-23T21:52:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "94740c36-859e-4636-a8fa-5688077a0bcd",
      "parentProductName": "Sol Sap - Black Lime Reserve - 1g Rosin",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "30d7d6fe-f53f-40ae-90f4-dd9140c49b82",
      "productName": "Sol Sap - Black Lime Reserve - 1g Rosin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T21:52:20.642468Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A40103000583B9000002128",
      "sku": "2128",
      "speciesName": null,
      "supplierName": "Farmers First",
      "terpenes": null,
      "type": "Rosin",
      "variantId": "b7286ca6-bcb2-4782-8c8b-40fb20736123",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mary Jane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-05-23T20:58:23Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e2fa1d22-dbe8-4617-9fa7-f35dc505d672",
      "parentProductName": "Maryjane's House of Glass Assorted $10 bowl head",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "eaad1c78-30f1-4781-8878-ef5508dd1247",
      "productName": "Maryjane's House of Glass Assorted $10 bowl head",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T20:58:23.148812Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 5,
      "regulatoryId": "",
      "sku": "Er7HHBu0fn",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "bowl",
      "variantId": "3a2039ed-080f-40e2-b362-c55c21fb0413",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mary Jane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-05-23T20:57:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6f9f8816-d9e4-41c4-a5ac-3aba7eb21148",
      "parentProductName": "Maryjane's House of Glass  2.5\" downstem",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "294ba4b9-a664-4864-88e1-d290a49f0b0e",
      "productName": "Maryjane's House of Glass  2.5\" downstem - Maryjane's House of Glass  3.5\" downstem",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T20:57:52.08126Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 5,
      "regulatoryId": "",
      "sku": "UJcteCQhpw",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "541dde38-f03e-4b79-809f-739ba3b119d6",
      "variantName": "Maryjane's House of Glass  3.5\" downstem",
      "weightTierInformation": null
    },
    {
      "brand": "Mary Jane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-05-23T20:57:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6f9f8816-d9e4-41c4-a5ac-3aba7eb21148",
      "parentProductName": "Maryjane's House of Glass  2.5\" downstem",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "4d13f140-6094-4cca-ab0e-7d15887137fa",
      "productName": "Maryjane's House of Glass  2.5\" downstem - Maryjane's House of Glass  3\" downstem",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T20:57:30.144074Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 7,
      "regulatoryId": "",
      "sku": "lQW0TGCauU",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "135a0abc-c1e2-48fd-a8db-c7ad9268694e",
      "variantName": "Maryjane's House of Glass  3\" downstem",
      "weightTierInformation": null
    },
    {
      "brand": "Mary Jane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-05-23T20:57:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6f9f8816-d9e4-41c4-a5ac-3aba7eb21148",
      "parentProductName": "Maryjane's House of Glass  2.5\" downstem",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "0170faa7-845c-47bf-b077-5f06885d2227",
      "productName": "Maryjane's House of Glass  2.5\" downstem - 2.5\"",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T20:57:05.060763Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 3,
      "regulatoryId": "",
      "sku": "Rg8VNdav7l",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "a3479ae4-9f2f-4a38-bf82-89363c17df7c",
      "variantName": "2.5\"",
      "weightTierInformation": null
    },
    {
      "brand": "Mary Jane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-05-23T20:56:39Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "492fb60e-3479-4d01-9b53-d76d04670c41",
      "parentProductName": "Maryjane's House of Glass  $15 color changing bowl",
      "postTaxPriceInPennies": 1500,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "473456f4-fd08-476f-bfc6-887d183a52d9",
      "productName": "Maryjane's House of Glass  $15 color changing bowl",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T20:56:39.980756Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 4,
      "regulatoryId": "",
      "sku": "otDDDR1ivK",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "831ca884-7a9f-4373-baf2-ecbfc28ee6b4",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mary Jane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-05-23T20:56:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "495f2558-cf3d-4abf-a213-15e6297147ed",
      "parentProductName": "Maryjane's House of Glass Party Bowl",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 2000,
      "priceInMinorUnits": 2000,
      "productDescription": "",
      "productId": "90a31926-16a9-4f7d-a2a5-82a92b199dc4",
      "productName": "Maryjane's House of Glass Party Bowl",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T20:56:12.756767Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 2,
      "regulatoryId": "",
      "sku": "bz0odRAn21",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "bowl",
      "variantId": "7556732f-71d9-4753-a026-2c00bdf54a91",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 72.4,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 72.4
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 833,
      "createdAt": "2024-05-23T19:38:49Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "afa06402-9f12-4c77-95a2-d599c3c34fbc",
      "parentProductName": "Portland Pot Shop - GG #4 - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "9697ec18-1fb0-417d-9a46-a38e6829c497",
      "productName": "Portland Pot Shop - GG #4 - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T19:38:49.600093Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300008CA2000040501",
      "sku": "40501",
      "speciesName": null,
      "supplierName": "The CO2 Company",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "b27e916f-917b-4785-adfb-f27b73c3547a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 85,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 85
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 833,
      "createdAt": "2024-05-23T19:37:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0dd91ed6-1333-455c-9868-b73d306b07c2",
      "parentProductName": "Portland Pot Shop - Granddaddy Purp - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "939005a3-5355-4969-81db-334e059f7c04",
      "productName": "Portland Pot Shop - Granddaddy Purp - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T19:37:10.418137Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300008CA2000040500",
      "sku": "40500",
      "speciesName": null,
      "supplierName": "The CO2 Company",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "2fe489da-4681-4c2a-a631-86f8553af859",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 84.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 84.9
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 833,
      "createdAt": "2024-05-23T19:34:40Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6f2b6160-0383-484f-a9b9-8d299fe60b2b",
      "parentProductName": "Portland Pot Shop - Berry - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "f2d62879-faf8-460d-9032-8a035a1129b8",
      "productName": "Portland Pot Shop - Berry - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T19:34:40.76146Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300008CA2000040502",
      "sku": "40502",
      "speciesName": null,
      "supplierName": "The CO2 Company",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "5747009c-1336-4a33-b82a-1e36233d2ab9",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 24.76,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 24.76
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2024-05-23T19:25:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "844bee5e-ea1b-4511-a598-ee2a75a96478",
      "parentProductName": "Calyx Crafts - White Truffle- 1g Preroll",
      "postTaxPriceInPennies": 501,
      "preTaxPriceInPennies": 417,
      "priceInMinorUnits": 417,
      "productDescription": "",
      "productId": "665c8e4b-a85a-49fa-a587-0fea0f4f68b0",
      "productName": "Calyx Crafts - White Truffle- 1g Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-23T19:25:45.982721Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A40103000576D5000004266",
      "sku": "4266",
      "speciesName": "Indica",
      "supplierName": "Calyx Wholesale",
      "terpenes": [],
      "type": "Preroll",
      "variantId": "ed6de584-ae1e-451a-8532-7131d59b4002",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sugarbud",
      "cannabinoidInformation": [
        {
          "lowerRange": 26.02,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.02
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": "0,0",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 352,
      "createdAt": "2024-05-09T18:24:34Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "be0eedc0-9dc7-4baf-8689-b51e4c92aed5",
      "parentProductName": "Mushroom Cake by Sugarbud",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "62163155-657f-4813-866b-7910eef67beb",
      "productName": "Mushroom Cake by Sugarbud - BOTH - Bulk",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-22T22:24:28.67219Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 127.5,
      "regulatoryId": "1A401030000E7A8000038859",
      "sku": "RPyc2VV6eT",
      "speciesName": "Indica-dom",
      "supplierName": "Sugarbud",
      "terpenes": null,
      "type": "Flower",
      "variantId": "3ab29e7e-81af-4573-81ea-19a7cbcd13af",
      "variantName": "BOTH - Bulk",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "RIP City Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 33.33,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 33.33
        },
        {
          "lowerRange": 0.14,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.14
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 308,
      "createdAt": "2024-05-22T18:53:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cec7b95f-9b15-4ad6-a83a-71616e6caade",
      "parentProductName": "Rubber Bands by Rip City Roots",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "7df56ff3-8a05-4422-b6a6-adacae11cc65",
      "productName": "Rubber Bands by Rip City Roots",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-22T18:53:43.784068Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 114.09,
      "regulatoryId": "1A40103000204CD000006692",
      "sku": "4VnorE5FaF",
      "speciesName": "Sativa",
      "supplierName": "Rip City Roots, LLC",
      "terpenes": null,
      "type": null,
      "variantId": "19c928a2-ddd1-4cd0-879e-f4aa9050a85e",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 309.4,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 309.4
        },
        {
          "lowerRange": 0.48,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0.48
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 300,
      "createdAt": "2024-05-21T20:41:01Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-08-21",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d8d5e5c8-6945-4d60-af34-0dd016281da4",
      "parentProductName": "Easy - Secret Formula - 1g Infused Preroll",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "9dd0613e-da6d-46c5-9ec2-76efdd266ed1",
      "productName": "Easy - Secret Formula - 1g Infused Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-21T20:41:01.922879Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002EA7D000002999",
      "sku": "2999",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "7f82c7aa-46b3-41ff-9327-18ba0e704553",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Easy Brand",
      "cannabinoidInformation": [
        {
          "lowerRange": 83.24,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.24
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 700,
      "createdAt": "2024-05-21T20:19:24Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0b067021-cabc-4c0a-8420-079d5bbd3099",
      "parentProductName": "Easy - Larry OG - 1g Live Resin",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "716032ff-9969-4a38-a466-fe3ed23e9396",
      "productName": "Easy - Larry OG - 1g Live Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-21T20:19:24.549215Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030002EA7D000002991",
      "sku": "2991",
      "speciesName": "Indica",
      "supplierName": "Easy Extracts",
      "terpenes": null,
      "type": "Live Resin",
      "variantId": "37ab2b80-916a-4185-8266-e7dfe0dd6505",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Noblecraft",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.25,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.25
        },
        {
          "lowerRange": 78.39,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 78.39
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-05-21T20:12:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d3015f8b-2337-4259-b99b-5550f4501faa",
      "parentProductName": "Noblecraft - GMO - 1g Live Rosin",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "5cbeff3d-08d4-4482-8129-4350b2222c4e",
      "productName": "Noblecraft - GMO - 1g Live Rosin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-21T20:12:58.89054Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 14,
      "regulatoryId": "1A401030004DA95000002883",
      "sku": "02883",
      "speciesName": "Indica",
      "supplierName": "Cascade Cannabis Solutions",
      "terpenes": null,
      "type": "Rosin",
      "variantId": "4a6f85f5-a09b-41ae-9347-3e2553e7f6a3",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 325,
      "createdAt": "2024-05-17T22:55:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c8b69e55-2fa7-46f3-b328-dff6a4849579",
      "parentProductName": "Drops Cherry Dreamy Single Gummy 100mg",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "157066ad-e14a-47c6-a220-fd12ae69a387",
      "productName": "Drops Cherry Dreamy Single Gummy 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-17T22:55:22.431321Z",
      "productWeight": 7.9,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300032E76000346751",
      "sku": "6751",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "Edibles",
      "variantId": "c4b07b53-c485-4d44-a5ab-2b26886807cb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Drops",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 650,
      "createdAt": "2024-05-17T22:29:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2bfa3522-e19d-40cb-90a8-2bb10d974988",
      "parentProductName": "Drops Cranberry Daydreamy 1:1 CBD 20 Pack Gummies",
      "postTaxPriceInPennies": 1299,
      "preTaxPriceInPennies": 1083,
      "priceInMinorUnits": 1083,
      "productDescription": "",
      "productId": "7e669253-c019-48db-b96c-f302bb25220a",
      "productName": "Drops Cranberry Daydreamy 1:1 CBD 20 Pack Gummies",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-17T22:29:36.447384Z",
      "productWeight": 44,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300032E76000346746",
      "sku": "6746",
      "speciesName": null,
      "supplierName": "Drops",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "2c1182d6-e4ac-4061-bc60-e58af48f2e49",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.07,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.07
        },
        {
          "lowerRange": 21.85,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.85
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 110,
      "createdAt": "2024-05-17T02:26:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "35fd63b1-2a40-446c-9fb4-5a4b5815822e",
      "parentProductName": "Knockout Runtz House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "acad1c4c-9e77-4f8b-bcf7-b04eb704d8c6",
      "productName": "Knockout Runtz House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-17T02:26:12.132998Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 43,
      "regulatoryId": "1A40103000071B1000003015",
      "sku": "3015",
      "speciesName": "Hybrid",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "d4de4dac-63d1-478b-9e4a-fad4f4760a4b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sauce",
      "cannabinoidInformation": [
        {
          "lowerRange": 816,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 816
        },
        {
          "lowerRange": 81.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 81.6
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1800,
      "createdAt": "2024-05-15T20:40:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e18faca0-b9f6-4034-bf12-6702159f8a01",
      "parentProductName": "Sauce - Blueberry Kush - 1g Live Resin Disposable",
      "postTaxPriceInPennies": 3600,
      "preTaxPriceInPennies": 3000,
      "priceInMinorUnits": 3000,
      "productDescription": "",
      "productId": "1871f14d-1ef9-4176-a9ec-232484ba63d1",
      "productName": "Sauce - Blueberry Kush - 1g Live Resin Disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-15T20:40:58.718874Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030003C8C1000069936",
      "sku": "9936",
      "speciesName": "Indica",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Flavored Disposable",
      "variantId": "b5091712-83e8-40f4-8246-8cff7bd2932e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sauce",
      "cannabinoidInformation": [
        {
          "lowerRange": 829,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 829
        },
        {
          "lowerRange": 82.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 82.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1800,
      "createdAt": "2024-05-15T20:35:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0785f8d9-e24e-4782-a397-e61a2c818c5b",
      "parentProductName": "Sauce - Strawberry Cough - 1g Live Resin Disposable",
      "postTaxPriceInPennies": 3600,
      "preTaxPriceInPennies": 3000,
      "priceInMinorUnits": 3000,
      "productDescription": "",
      "productId": "ac1c5ffe-6df2-4f59-89b9-e87771af2613",
      "productName": "Sauce - Strawberry Cough - 1g Live Resin Disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-15T20:35:47.627793Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030003C8C1000069935",
      "sku": "9935",
      "speciesName": "Sativa",
      "supplierName": "Nimble Distribution",
      "terpenes": null,
      "type": "Disposable Cartridge",
      "variantId": "61bac93f-3bd5-4614-98fb-c2d5fc9a4d8c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.55,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.55
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 121,
      "createdAt": "2024-05-14T22:02:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1394478b-0650-4e50-a285-39f5691b9dd2",
      "parentProductName": "Zuyaqui House Preroll",
      "postTaxPriceInPennies": 450,
      "preTaxPriceInPennies": 375,
      "priceInMinorUnits": 375,
      "productDescription": "",
      "productId": "eb6ea50e-d8e5-4b9a-b34a-ff8404c3628a",
      "productName": "Zuyaqui House Preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-14T22:02:03.297218Z",
      "productWeight": 0.5,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000071B1000003011",
      "sku": "PX0w4oO4V4",
      "speciesName": "Indica",
      "supplierName": "Portland Pot Shop",
      "terpenes": null,
      "type": "House Preroll",
      "variantId": "f18fdea1-072e-439a-b228-1fd70c329b13",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Single Origin",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 79.21,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 79.21
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 550,
      "createdAt": "2024-05-14T20:27:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3697f7ec-7102-4f1c-ae25-71a0822adfcf",
      "parentProductName": "Single Origin - OG Kush - 1g distillate dripper",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "df3f4121-ff64-4a53-a44c-3cf59054a23e",
      "productName": "Single Origin - OG Kush - 1g distillate dripper",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-14T20:27:33.736717Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300036012000009285",
      "sku": "9285",
      "speciesName": "Hybrid",
      "supplierName": "Craft Concentrates",
      "terpenes": null,
      "type": "Distillate Dripper",
      "variantId": "62835613-3961-44f3-a338-f8cd048367eb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Single Origin",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-05-14T20:24:49Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a2dc7387-e201-452d-895c-b2764583a588",
      "parentProductName": "The Hex Boost! Rainbow",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "d7a008a6-5697-4c54-87ea-123a175dc928",
      "productName": "The Hex Boost! Rainbow",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-14T20:24:49.621374Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 1,
      "regulatoryId": "",
      "sku": "2bI4XKiuXE",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "7c017c50-2355-4db8-aa1b-27f31f210dc2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Single Origin",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-05-14T20:24:12Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "dec54ca8-2386-4c8e-bffe-cb23f49c006d",
      "parentProductName": "The Hex Boost! - Pink",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "117a7387-85ff-46fe-8c9f-46b2e29c2648",
      "productName": "The Hex Boost! - Pink",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-14T20:24:12.594826Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 0,
      "regulatoryId": "",
      "sku": "FUaVc9Kzft",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "915c7741-1860-4270-bdd3-a167528b4f47",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Single Origin",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-05-14T20:23:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4fc8a61e-f713-4d40-81ae-b8b463f20c91",
      "parentProductName": "The Hex Boost! Blue",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "4354eb81-f39c-4799-b815-01f17b97bf96",
      "productName": "The Hex Boost! Blue",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-14T20:23:45.853006Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 1,
      "regulatoryId": "",
      "sku": "MZgT2MHyAa",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "510 Battery",
      "variantId": "e6a5af4d-5611-4635-aaef-4fcabb97fa06",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Piff Stixs",
      "cannabinoidInformation": [
        {
          "lowerRange": 74.953,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.953
        },
        {
          "lowerRange": 0.21,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.21
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2500,
      "createdAt": "2024-05-14T18:51:23Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8db930ec-f504-456b-b2c1-e7c8d229b065",
      "parentProductName": "Piff Stix - Blueberry Kush/Sour Diesel - 2g Disposable",
      "postTaxPriceInPennies": 5000,
      "preTaxPriceInPennies": 4167,
      "priceInMinorUnits": 4167,
      "productDescription": "",
      "productId": "ec28c766-cc2f-4133-abfa-773b677e36d1",
      "productName": "Piff Stix - Blueberry Kush/Sour Diesel - 2g Disposable",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-14T18:51:23.993156Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300045561000039004",
      "sku": "39004",
      "speciesName": null,
      "supplierName": "Dutch Gold Extracts",
      "terpenes": null,
      "type": "Disposable Cartridge",
      "variantId": "1b3441fe-1c6f-4943-8ded-c11757f34742",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green Dragon",
      "cannabinoidInformation": [
        {
          "lowerRange": 11.15,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 11.15
        },
        {
          "lowerRange": 7.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 7.6
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 85,
      "createdAt": "2024-05-13T19:32:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "14e70779-321c-4ff3-957c-85c413afd125",
      "parentProductName": "Green Dragon - Critical Mass 1:1 - 1g preroll",
      "postTaxPriceInPennies": 301,
      "preTaxPriceInPennies": 250,
      "priceInMinorUnits": 250,
      "productDescription": "",
      "productId": "80d879a4-ade7-4817-a204-81f45aa444e4",
      "productName": "Green Dragon - Critical Mass 1:1 - 1g preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-13T19:32:56.817656Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A40103000405B3000027565",
      "sku": "27565",
      "speciesName": null,
      "supplierName": "Green Dragon Wholesale",
      "terpenes": null,
      "type": "CBD preroll",
      "variantId": "04bb0966-e3b0-45a2-9070-0b03c1ca2b9b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Green Dragon",
      "cannabinoidInformation": [
        {
          "lowerRange": 14.25,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 14.25
        },
        {
          "lowerRange": 9.74,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 9.74
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 621,
      "createdAt": "2024-05-13T19:30:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "775c8967-5702-4025-9065-a692ec491c57",
      "parentProductName": "Green Dragon - Critical Mass 1:1 - 3.5g preroll pack",
      "postTaxPriceInPennies": 1400,
      "preTaxPriceInPennies": 1167,
      "priceInMinorUnits": 1167,
      "productDescription": "",
      "productId": "eb72ad92-7974-49f9-9998-41086b3953cd",
      "productName": "Green Dragon - Critical Mass 1:1 - 3.5g preroll pack",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-13T19:30:38.648139Z",
      "productWeight": 3.5,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A40103000405B3000027564",
      "sku": "27564",
      "speciesName": null,
      "supplierName": "Green Dragon Wholesale",
      "terpenes": null,
      "type": "CBD preroll",
      "variantId": "f546f3a1-ea2c-444a-8078-5936d83e1003",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 91.5,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 91.5
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-05-11T17:27:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "49139243-2be0-428e-8a00-510891ea265f",
      "parentProductName": "CHOMPD - Watermelon - 100mg 10pk",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "584c464d-e260-4f42-8ae1-8e39ec0687c7",
      "productName": "CHOMPD - Watermelon - 100mg 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-11T17:27:30.273955Z",
      "productWeight": 27,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030002DADD000015075",
      "sku": "15075",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "4ae5701c-4087-4bb9-a609-e9cb99bc919a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 675,
      "createdAt": "2024-05-11T17:21:06Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "633ba68c-f894-4a7c-87cf-3e7e10e77307",
      "parentProductName": "CHOMPD - Berry Blast + Rainbow - 100mg gummy",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "56840e62-a956-4296-8910-77ddf529ba34",
      "productName": "CHOMPD - Berry Blast + Rainbow - 100mg gummy",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-11T17:21:06.990439Z",
      "productWeight": 6.5,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A401030002DADD000015076",
      "sku": "15076",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "aeed49fb-0a72-4417-9bc9-474d4773f35b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 107.9,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 107.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 450,
      "createdAt": "2024-05-11T17:18:13Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3cd6c6f5-ff98-41cf-86a9-1656a8f25064",
      "parentProductName": "CHOMPD - Wildberry + Rainbow Geek Bar- 100mg Chocolate",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "f53ecfc9-6ac9-495f-a680-27b69a98ca20",
      "productName": "CHOMPD - Wildberry + Rainbow Geek Bar- 100mg Chocolate",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-11T17:18:13.888446Z",
      "productWeight": 19,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A401030002DADD000015073",
      "sku": "15073",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Chocolate",
      "variantId": "1a1cb77d-ddb6-4552-a2b7-66f61109cdc5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 360,
      "createdAt": "2024-05-10T22:25:34Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9bc76f65-8ba0-4af1-880e-150b182beb14",
      "parentProductName": "CHOMPD - Monkey Bar - 100mg chocolate bar",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "0b985c60-c9c2-4f1a-9da2-9321182d6487",
      "productName": "CHOMPD - Monkey Bar - 100mg chocolate bar",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-10T22:25:34.058996Z",
      "productWeight": 15,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030002DADD000015072",
      "sku": "5072",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Chocolate",
      "variantId": "650c72a8-8e26-4919-9696-1247bca04800",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 360,
      "createdAt": "2024-05-10T22:23:04Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c69517ef-ccef-4ce1-a03b-f804e39b7392",
      "parentProductName": "CHOMPD - Mint Chocolate Chip - 100mg chocolate bar",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "aaedba64-be39-4cb6-9901-549e26ee6066",
      "productName": "CHOMPD - Mint Chocolate Chip - 100mg chocolate bar",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-10T22:23:04.48817Z",
      "productWeight": 15,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A401030002DADD000015071",
      "sku": "5071",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Chocolate",
      "variantId": "8e897178-fbcf-4756-a9b2-4694f0e58a6b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 360,
      "createdAt": "2024-05-10T21:52:11Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "150474d3-139c-46b9-bb47-d01092e68383",
      "parentProductName": "CHOMPD - Cookies N' Cream - 100mg chocolate bar",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "3096f420-2f76-4d78-ac85-7c0d66ec21d0",
      "productName": "CHOMPD - Cookies N' Cream - 100mg chocolate bar",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-10T21:52:11.783637Z",
      "productWeight": 15,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030002DADD000015069",
      "sku": "5069",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Chocolate",
      "variantId": "f97b5574-9716-43f8-8de8-a6187984f0c8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 50,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 50
        },
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-05-09T16:55:58Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "043c4b90-a395-4dc5-b522-460ade01ee08",
      "parentProductName": "Wyld Peach 2:1 CBD/THC Gummies 100mg",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "f81207a1-4012-40a5-9f4d-d2491432c8ca",
      "productName": "Wyld Peach 2:1 CBD/THC Gummies 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-09T16:55:58.468234Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A40103000062D5000461180",
      "sku": "461180",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "322cf02c-c17e-4a23-8239-131f9afdcea5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 9.96,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 9.96
        },
        {
          "lowerRange": 195.6,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 195.6
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-05-09T16:54:36Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "74bfb121-4332-4951-b972-0de239546939",
      "parentProductName": "Wyld Strawberry 20:1 Gummies 200mg CBD",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "600194e2-1bf1-4d4c-b39e-176ec690d1c4",
      "productName": "Wyld Strawberry 20:1 Gummies 200mg CBD",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-09T16:54:36.187575Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A40103000062D5000458763",
      "sku": "458763",
      "speciesName": null,
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "CBD Edible",
      "variantId": "c34128f9-da50-434e-a580-607c60577453",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Wyld",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 875,
      "createdAt": "2024-05-09T16:50:13Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3b1ef424-03f7-4e75-ac20-695d5c179309",
      "parentProductName": "Wyld Boysenberry 1:1:1 100mg",
      "postTaxPriceInPennies": 1899,
      "preTaxPriceInPennies": 1583,
      "priceInMinorUnits": 1583,
      "productDescription": "",
      "productId": "ebb0cbc9-bcdc-4963-8569-d162cd300e0a",
      "productName": "Wyld Boysenberry 1:1:1 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-09T16:50:13.903345Z",
      "productWeight": 40,
      "purchaseCategory": "rec",
      "quantity": 7,
      "regulatoryId": "1A40103000062D5000458071",
      "sku": "458071",
      "speciesName": "Indica",
      "supplierName": "Wyld",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "3742d09f-fe3c-4210-a3cc-3c272d30a222",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Noblecraft",
      "cannabinoidInformation": [
        {
          "lowerRange": 27.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27.26
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-05-07T20:27:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f07de8e0-754b-4b5f-aeff-3205960d532a",
      "parentProductName": "Pineapple Express by Noblecraft",
      "postTaxPriceInPennies": 1501,
      "preTaxPriceInPennies": 1250,
      "priceInMinorUnits": 1250,
      "productDescription": "",
      "productId": "e4ccd256-3db4-4551-82ab-33d741c91747",
      "productName": "Pineapple Express by Noblecraft",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-07T21:08:07.545683Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 27.296,
      "regulatoryId": "1A401030004DA95000002505",
      "sku": "N2RfXQw7Jt",
      "speciesName": "Sativa",
      "supplierName": "Cascade Cannabis Solutions",
      "terpenes": null,
      "type": null,
      "variantId": "054d1106-3703-4ccb-b68a-87c347c3c9dc",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1250
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3750
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 7083
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 12917
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 23750
        }
      ]
    },
    {
      "brand": "Noblecraft",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 450,
      "createdAt": "2024-05-07T19:45:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "290f7735-ec62-467a-8961-b324231aa716",
      "parentProductName": "Third Eye Cannabis - Cherry Lime Elixir - 100mg",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "d6e7697a-2442-48dd-b495-ee7d8d02db20",
      "productName": "Third Eye Cannabis - Cherry Lime Elixir - 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-07T19:45:21.664486Z",
      "productWeight": 16,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A401030004DA95000002611",
      "sku": "2611",
      "speciesName": null,
      "supplierName": "Cascade Cannabis Solutions",
      "terpenes": null,
      "type": "soda",
      "variantId": "c2ce4594-9fed-4ed4-b763-b7a9bd2dfabd",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 71.68,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.68
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-04-11T23:46:31Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d61287df-c31c-40e5-b7a8-3509f7a21dad",
      "parentProductName": "Bob's Bammer - Don Dada - 1g Cart",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "885bae38-9bf8-4542-aefe-1e74d79c9a6b",
      "productName": "Bob's Bammer - Don Dada - 1g Cart",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-06T15:32:37.734393Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A40103000576D5000002591",
      "sku": "2591",
      "speciesName": null,
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "9209dfeb-1aa2-42ce-936c-a4a31df41325",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 264,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 264
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-04-19T16:28:47Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4c869ac3-41d1-49e1-9ca1-3bcced850e35",
      "parentProductName": "Crown B Alchemy - Super Punch - 250mg",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "e8ea9ca4-2971-46e8-905e-818d631f4d0c",
      "productName": "Crown B Alchemy - Super Punch - 250mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-06T15:30:30.474195Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300022B79000020057",
      "sku": "0057",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "Syrup",
      "variantId": "caae3657-73e7-45d5-9dee-abbe330648ce",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Liquid Loud",
      "cannabinoidInformation": [
        {
          "lowerRange": 991.38,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 991.38
        },
        {
          "lowerRange": 3.2,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 3.2
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2024-04-19T20:20:44Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6fee5c0f-3c40-45a8-8271-32441c58db4c",
      "parentProductName": "Liquid Loud - Apple Citrus - 1000mg THC Mouth Spray",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 3333,
      "priceInMinorUnits": 3333,
      "productDescription": "",
      "productId": "9ac28999-8738-42ea-ac3f-f40ea763f97e",
      "productName": "Liquid Loud - Apple Citrus - 1000mg THC Mouth Spray",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-05-05T03:42:59.894665Z",
      "productWeight": 20,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000543A9000000873",
      "sku": "00900",
      "speciesName": null,
      "supplierName": "Element 02 LLC",
      "terpenes": null,
      "type": "Mouth Spray",
      "variantId": "84ed73b0-189e-4e28-9705-72cc929758bb",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Ananda Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.11,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.11
        },
        {
          "lowerRange": 32.41,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 32.41
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-03-27T18:04:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e885f345-4161-4160-a9e0-bedf4b76edcf",
      "parentProductName": "Garlic Bread by Ananda Farms",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "cca5479a-53f1-4dc0-85bd-67c26075b5cb",
      "productName": "Garlic Bread by Ananda Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-05-01T22:23:37.252422Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 80.4,
      "regulatoryId": "1A4010300000836000009186",
      "sku": "hNm1ukEkgs",
      "speciesName": "Hybrid",
      "supplierName": "Ananda Farms",
      "terpenes": null,
      "type": null,
      "variantId": "962533b0-7008-4fcc-b74d-459b26e6a791",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "Ananda Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        },
        {
          "lowerRange": 28.25,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.25
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 264,
      "createdAt": "2024-03-13T19:26:15Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": false,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "77ef9345-7d7e-40f2-8bb0-ab719e1dc358",
      "parentProductName": "Black Mentz by Ananda Farms",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "864ea099-2105-4746-a796-d2442ec31994",
      "productName": "Black Mentz by Ananda Farms",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-04-30T21:34:49.727483Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 91.8,
      "regulatoryId": "1A4010300000836000009232",
      "sku": "HCwDn2NwBu",
      "speciesName": "Indica",
      "supplierName": "Ananda Farms",
      "terpenes": null,
      "type": null,
      "variantId": "5b5046e8-71a9-4773-b3e5-75dd07299e01",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 833
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 2667
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 4833
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 8750
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 16167
        }
      ]
    },
    {
      "brand": "Nugz",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.53,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.53
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 307,
      "createdAt": "2024-04-09T16:31:38Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": true,
      "isSoldByWeight": true,
      "isStackable": true,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "44bed4af-353c-4e92-980a-ec0f3ef83b0d",
      "parentProductName": "Unicorn Horn by NUGZ",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "dda9c6c5-0af1-4292-8576-dade35693578",
      "productName": "Unicorn Horn by NUGZ",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-04-30T01:08:52.01611Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 118.2,
      "regulatoryId": "1A401030000CCB1000006689",
      "sku": "m6xk7mOjfR",
      "speciesName": "Sativa",
      "supplierName": "NUGZ",
      "terpenes": null,
      "type": null,
      "variantId": "cede6ac5-92cd-446f-8121-c99ab26cb6f5",
      "variantName": "",
      "weightTierInformation": [
        {
          "gramAmount": 1,
          "name": "Grams",
          "pricePerUnitInMinorUnits": 1000
        },
        {
          "gramAmount": 3.5,
          "name": "Eighth",
          "pricePerUnitInMinorUnits": 3083
        },
        {
          "gramAmount": 7,
          "name": "Quarter",
          "pricePerUnitInMinorUnits": 5417
        },
        {
          "gramAmount": 14,
          "name": "Half-Ounce",
          "pricePerUnitInMinorUnits": 10000
        },
        {
          "gramAmount": 28,
          "name": "Ounce",
          "pricePerUnitInMinorUnits": 18750
        }
      ]
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 3500,
      "createdAt": "2024-04-24T21:16:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "baeee437-56b2-4a96-8214-0790c0c9ec43",
      "parentProductName": "$69 Dab Rig + Quartz Bucket",
      "postTaxPriceInPennies": 6900,
      "preTaxPriceInPennies": 6900,
      "priceInMinorUnits": 6900,
      "productDescription": "",
      "productId": "772f519d-c3f3-4e5d-9246-0293292bfeb0",
      "productName": "$69 Dab Rig + Quartz Bucket",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-24T21:16:43.521916Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 1,
      "regulatoryId": "",
      "sku": "nNbAvvAmsf",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "2e505567-1893-4bdf-8aca-e741dbd6a0fe",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-04-24T21:16:05Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "2d5ee044-8f38-4dd5-891b-b8b7d6716450",
      "parentProductName": "$25 Bing",
      "postTaxPriceInPennies": 2500,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "0c956b76-9c21-432a-97d8-c0b6fda8fd8b",
      "productName": "$25 Bing",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-24T21:16:05.515966Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 1,
      "regulatoryId": "",
      "sku": "lkU3cxUq6k",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "973291df-21fc-42e9-95ec-281334622ae5",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2024-04-20T18:25:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a7f2153d-d5a8-4e2e-8916-7c9710c04d55",
      "parentProductName": "grey pps hoody",
      "postTaxPriceInPennies": 5000,
      "preTaxPriceInPennies": 5000,
      "priceInMinorUnits": 5000,
      "productDescription": "",
      "productId": "982014c0-5d53-44c4-9fa7-20511725da29",
      "productName": "grey pps hoody",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-20T18:25:55.716793Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 8,
      "regulatoryId": "",
      "sku": "hT9CRRD1DF",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "46517491-b5a3-4df4-8387-d9835070c119",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-04-20T16:34:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "8bded197-1dba-4836-a901-09c1a79f8187",
      "parentProductName": "Portland Pot Shop Bucket Hat",
      "postTaxPriceInPennies": 2500,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "b0cfb1f0-5531-425f-b57b-6423289c2340",
      "productName": "Portland Pot Shop Bucket Hat",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-20T16:34:25.130429Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 4,
      "regulatoryId": "",
      "sku": "HSG6rg3PsZ",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "6ca6d2e5-4207-44d4-88fc-5292cbf38c89",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 839.9,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 839.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 83.99,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.99
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-04-10T20:59:43Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "50e5e97d-5946-48ef-b828-08ae420f910c",
      "parentProductName": "National Cannabis Co - Vanilla Shake - 1g Cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "7835171f-a3b7-4c8f-96e3-1da97938243e",
      "productName": "National Cannabis Co - Vanilla Shake - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-20T15:15:31.5724Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 6,
      "regulatoryId": "1A401030000D995000031637",
      "sku": "31637",
      "speciesName": null,
      "supplierName": "Sublime Solutions, LLC",
      "terpenes": null,
      "type": "Flavored Cartridge",
      "variantId": "395eb3aa-622a-4a44-8419-9fb62db9ec59",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Rip City Dabs",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 64.29,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 64.29
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-04-18T21:19:23Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a9e1f8e6-82a0-46d8-a587-f4883902feb7",
      "parentProductName": "Rip City Dabs - Wizard Wool - 1g sugar wax",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "c844a13b-8014-46b1-8d85-e874cd08dcda",
      "productName": "Rip City Dabs - Wizard Wool - 1g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-18T21:19:23.93827Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300048379000005464",
      "sku": "5464",
      "speciesName": "Indica",
      "supplierName": "Hillsboro Industries LLC",
      "terpenes": null,
      "type": "Sugar Sauce",
      "variantId": "2ebc2508-a227-4a36-88c6-0f6eb9898267",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Rip City Dabs",
      "cannabinoidInformation": [
        {
          "lowerRange": 5.888,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 5.888
        },
        {
          "lowerRange": 0.15,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.15
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-04-18T21:18:18Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9c2a0b78-675d-485c-85fb-96e6c7053ffc",
      "parentProductName": "Rip City Dabs - Last Supper - 1g",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "7efb87f7-cf58-4e65-b3d7-d035803175dd",
      "productName": "Rip City Dabs - Last Supper - 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-18T21:18:18.08124Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300048379000005463",
      "sku": "5463",
      "speciesName": "Hybrid",
      "supplierName": "Hillsboro Industries LLC",
      "terpenes": null,
      "type": "Sugar Sauce",
      "variantId": "8ab667ac-4cc6-4884-94ab-c37847dd3bad",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Rip City Dabs",
      "cannabinoidInformation": [
        {
          "lowerRange": 71.07,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 71.07
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 350,
      "createdAt": "2024-04-18T21:16:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fda0932a-6dfc-46a2-986e-17f74069ebc4",
      "parentProductName": "Rip City Dabs - Flamingo Jim - 1g sugar wax",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "e927bd22-45c0-4721-b241-0602596b503e",
      "productName": "Rip City Dabs - Flamingo Jim - 1g sugar wax",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-18T21:16:55.012716Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 12,
      "regulatoryId": "1A4010300048379000005462",
      "sku": "5462",
      "speciesName": "Sativa",
      "supplierName": "Hillsboro Industries LLC",
      "terpenes": null,
      "type": "Sugar Sauce",
      "variantId": "8951df29-d58b-4a85-9377-20e8dd8c035c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Lifted Northwest",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.11,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.11
        },
        {
          "lowerRange": 74.12,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.12
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-04-17T22:45:26Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4d135f69-66bb-4144-beb2-9f60ef482a12",
      "parentProductName": "Lifted NW - Mother Tongue - 1g Cured Resin",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "4e477651-a9a8-4b98-a7f1-483f8d11e2f6",
      "productName": "Lifted NW - Mother Tongue - 1g Cured Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-17T22:45:26.101122Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300042ACF000033370",
      "sku": "33370",
      "speciesName": "Sativa",
      "supplierName": "Lifted Northwest",
      "terpenes": null,
      "type": "cured resin",
      "variantId": "c06ea3f8-a2f8-4122-9319-de126537ea4f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Single Origin",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 775,
      "createdAt": "2024-04-16T20:38:32Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "138f6d53-24f8-45a7-a595-98e30d625cba",
      "parentProductName": "The Palm Boost! Gold",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 2000,
      "priceInMinorUnits": 2000,
      "productDescription": "",
      "productId": "72e29f44-1b16-4959-a660-07419032b17e",
      "productName": "The Palm Boost! Gold",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-16T20:38:32.025648Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 5,
      "regulatoryId": "",
      "sku": "w5sbgAc48K",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Battery",
      "variantId": "db4528bf-5ac3-4757-806e-d0ba50afbb5b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Covert Extractions",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.29,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.29
        },
        {
          "lowerRange": 64.18,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 64.18
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-04-16T20:17:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cc466653-4043-41f0-ab2e-b293a21ab71a",
      "parentProductName": "Covert Extractions - Skunk #1 - 1g cartridge",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "a1fd9626-9bbf-472b-8513-318c0e91d108",
      "productName": "Covert Extractions - Skunk #1 - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-16T20:17:52.698552Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300043685000049099",
      "sku": "49099",
      "speciesName": null,
      "supplierName": "Oregon Cannabis Collective",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "630a6411-8fbb-4019-b53b-070d3afcc4a1",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 507,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 507
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-04-16T17:01:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "9aae5345-9303-4ca9-b123-93380356b454",
      "parentProductName": "Crown B Alchemy - POG - 500mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "3d3f6df3-ec1b-4e29-9551-cb4c669bb39c",
      "productName": "Crown B Alchemy - POG - 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-16T17:01:10.591108Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300022B79000020056",
      "sku": "20056",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "Syrup",
      "variantId": "0330ce07-2b3b-4702-b56f-d5b558babc57",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crown B Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 257,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 257
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-04-16T16:57:33Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d8519fbf-a880-46fd-b4f0-780800987497",
      "parentProductName": "Crown B Alchemy - Mango - 250mg",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "23efd56e-9490-4fff-8742-3a8b834a68e5",
      "productName": "Crown B Alchemy - Mango - 250mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-16T16:57:33.00479Z",
      "productWeight": 2,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A4010300022B79000020054",
      "sku": "20054",
      "speciesName": null,
      "supplierName": "CROWN B ALCHEMY",
      "terpenes": null,
      "type": "Syrup",
      "variantId": "0df5a66f-1ceb-4102-943c-6b86ffd8351e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Calyx Crafts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 66.41,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 66.41
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 850,
      "createdAt": "2024-04-11T23:45:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3564f7a6-3f6f-4822-afa3-68ae6d77160d",
      "parentProductName": "Bob's Bammer - Gelato x Thin Mint Cookies - 1g cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "a35525d1-3f5a-4c98-9b97-4074ebe64a1a",
      "productName": "Bob's Bammer - Gelato x Thin Mint Cookies - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T23:45:08.480848Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A40103000576D5000002729",
      "sku": "2729",
      "speciesName": null,
      "supplierName": "Calyx Wholesale",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "f688a3b2-234b-4509-adbe-ae84b430db14",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 26.9,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 575,
      "createdAt": "2024-04-11T22:07:29Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c5b96e77-3526-4abf-9d5d-6debc9ac9bbe",
      "parentProductName": "Toking Blunts - Sherbet - 2.5g Blunt",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "ba8eca3b-3548-41ca-b128-13d0fc27bf8a",
      "productName": "Toking Blunts - Sherbet - 2.5g Blunt",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T22:07:29.839059Z",
      "productWeight": 2.5,
      "purchaseCategory": "rec",
      "quantity": 17,
      "regulatoryId": "1A40103000017D5000030759",
      "sku": "30759",
      "speciesName": "Indica",
      "supplierName": "Toking Farms",
      "terpenes": null,
      "type": "Blunt",
      "variantId": "182eff37-8a64-4be6-923e-4c7f6e39865a",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 28.64,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.64
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 575,
      "createdAt": "2024-04-11T22:04:53Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "5915f68a-385a-4623-bb0b-44771083ccfb",
      "parentProductName": "Toking Blunts - Stardawg Breath - 2.5g Blunt",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "46ecf02c-9966-44e2-9d46-84c6bc9e5d06",
      "productName": "Toking Blunts - Stardawg Breath - 2.5g Blunt",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T22:04:53.607294Z",
      "productWeight": 2.5,
      "purchaseCategory": "rec",
      "quantity": 22,
      "regulatoryId": "1A40103000017D5000030813",
      "sku": "30813",
      "speciesName": "Hybrid",
      "supplierName": "Toking Farms",
      "terpenes": null,
      "type": "Blunt",
      "variantId": "0b2a9b07-0f0f-4768-b46a-bb5f9a8ee52c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 25.78,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.78
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 575,
      "createdAt": "2024-04-11T22:03:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d9452ac9-2027-4e96-8642-d06b488c2a43",
      "parentProductName": "Toking Blunts - Acapulco Gold - 2.5g Blunt",
      "postTaxPriceInPennies": 1200,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "5cf0a0b8-34a1-47c4-92dd-317ecb9a7943",
      "productName": "Toking Blunts - Acapulco Gold - 2.5g Blunt",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T22:03:09.266138Z",
      "productWeight": 2.5,
      "purchaseCategory": "rec",
      "quantity": 17,
      "regulatoryId": "1A40103000017D5000030740",
      "sku": "30740",
      "speciesName": "Sativa",
      "supplierName": "Toking Farms",
      "terpenes": null,
      "type": "Blunt",
      "variantId": "987e90a5-c4a6-4952-b70b-89c48cc2ca53",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 34.32,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 34.32
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 260,
      "createdAt": "2024-04-11T22:00:51Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "de6676c5-da4d-43b6-b4d5-be93de940ac1",
      "parentProductName": "Toking Blunts - Do-Si-Dos - 1g Bloint",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "23a0a4b1-de3b-4837-8096-ce1660093530",
      "productName": "Toking Blunts - Do-Si-Dos - 1g Bloint",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T22:00:51.759783Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 27,
      "regulatoryId": "1A40103000017D5000029492",
      "sku": "29492",
      "speciesName": "Indica",
      "supplierName": "Toking Farms",
      "terpenes": [],
      "type": "Blunt",
      "variantId": "8b128d5b-0c9c-4bf8-8fe4-79c1191314d4",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 27.25,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 27.25
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 260,
      "createdAt": "2024-04-11T21:58:11Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "586b93ef-21d3-4276-906a-aabd147aa5ec",
      "parentProductName": "Toking Blunts - Ghost Train Haze - 1g Bloint",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "668f7921-3e74-4767-86d3-6fb8a1934d32",
      "productName": "Toking Blunts - Ghost Train Haze - 1g Bloint",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T21:58:11.243137Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 24,
      "regulatoryId": "1A40103000017D5000030797",
      "sku": "30797",
      "speciesName": "Sativa",
      "supplierName": "Toking Farms",
      "terpenes": [],
      "type": "Blunt",
      "variantId": "ed464386-5e85-4d2d-b39d-4e99bebb34ed",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Toking Blunts",
      "cannabinoidInformation": [
        {
          "lowerRange": 26.62,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.62
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 260,
      "createdAt": "2024-04-11T21:56:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7cf6f6e0-6214-422b-8381-08290a004de3",
      "parentProductName": "Toking Blunts - GSC - 1g Bloint",
      "postTaxPriceInPennies": 699,
      "preTaxPriceInPennies": 583,
      "priceInMinorUnits": 583,
      "productDescription": "",
      "productId": "ec8b8fc9-be3c-4e7f-bde9-a4cba9c03185",
      "productName": "Toking Blunts - GSC - 1g Bloint",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T21:56:03.391Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 22,
      "regulatoryId": "1A40103000017D5000030783",
      "sku": "30783",
      "speciesName": "Hybrid",
      "supplierName": "Toking Farms",
      "terpenes": null,
      "type": "Blunt",
      "variantId": "589b6029-6a06-45be-8e33-36c0b1c39fe9",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Beehive Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 74.58,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 74.58
        },
        {
          "lowerRange": 0.2,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.2
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1400,
      "createdAt": "2024-04-11T20:44:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4fdfe002-4c77-4802-b676-357813e5d40d",
      "parentProductName": "Beehive Extracts - Grape MTN - 1g cured resin cartridge",
      "postTaxPriceInPennies": 2800,
      "preTaxPriceInPennies": 2333,
      "priceInMinorUnits": 2333,
      "productDescription": "",
      "productId": "39e38a9e-aa43-410c-97d6-96f1be25104e",
      "productName": "Beehive Extracts - Grape MTN - 1g cured resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T20:44:20.615623Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A401030004DE19000016558",
      "sku": "16558",
      "speciesName": null,
      "supplierName": "Beehive Extracts",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "c51d2511-7a08-4e73-bcec-2c44eee2ab23",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Beehive Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 70.86,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 70.86
        },
        {
          "lowerRange": 0.2,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.2
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1400,
      "createdAt": "2024-04-11T20:43:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1ec7ce57-9bc6-40e5-af8c-b17e3e337001",
      "parentProductName": "Beehive Extracts - Project Gary - 1g cured resin cartridge",
      "postTaxPriceInPennies": 2800,
      "preTaxPriceInPennies": 2333,
      "priceInMinorUnits": 2333,
      "productDescription": "",
      "productId": "42954e86-0255-498f-99e9-39ebdc4857eb",
      "productName": "Beehive Extracts - Project Gary - 1g cured resin cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T20:43:10.404908Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030004DE19000016559",
      "sku": "16559",
      "speciesName": null,
      "supplierName": "Beehive Extracts",
      "terpenes": null,
      "type": "Cured Resin Cartridge",
      "variantId": "0431a943-aa4c-4a73-b06e-37e262e97c76",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Highsman",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 28.36,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.36
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 150,
      "createdAt": "2024-04-11T18:51:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a0af732e-635b-4724-948e-833fcf6e34ce",
      "parentProductName": "Freak Genetixs - Greasy Runtz x Pink Ranger - 1g preroll",
      "postTaxPriceInPennies": 400,
      "preTaxPriceInPennies": 333,
      "priceInMinorUnits": 333,
      "productDescription": "",
      "productId": "9f064e93-b1e8-4d2b-97bc-babea9433134",
      "productName": "Freak Genetixs - Greasy Runtz x Pink Ranger - 1g preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-11T18:51:10.437211Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 19,
      "regulatoryId": "1A4010300044CC9000001791",
      "sku": "1791",
      "speciesName": "Indica",
      "supplierName": "ASAP BUDS",
      "terpenes": null,
      "type": "Preroll",
      "variantId": "391be93c-bf7b-4e64-86c4-bd3c55b51631",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 83.43,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 83.43
        }
      ],
      "category": "Cartridges",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-04-10T21:06:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "da8fe23d-f682-41ed-9823-97226abed832",
      "parentProductName": "National Cannabis Co - Headband Haze - 1g cartridge",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "3a032be6-0fb2-472a-b9e4-b620d0aeb779",
      "productName": "National Cannabis Co - Headband Haze - 1g cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-10T21:06:10.753187Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A401030000D995000030346",
      "sku": "30346",
      "speciesName": null,
      "supplierName": "Sublime Solutions, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "3c4aa954-6f0a-470a-845e-50e4c4b50ef6",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Garden First",
      "cannabinoidInformation": [
        {
          "lowerRange": 433.16,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 433.16
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Infuseds",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-04-10T18:31:00Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2024-07-10",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3ffde89b-23d5-4566-9682-da5e04b67529",
      "parentProductName": "Garden First - Golden Goat - 1g infused preroll",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "4c204b94-0210-48f4-8f43-822a54ea0908",
      "productName": "Garden First - Golden Goat - 1g infused preroll",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-10T18:31:00.23359Z",
      "productWeight": null,
      "purchaseCategory": "rec",
      "quantity": 5,
      "regulatoryId": "1A4010300016ECD000033437",
      "sku": "33437",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Infused Preroll",
      "variantId": "8e17c219-d209-4d95-9cc5-eccd08bf1223",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Garden First",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 25.42,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 25.42
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1250,
      "createdAt": "2024-04-10T18:27:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "32e9b4bc-b461-4cae-9d71-7a8fb0d1aab0",
      "parentProductName": "Puff Pack - 818 Headband - 0.65g 10pk",
      "postTaxPriceInPennies": 2499,
      "preTaxPriceInPennies": 2083,
      "priceInMinorUnits": 2083,
      "productDescription": "",
      "productId": "baf9d475-f3e1-473a-8ae0-d0d86e7eedb6",
      "productName": "Puff Pack - 818 Headband - 0.65g 10pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-10T18:27:03.990316Z",
      "productWeight": 6.5,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300016ECD000036048",
      "sku": "36048",
      "speciesName": "Sativa",
      "supplierName": "Hunter Distributing",
      "terpenes": null,
      "type": "Preroll Pack",
      "variantId": "daafe9a4-192c-4eeb-b6bd-142593b6fae1",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "After Glow",
      "cannabinoidInformation": [
        {
          "lowerRange": 880,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 880
        },
        {
          "lowerRange": 0.53,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.53
        },
        {
          "lowerRange": 88,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 88
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-04-09T18:04:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d065a049-5e3e-49b0-a826-18b0b84ceceb",
      "parentProductName": "After Glow Blueberry Glaze Cartridge 1g",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "25cefb8a-9c23-4f55-ae70-c30f7fc73caa",
      "productName": "After Glow Blueberry Glaze Cartridge 1g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-09T18:04:25.581958Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A4010300038402000017136",
      "sku": "17136",
      "speciesName": null,
      "supplierName": "Mana Distribution",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "efe80d86-f1a7-4244-a9f9-99ec219a4b84",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "CHOMPD",
      "cannabinoidInformation": [
        {
          "lowerRange": 106.9,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 106.9
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 450,
      "createdAt": "2024-04-06T02:59:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d31e64a4-fa25-48f5-8ff0-ddd7ad49cb70",
      "parentProductName": "CHOMPD - Dreamsicle + Rainbow Geek Bar- 100mg Chocolate",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "a40f963c-acdd-4e00-9fc6-930231ecf59b",
      "productName": "CHOMPD - Dreamsicle + Rainbow Geek Bar- 100mg Chocolate",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-04-06T02:59:08.486466Z",
      "productWeight": 19,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A401030002DADD000014549",
      "sku": "4549",
      "speciesName": null,
      "supplierName": "Green Box MFG LLC",
      "terpenes": null,
      "type": "Chocolate",
      "variantId": "3efbc701-1a30-4639-a2bd-8f3be71fda8b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Phyre",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 21.26,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.26
        },
        {
          "lowerRange": "0,21.26",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,21.26"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1967,
      "createdAt": "2024-04-02T20:24:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "de150bff-36a9-4763-b8b1-21ab71b4365e",
      "parentProductName": "Presidential Sweet by Phyre",
      "postTaxPriceInPennies": 2400,
      "preTaxPriceInPennies": 2000,
      "priceInMinorUnits": 2000,
      "productDescription": "",
      "productId": "f3173eee-d49a-423b-867a-8457f7e0b7b8",
      "productName": "Presidential Sweet by Phyre - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-04-02T20:24:55.666393Z",
      "productWeight": 6.39,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000BD78000017464",
      "sku": "TmyIcUG9m2",
      "speciesName": "50/50",
      "supplierName": "Phyre",
      "terpenes": null,
      "type": "Flower",
      "variantId": "7eaf83ad-c5a3-49c9-9adb-bb20723d082f",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Sugarbud",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 26.21,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.21
        },
        {
          "lowerRange": "0,26.21",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,26.21"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2865,
      "createdAt": "2024-04-02T20:05:23Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3b504fcd-3f53-4775-99d1-fef18b0ea3a2",
      "parentProductName": "Grape Octane OG by Sugarbud",
      "postTaxPriceInPennies": 3501,
      "preTaxPriceInPennies": 2917,
      "priceInMinorUnits": 2917,
      "productDescription": "",
      "productId": "64445c1e-5c2c-414a-af0b-48161f9a15bb",
      "productName": "Grape Octane OG by Sugarbud - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-04-02T20:05:23.054995Z",
      "productWeight": 7.44,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030000E7A8000025153",
      "sku": "DlsH1IJCOQ",
      "speciesName": "Indica-dom",
      "supplierName": "Sugarbud",
      "terpenes": null,
      "type": "Flower",
      "variantId": "0764eb93-a6a2-48f0-9eb6-dded2a4248e1",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Earl Baker",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.09,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.09
        },
        {
          "lowerRange": 26.7,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 26.7
        },
        {
          "lowerRange": "0,26.7",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,26.7"
        },
        {
          "lowerRange": "0,0.09",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0.09"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2401,
      "createdAt": "2024-04-02T20:00:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "b5bd68a4-48a5-4b6a-875f-9ec7512ee84c",
      "parentProductName": "Ice Cream Man by Earl Baker",
      "postTaxPriceInPennies": 2901,
      "preTaxPriceInPennies": 2417,
      "priceInMinorUnits": 2417,
      "productDescription": "",
      "productId": "1959e1ca-a7ec-4649-8f1e-ca0f2901c4db",
      "productName": "Ice Cream Man by Earl Baker - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-04-02T20:00:30.329184Z",
      "productWeight": 6.42,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A4010300037CF9000002122",
      "sku": "SeBtD0K7yY",
      "speciesName": "Indica-dom",
      "supplierName": "Hunter Distro",
      "terpenes": null,
      "type": "Flower",
      "variantId": "9d46eaab-b126-433b-939f-156b2465d8ca",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Willamette Valley Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.15,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.15
        },
        {
          "lowerRange": 64.05,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 64.05
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1600,
      "createdAt": "2024-03-26T21:23:01Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f60d99fc-bd99-43b1-887d-c54a50a0bdf0",
      "parentProductName": "Willamette Valley Alchemy - Sherb Bait - 1g Live Resin",
      "postTaxPriceInPennies": 3200,
      "preTaxPriceInPennies": 2667,
      "priceInMinorUnits": 2667,
      "productDescription": "",
      "productId": "57d2d17d-3bf5-4298-8fe6-5078d079ae7a",
      "productName": "Willamette Valley Alchemy - Sherb Bait - 1g Live Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-26T21:23:01.048958Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A40103000175D5000159474",
      "sku": "159474",
      "speciesName": "Indica",
      "supplierName": "Willamette Valley Alchemy Wholesale",
      "terpenes": null,
      "type": null,
      "variantId": "f37418ae-4ff9-4cc9-8ceb-258938ecd095",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Willamette Valley Alchemy",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.16,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.16
        },
        {
          "lowerRange": 67.24,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 67.24
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1600,
      "createdAt": "2024-03-26T21:21:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "81b81f8a-1dd0-497d-a4c7-fb63fa20ddc5",
      "parentProductName": "Willamette Valley Alchemy - Tie Dye - 1g Live Resin",
      "postTaxPriceInPennies": 3200,
      "preTaxPriceInPennies": 2667,
      "priceInMinorUnits": 2667,
      "productDescription": "",
      "productId": "ab8dc577-437b-4106-986d-b97df4e5b056",
      "productName": "Willamette Valley Alchemy - Tie Dye - 1g Live Resin",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-26T21:21:52.375077Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000175D5000159475",
      "sku": "159475",
      "speciesName": "Hybrid",
      "supplierName": "Willamette Valley Alchemy Wholesale",
      "terpenes": null,
      "type": "Live Resin",
      "variantId": "6f92def6-6893-496a-87d8-e55f54991817",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Phyre",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 21.51,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 21.51
        },
        {
          "lowerRange": "0,21.51",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,21.51"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2457,
      "createdAt": "2024-03-19T18:42:21Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "62945fea-b30f-4b9a-8019-bb68fe445845",
      "parentProductName": "PAC By Phyre",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "4d1fda29-a62e-4997-a904-6257d094026d",
      "productName": "PAC By Phyre - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-03-19T18:42:21.412721Z",
      "productWeight": 9.31,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000BD78000017202",
      "sku": "33Sbz3vavw",
      "speciesName": "Indica-dom",
      "supplierName": "Phyre",
      "terpenes": null,
      "type": "Flower",
      "variantId": "b5666d27-8757-459c-9d0d-595e00bbee77",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Highland Provisions",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        },
        {
          "lowerRange": 22.5,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 22.5
        },
        {
          "lowerRange": "0,22.5",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,22.5"
        },
        {
          "lowerRange": "0,0.08",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0.08"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2258,
      "createdAt": "2024-03-19T18:19:26Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "fe67662a-37fd-46a5-926b-899e01e4b809",
      "parentProductName": "Sour Garlic Cookies by Highland Provisions",
      "postTaxPriceInPennies": 2800,
      "preTaxPriceInPennies": 2333,
      "priceInMinorUnits": 2333,
      "productDescription": "",
      "productId": "fac88013-10f7-4867-984b-14fe53ef30cc",
      "productName": "Sour Garlic Cookies by Highland Provisions - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-03-19T18:19:26.43517Z",
      "productWeight": 8.58,
      "purchaseCategory": "rec",
      "quantity": 0,
      "regulatoryId": "1A40103000211B3000038955",
      "sku": "kb2toTeDS8",
      "speciesName": "50/50",
      "supplierName": "Highland Provisions",
      "terpenes": null,
      "type": "Flower",
      "variantId": "27d11457-7268-43e3-a9fe-b0ed48e2f233",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "National Cannabis Co",
      "cannabinoidInformation": [
        {
          "lowerRange": 883.4,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 883.4
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 88.34,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 88.34
        }
      ],
      "category": "Inhalable Cannabinoid",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2024-03-15T03:01:50Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d824774e-ee3d-46c9-8faf-0bd6fa18a706",
      "parentProductName": "National Cannabis Co - Watermelon Wonder - 1g Cartridge",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 1667,
      "priceInMinorUnits": 1667,
      "productDescription": "",
      "productId": "6454affd-e1ca-4316-a096-f824ed8de7e9",
      "productName": "National Cannabis Co - Watermelon Wonder - 1g Cartridge",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-15T03:01:50.76615Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000D995000029403",
      "sku": "9403",
      "speciesName": "Hybrid",
      "supplierName": "Sublime Solutions, LLC",
      "terpenes": null,
      "type": "Cartridge",
      "variantId": "eea7ba60-84e0-4a06-a6ca-4ccb7f2c4dc8",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sun God Medicinals",
      "cannabinoidInformation": [
        {
          "lowerRange": 510.11,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 510.11
        },
        {
          "lowerRange": 521.39,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 521.39
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2024-03-11T20:07:03Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e1049c57-608c-4638-8620-8d345894995a",
      "parentProductName": "Ra Cannabis - 1:1 Tincture - 500mg",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 3333,
      "priceInMinorUnits": 3333,
      "productDescription": "",
      "productId": "bd13ac45-9fb9-4edf-8aa6-075da06fb9c8",
      "productName": "Ra Cannabis - 1:1 Tincture - 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-11T20:07:03.33049Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A4010300004FB2000030677",
      "sku": "30677",
      "speciesName": null,
      "supplierName": "Sun God Medicinals",
      "terpenes": null,
      "type": "CBD Tincture",
      "variantId": "a2c64185-5e95-47d3-9b27-948ec51dff03",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sun God Medicinals",
      "cannabinoidInformation": [
        {
          "lowerRange": 500,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 500
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 0,
          "name": "cbn",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-03-11T20:03:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "530879cb-33b5-46e1-b35b-cd9812012bd8",
      "parentProductName": "Sun God THC Heka Tincture 500mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "58f2bf25-11c7-4516-90df-e73f2464c761",
      "productName": "Sun God THC Heka Tincture 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-11T20:03:22.123969Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 9,
      "regulatoryId": "1A4010300004FB2000030676",
      "sku": "30676",
      "speciesName": null,
      "supplierName": "Sun God Medicinals",
      "terpenes": null,
      "type": "Tincture",
      "variantId": "8139dfa9-2e92-4b29-8645-fcf45c1127ad",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Sun God Medicinals",
      "cannabinoidInformation": [
        {
          "lowerRange": 500,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 500
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 0,
          "name": "cbn",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-03-11T20:01:29Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cc0d8281-f513-49cc-bb9e-af406d8459f9",
      "parentProductName": "Sun God THC AJA Tincture 500mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "b37b35f2-3cf0-47d0-a77e-09d9832b976e",
      "productName": "Sun God THC AJA Tincture 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-11T20:01:29.219712Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A4010300004FB2000030675",
      "sku": "30675",
      "speciesName": null,
      "supplierName": "Sun God Medicinals",
      "terpenes": null,
      "type": "Tincture",
      "variantId": "b741c245-6a0d-488b-b2c2-24094d910e0f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Buddies",
      "cannabinoidInformation": [
        {
          "lowerRange": 476.89,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 476.89
        },
        {
          "lowerRange": 498.9,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 498.9
        }
      ],
      "category": "Tinctures",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1500,
      "createdAt": "2024-03-07T21:16:27Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "742fae74-5605-4dbe-9d78-2cfe4000f8aa",
      "parentProductName": "Siskiyou Sungrown - 1:1 Tincture - 500mg",
      "postTaxPriceInPennies": 3000,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "b51fc9c4-d339-47bc-888e-80a96bc5f6de",
      "productName": "Siskiyou Sungrown - 1:1 Tincture - 500mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-07T21:16:27.646166Z",
      "productWeight": 32,
      "purchaseCategory": "rec",
      "quantity": 8,
      "regulatoryId": "1A401030000A5A2000183979",
      "sku": "183979",
      "speciesName": null,
      "supplierName": "Dreamfield Transfer Services",
      "terpenes": null,
      "type": "CBD Tincture",
      "variantId": "ac2bd73f-0fd7-475e-80ad-e4253901680c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Piff Stixs",
      "cannabinoidInformation": [
        {
          "lowerRange": 997.5,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 997.5
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        },
        {
          "lowerRange": 1,
          "name": "of concentrate",
          "unitOfMeasure": "grams",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 1
        },
        {
          "lowerRange": 2,
          "name": "of flower",
          "unitOfMeasure": "grams",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 2
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 33.25,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 33.25
        }
      ],
      "category": "Infused Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2500,
      "createdAt": "2024-03-07T21:07:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f069368d-9178-4893-9c7f-6d1df87e3eeb",
      "parentProductName": "Snow Budz - Lavender Cookies - 3g",
      "postTaxPriceInPennies": 5000,
      "preTaxPriceInPennies": 4167,
      "priceInMinorUnits": 4167,
      "productDescription": "",
      "productId": "e7bfd0ce-7114-4667-8ecf-00fa362c3bac",
      "productName": "Snow Budz - Lavender Cookies - 3g",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-07T21:07:52.037572Z",
      "productWeight": 3,
      "purchaseCategory": "rec",
      "quantity": 4,
      "regulatoryId": "1A4010300045561000036185",
      "sku": "36185",
      "speciesName": "Indica",
      "supplierName": "Dutch Gold Extracts",
      "terpenes": null,
      "type": "Infused Flower",
      "variantId": "1c49ac32-bc27-4b44-9ab4-c48d99a72b71",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Crescendo Concentrates",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.95,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.95
        },
        {
          "lowerRange": 47.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 47.6
        }
      ],
      "category": "Concentrates",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 800,
      "createdAt": "2024-03-07T00:14:02Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "1c0552c5-a626-46da-b0f8-aa79efc9eb36",
      "parentProductName": "Crescendo Concentrates - Hood Candyz - 1g Bubble Hash",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "51de2c8e-8b82-4dd5-900c-6ab71f53399d",
      "productName": "Crescendo Concentrates - Hood Candyz - 1g Bubble Hash",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-03-07T00:14:02.20636Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300051AA5000000461",
      "sku": "000461",
      "speciesName": "Indica",
      "supplierName": "Crescendo Concentrates",
      "terpenes": null,
      "type": "Bubble Hash",
      "variantId": "2089d2ed-a405-4e8c-b88c-0b27c7148123",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Phyre",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 33.34,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 33.34
        },
        {
          "lowerRange": "0,33.34",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,33.34"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2314,
      "createdAt": "2024-02-21T20:22:22Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "0faab82c-82b2-40b3-9b6a-8b82b84e1270",
      "parentProductName": "Pineapple Kiwi by Phyre",
      "postTaxPriceInPennies": 2800,
      "preTaxPriceInPennies": 2333,
      "priceInMinorUnits": 2333,
      "productDescription": "",
      "productId": "95bcb0f9-dd74-47fd-8970-c2a6886ab4e7",
      "productName": "Pineapple Kiwi by Phyre - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-02-21T20:22:22.034816Z",
      "productWeight": 7.51,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000BD78000017378",
      "sku": "bYhojFcM0x",
      "speciesName": "50/50",
      "supplierName": "Phyre",
      "terpenes": null,
      "type": "Flower",
      "variantId": "89e045ec-68f0-4eb5-82eb-7b35f7c9356b",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Virgin Cannabis",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 28.47,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.47
        },
        {
          "lowerRange": "0,28.47",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,28.47"
        },
        {
          "lowerRange": "0,0.12",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0.12"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2375,
      "createdAt": "2024-02-21T19:08:56Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "e18a871d-78e0-44db-99aa-9f4ec4128de5",
      "parentProductName": "Pure Michigan by Virgin",
      "postTaxPriceInPennies": 2901,
      "preTaxPriceInPennies": 2417,
      "priceInMinorUnits": 2417,
      "productDescription": "",
      "productId": "39a04667-40e4-4902-bbda-7b23a3b713aa",
      "productName": "Pure Michigan by Virgin - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-02-21T19:08:56.573032Z",
      "productWeight": 9.06,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030002A4F5000008935",
      "sku": "MxbGcnzJze",
      "speciesName": "Indica-dom",
      "supplierName": "Virgin Cannabis",
      "terpenes": null,
      "type": "Flower",
      "variantId": "1edc29d4-d455-4b21-a49a-82fa1b9a23c7",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Mana Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 62.6,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 62.6
        }
      ],
      "category": "Extracts",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 450,
      "createdAt": "2024-02-06T21:38:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "638e6ae2-5a91-44b8-b7cd-9cd5988ce5c0",
      "parentProductName": "Mana Extracts - Rogue Valley MAC - 1g batter",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "744d8635-869e-41a4-93a0-b92a44fd80d8",
      "productName": "Mana Extracts - Rogue Valley MAC - 1g batter",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-02-06T21:38:55.481966Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A4010300038402000005330",
      "sku": "5330",
      "speciesName": "Hybrid",
      "supplierName": "Mana Distribution",
      "terpenes": null,
      "type": "Batter",
      "variantId": "ec93dc6a-a671-481e-a73b-1fde0253eb2e",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 0
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 420,
      "createdAt": "2024-02-03T22:00:13Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7a7d4755-a5aa-4e74-a392-11e0eccf60c8",
      "parentProductName": "Mule Extracts Raspberry Lemonade Kicker CBG 1:1 100mg",
      "postTaxPriceInPennies": 901,
      "preTaxPriceInPennies": 750,
      "priceInMinorUnits": 750,
      "productDescription": "",
      "productId": "1e11b1c5-ac54-4df7-aada-cb4301ed30f6",
      "productName": "Mule Extracts Raspberry Lemonade Kicker CBG 1:1 100mg",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-02-03T22:00:13.228699Z",
      "productWeight": 24.5,
      "purchaseCategory": "rec",
      "quantity": 11,
      "regulatoryId": "1A401030001CC8D000219960",
      "sku": "9960",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "Gummy",
      "variantId": "5e95d6e1-472e-4e47-b0d3-3fe211064863",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Dr. Jolly's",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 23.76,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 23.76
        },
        {
          "lowerRange": "0,23.76",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,23.76"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2787,
      "createdAt": "2024-01-30T20:35:54Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "f0349199-57d4-4f47-a4a5-6128f60a9307",
      "parentProductName": "Platinum Huckleberry Cookies #9 by Dr. Jolly's",
      "postTaxPriceInPennies": 3400,
      "preTaxPriceInPennies": 2833,
      "priceInMinorUnits": 2833,
      "productDescription": "",
      "productId": "944e4ee8-a819-4273-af09-82af8d9eafc4",
      "productName": "Platinum Huckleberry Cookies #9 by Dr. Jolly's - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-01-30T20:35:54.431065Z",
      "productWeight": 9.05,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A401030000B1BE000012926",
      "sku": "12926",
      "speciesName": "Indica-dom",
      "supplierName": "Dr. Jolly's",
      "terpenes": [],
      "type": "Flower",
      "variantId": "8d32df1c-b1ae-4cda-b3f0-e0f5fde8ab2f",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "PDX Organics",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.09,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.09
        },
        {
          "lowerRange": 32.33,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 32.33
        },
        {
          "lowerRange": "0,32.33",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,32.33"
        },
        {
          "lowerRange": "0,0.09",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0.09"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2668,
      "createdAt": "2024-01-30T20:21:30Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c989b3a2-bd46-4117-a4af-a4f3d4581b45",
      "parentProductName": "Crepes #4 by PDX Organics",
      "postTaxPriceInPennies": 3301,
      "preTaxPriceInPennies": 2750,
      "priceInMinorUnits": 2750,
      "productDescription": "Project 4516 x Pancakes",
      "productId": "15d61417-bc96-4008-a98c-7c121c8ff9ae",
      "productName": "Crepes #4 by PDX Organics - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2024-01-30T20:21:30.890587Z",
      "productWeight": 6.74,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030000F80D000009415",
      "sku": "9415",
      "speciesName": "Indica-dom",
      "supplierName": "PDX Organics",
      "terpenes": [],
      "type": "Flower",
      "variantId": "2148ea53-26cd-45d2-ae8f-a7ccadb6ba7c",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 750,
      "createdAt": "2024-01-24T22:48:35Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "cecd471e-15a3-409d-83ce-500fa0e17a07",
      "parentProductName": "Maryjane's House of Glass Assorted $15 Pipes",
      "postTaxPriceInPennies": 1500,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "40a0cb2f-dd29-4c15-ac17-0f3fb0c59773",
      "productName": "Maryjane's House of Glass Assorted $15 Pipes",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-01-24T22:48:35.904702Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 51,
      "regulatoryId": "",
      "sku": "hpuomcdf1a",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "57a3ea8e-24ca-4271-bef0-e46116452974",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 500,
      "createdAt": "2024-01-24T22:47:45Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "389b6b5e-5cb2-41ed-a9f1-96d7328b4a5e",
      "parentProductName": "Maryjane's House of Glass Assorted $10 Pipes",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 1000,
      "priceInMinorUnits": 1000,
      "productDescription": "",
      "productId": "7c0d309f-9adf-4452-ba51-8986ea7b70a1",
      "productName": "Maryjane's House of Glass Assorted $10 Pipes",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2024-01-24T22:47:45.296822Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 71,
      "regulatoryId": "",
      "sku": "cXgocugXEM",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "c2cfa597-c5d5-42d0-93e3-2bdae045859c",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Noble Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0.08,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0.08
        },
        {
          "lowerRange": 32.34,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 32.34
        }
      ],
      "category": "Pre-Roll",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 416,
      "createdAt": "2023-12-27T23:48:52Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "119cc080-1e74-42fc-894d-eae4deae78b0",
      "parentProductName": "Noble Farms - OMMP Blueberry - 0.5g 2pk",
      "postTaxPriceInPennies": 1000,
      "preTaxPriceInPennies": 833,
      "priceInMinorUnits": 833,
      "productDescription": "",
      "productId": "7e92b925-3484-49ab-81d1-96a43626903a",
      "productName": "Noble Farms - OMMP Blueberry - 0.5g 2pk",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-12-27T23:48:52.152337Z",
      "productWeight": 1,
      "purchaseCategory": "rec",
      "quantity": 10,
      "regulatoryId": "1A4010300001EE5000008490",
      "sku": "8490",
      "speciesName": "Indica",
      "supplierName": "Noble Farms",
      "terpenes": [],
      "type": "Preroll Pack",
      "variantId": "61d97142-40cf-48e3-8a78-e3334b0b00bc",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2023-12-14T18:53:27Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c3425eb1-bdca-4f27-a7a7-01c21ce1a926",
      "parentProductName": "Portland Potshop Gray Crew XXLarge",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 4000,
      "priceInMinorUnits": 4000,
      "productDescription": "",
      "productId": "7852d688-40d6-407a-a382-a32b69cfc6d7",
      "productName": "Portland Potshop Gray Crew XXLarge",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-12-14T18:53:27.654823Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 2,
      "regulatoryId": "",
      "sku": "XXlarge",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "6d2db373-045d-4098-bae4-e890884f3648",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2023-12-14T18:52:10Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "4bc2dd43-369b-4caa-af79-4c9e8c1d6766",
      "parentProductName": "Portland Potshop Gray Crew LS - X-Large",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 4000,
      "priceInMinorUnits": 4000,
      "productDescription": "",
      "productId": "b23cc4e6-7b45-48a5-b431-b3379f576169",
      "productName": "Portland Potshop Gray Crew LS - X-Large",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-12-14T18:52:10.435453Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 12,
      "regulatoryId": "",
      "sku": "Xlarge",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "714bac3d-2753-4389-abb4-5d7512d8370d",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2023-12-14T18:50:55Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "c46c96cd-60fc-42b5-aebf-cb9017165b26",
      "parentProductName": "Portland Potshop Gray Crew LS -  Large",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 4000,
      "priceInMinorUnits": 4000,
      "productDescription": "",
      "productId": "2be0f381-8e16-422c-b612-f84ae2fcee8c",
      "productName": "Portland Potshop Gray Crew LS -  Large",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-12-14T18:50:55.980383Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 18,
      "regulatoryId": "",
      "sku": "Large",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "5d67ca4d-7c82-47d5-adfc-c77e512e5297",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2023-12-14T18:45:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3623565c-2362-4728-ae62-ab55b1f4f0ae",
      "parentProductName": "Portland Potshop Gray Crew LS -  Medium",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 4000,
      "priceInMinorUnits": 4000,
      "productDescription": "",
      "productId": "3560519c-8093-4a1a-a5a0-c07a348df891",
      "productName": "Portland Potshop Gray Crew LS -  Medium",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-12-14T18:45:09.994688Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 10,
      "regulatoryId": "",
      "sku": "Medium ",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "dddc0f40-31b6-454f-bfd5-f3d8da5d9249",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Apparel",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2000,
      "createdAt": "2023-12-14T18:39:31Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "d86eda03-52bb-4f09-a2cc-1fda7186e5c2",
      "parentProductName": "Portland Potshop Gray Crew LS - Small",
      "postTaxPriceInPennies": 4000,
      "preTaxPriceInPennies": 4000,
      "priceInMinorUnits": 4000,
      "productDescription": "",
      "productId": "d998d883-e869-4724-b8dc-0309129ddbb7",
      "productName": "Portland Potshop Gray Crew LS - Small",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-12-14T18:39:31.760518Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 5,
      "regulatoryId": "",
      "sku": "Small",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": null,
      "variantId": "e7e1118c-07a3-47d3-84c4-13dce9a3553f",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Derby Farms",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 28.13,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 28.13
        },
        {
          "lowerRange": "0,28.13",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,28.13"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2194,
      "createdAt": "2023-12-08T00:39:50Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "75f491d9-9c43-4ebb-8bba-63a2096e7a18",
      "parentProductName": "Deadhead OG by Derby Fams",
      "postTaxPriceInPennies": 2701,
      "preTaxPriceInPennies": 2250,
      "priceInMinorUnits": 2250,
      "productDescription": "Deadhead OG, also known as \"Dead Head,\" \"Deadhead OG Kush,\" and \"Deadhead,\" is an indica-dominant hybrid marijuana strain made by crossing Chemdog 91 with SFV OG Kush. Deadhead OG, is beloved by experienced cannabis consumers for its high potency and reliability to produce a long-lasting body high. The effects are cerebral, physically stimulating and mentally relaxing. Those who are new to cannabis or have a low THC tolerance should use caution with DeadHead OG. This strain pairs best with lazy activities, like watching TV or playing video games. Consumers say Deadhead OG tastes hearty, with notes of pine and earth.",
      "productId": "3f606921-25a0-44d0-b1e9-fc75e20bf14e",
      "productName": "Deadhead OG by Derby Fams - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2023-12-08T00:39:50.294486Z",
      "productWeight": 6.23,
      "purchaseCategory": "rec",
      "quantity": 3,
      "regulatoryId": "1A401030003A086000003449",
      "sku": "il4tKPRknT",
      "speciesName": "Indica",
      "supplierName": "Derby Farms",
      "terpenes": [],
      "type": "Flower",
      "variantId": "a1a1bee3-eab8-4f18-b81e-8a52700ce226",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Oregon Roots",
      "cannabinoidInformation": [
        {
          "lowerRange": 0,
          "name": "cbd",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 0
        },
        {
          "lowerRange": 29.4,
          "name": "thc",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": 29.4
        },
        {
          "lowerRange": "0,29.4",
          "name": "thc range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,29.4"
        },
        {
          "lowerRange": "0,0",
          "name": "cbd range",
          "unitOfMeasure": "%",
          "unitOfMeasureToGramsMultiplier": null,
          "upperRange": "0,0"
        }
      ],
      "category": "Flower",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 2366,
      "createdAt": "2023-11-22T22:39:09Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "grams",
      "inventoryUnitOfMeasureToGramsMultiplier": 1,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "877b988d-66cc-4fe2-8d38-d2ccc8448486",
      "parentProductName": "MO' Bama by Oregon Roots",
      "postTaxPriceInPennies": 2901,
      "preTaxPriceInPennies": 2417,
      "priceInMinorUnits": 2417,
      "productDescription": "",
      "productId": "a87af3de-f51c-44cb-a5ff-b7ba55b3db37",
      "productName": "MO' Bama by Oregon Roots - fluff",
      "productPictureURL": null,
      "productUnitOfMeasure": "grams",
      "productUnitOfMeasureToGramsMultiplier": 1,
      "productUpdatedAt": "2023-11-22T22:39:09.324491Z",
      "productWeight": 7.17,
      "purchaseCategory": "rec",
      "quantity": 2,
      "regulatoryId": "1A40103000343F2000005768",
      "sku": "i1PEKGjokf",
      "speciesName": "50/50",
      "supplierName": "Oregon Roots",
      "terpenes": [],
      "type": "Flower",
      "variantId": "1284903a-519d-461f-a7b0-e26e323cffb9",
      "variantName": "fluff",
      "weightTierInformation": null
    },
    {
      "brand": "Mule Extracts",
      "cannabinoidInformation": [
        {
          "lowerRange": 100,
          "name": "thc",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        },
        {
          "lowerRange": 100,
          "name": "cbd",
          "unitOfMeasure": "mg",
          "unitOfMeasureToGramsMultiplier": "1000",
          "upperRange": 100
        }
      ],
      "category": "Edibles (Solid)",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 833,
      "createdAt": "2023-06-09T22:29:25Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": "2023-09-09",
      "inventoryUnitOfMeasure": "each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "329cc9c7-39c7-46e1-92d4-dbc4193f6514",
      "parentProductName": "Mule - Mini Kickers - Strawberry Lemonade 1:1:1:1",
      "postTaxPriceInPennies": 1800,
      "preTaxPriceInPennies": 1500,
      "priceInMinorUnits": 1500,
      "productDescription": "",
      "productId": "47ee6c3b-af0b-41a1-8e18-6daae09397f3",
      "productName": "Mule - Mini Kickers - Strawberry Lemonade 1:1:1:1",
      "productPictureURL": null,
      "productUnitOfMeasure": "each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-10-26T22:14:08.693336Z",
      "productWeight": 24.5,
      "purchaseCategory": "rec",
      "quantity": 1,
      "regulatoryId": "1A401030001CC8D000178701",
      "sku": "fz7232GXQ5",
      "speciesName": null,
      "supplierName": "Mule Extracts",
      "terpenes": null,
      "type": "CBD Edibles",
      "variantId": "3174cf41-0909-4031-bf46-e64f8652366b",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1,
      "createdAt": "2023-08-24T23:15:29Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "6feb6250-bae5-46b4-b7b2-11e10c1dbf19",
      "parentProductName": "Portland Pot Shop Hat",
      "postTaxPriceInPennies": 2500,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "571b1fc4-225e-4a07-81c4-0cd3727f0cc1",
      "productName": "Portland Pot Shop Hat",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-10-26T22:14:08.693336Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 5,
      "regulatoryId": "",
      "sku": "vgb3eYeCPc",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Swag",
      "variantId": "e231b34b-f835-44dd-af28-a853a8144191",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 250,
      "createdAt": "2022-08-08T17:45:08Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "3fa61de3-b48b-4821-97a7-9ce88f31d9a2",
      "parentProductName": "Maryjane's House of Glass $5 Dab Straw",
      "postTaxPriceInPennies": 500,
      "preTaxPriceInPennies": 500,
      "priceInMinorUnits": 500,
      "productDescription": "",
      "productId": "6a0cb208-476b-468b-9d9d-17d4c9469662",
      "productName": "Maryjane's House of Glass $5 Dab Straw",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-10-26T22:14:08.693336Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 3,
      "regulatoryId": "",
      "sku": "XQbGumZ7Pz",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "3bda6739-558b-4152-9cf9-23fa875f1dd9",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Portland Pot Shop",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1,
      "createdAt": "2023-03-19T22:39:20Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "7383dcea-948d-44f8-9528-1edf5b9260fd",
      "parentProductName": "Portland Pot Shop Beanie",
      "postTaxPriceInPennies": 2500,
      "preTaxPriceInPennies": 2500,
      "priceInMinorUnits": 2500,
      "productDescription": "",
      "productId": "da678aba-d42c-4646-9c73-12d0bcfe1b90",
      "productName": "Portland Pot Shop Beanie",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-10-26T22:14:08.693336Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 4,
      "regulatoryId": "",
      "sku": "AjmJnrhKii",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Swag",
      "variantId": "12dd77c0-139f-41a8-a854-543aa7a06459",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2022-08-08T17:44:39Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "36bf27b9-e605-4d95-aca8-426e7d03c3c6",
      "parentProductName": "Maryjane's House of Glass $20 Basket Pipe",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 2000,
      "priceInMinorUnits": 2000,
      "productDescription": "",
      "productId": "d16b92fb-6ff8-4a95-9cb0-3e256d097ce8",
      "productName": "Maryjane's House of Glass $20 Basket Pipe",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-10-26T22:14:08.693336Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 14,
      "regulatoryId": "",
      "sku": "LMiJDvrtB5",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "01f06dd1-ea8b-4dfe-a5c2-d12a113046f2",
      "variantName": "",
      "weightTierInformation": null
    },
    {
      "brand": "Maryjane's House of Glass",
      "cannabinoidInformation": null,
      "category": "Accessories",
      "clientId": "5dd15878-aa4c-4adf-8650-b931f32a7b67",
      "costInMinorUnits": 1000,
      "createdAt": "2023-03-30T23:24:26Z",
      "currencyCode": "USD",
      "customCategoryName": null,
      "erpId": null,
      "erpName": null,
      "expirationDate": null,
      "inventoryUnitOfMeasure": "Each",
      "inventoryUnitOfMeasureToGramsMultiplier": null,
      "invoiceNumber": null,
      "isMixAndMatch": null,
      "isSoldByWeight": false,
      "isStackable": null,
      "locationId": "10f19fc5-31d0-4d76-bad4-ad593c9803ae",
      "locationName": "Portland Pot Shop",
      "manifestId": null,
      "nutrients": null,
      "parentProductId": "a58a4351-47d7-4fb9-9abc-44e0619f7191",
      "parentProductName": "$20 Color Changing Glass Pipe",
      "postTaxPriceInPennies": 2000,
      "preTaxPriceInPennies": 2000,
      "priceInMinorUnits": 2000,
      "productDescription": "",
      "productId": "5971108e-d5ab-4254-ba44-09b64979b66a",
      "productName": "$20 Color Changing Glass Pipe",
      "productPictureURL": null,
      "productUnitOfMeasure": "Each",
      "productUnitOfMeasureToGramsMultiplier": null,
      "productUpdatedAt": "2023-10-26T22:14:08.693336Z",
      "productWeight": null,
      "purchaseCategory": "unrestricted",
      "quantity": 31,
      "regulatoryId": "",
      "sku": "3000026",
      "speciesName": null,
      "supplierName": null,
      "terpenes": null,
      "type": "Glass",
      "variantId": "071458c9-5b41-4eb5-91bc-027fb3856546",
      "variantName": "",
      "weightTierInformation": null
    }
  ]
}