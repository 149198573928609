import { Injectable } from '@angular/core';
import { AuthConfig } from '../models/config/auth-config';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
    constructor() {
  }

  public static getAuthConfig(): AuthConfig { 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const wind: any = window;
    return wind.authConfig;                                
  }
}
