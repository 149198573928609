<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div #appCategory class="app-category" *ngIf="!ShowCarousel" [@fade]="value">
    {{ value }}
</div>

<div #appCategory *ngIf="ShowCarousel" class="categories-carousel">
    <ngx-slick-carousel class="carousel"
        #slickModal="slick-carousel" 
        [config]="slideConfig">
        <div ngxSlickItem #slideElement [ngStyle]="getSlideStyle(slide)" *ngFor="let slide of slides" (keypress)="onSelectSlide(slideElement)" (click)="onSelectSlide(slideElement)" class="slide category-slide" >
            <div class="category-slide-title">{{ slide.title }}</div>
        </div>
    </ngx-slick-carousel>
</div>