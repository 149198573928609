import { KeyValue } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewContainerRef, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ProductApiResponse } from 'src/app/models/product-api-response';
import { ConfigService } from 'src/app/services/config.service';
import { FlowHubApiService } from 'src/app/services/flowhub-api.service';
import { AppComponent } from '../app/app.component';
import { debounceTime, delay, distinctUntilChanged, Subject } from 'rxjs';
import { CategoryCarouselComponent } from '../category-carousel/category-carousel.component';
import { MultiColumnMenuConfig } from 'src/app/models/config/multi-column-menu-config';
import Aos from 'aos';

@Component({
  selector: 'app-multi-column-menu',
  templateUrl: './multi-column-menu.component.html',
  styleUrls: ['./multi-column-menu.component.css']
})
export class MultiColumnMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  private config!: MultiColumnMenuConfig;
  private exitLoop: boolean = false;
  private started!: Date;
  
  public productsByCategory: KeyValue<string, Product[]>[] = [];
  public products: Product[] = [];
  private appComponent!: AppComponent;
  private get appContent(): undefined | null {return  this.appMenu?.nativeElement.parentElement?.parentElement;}
 
  private category$: Subject<string | null | undefined> = new Subject<string | null | undefined>();
  private get category(): CategoryCarouselComponent{
    return this.appComponent.category || new CategoryCarouselComponent(this.flowHubApiService);
  }
  
  @ViewChild('appMenu') 
  private appMenu: ElementRef | null = null;
  
  constructor(private flowHubApiService: FlowHubApiService,
              private configService: ConfigService,                  
              private viewContainerRef: ViewContainerRef) { }

  async ngOnInit() {
    Aos.init();
    this.config = (await this.configService.getConfigAsync()).multiColumnMenuConfig;
    this.appComponent = this.viewContainerRef.parentInjector.get<AppComponent>(AppComponent); 
    this.appComponent.ShowCategoryCarousel = false;
    this.appComponent.ScrollableContent = false;
    this.appComponent.ShowFooter = true;
    
    this.category.categorySubscription = this.category$.asObservable()
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())
    .subscribe((category: string | null | undefined) => { this.category.value = category || "" });
  }
  
  ngOnDestroy() {
    this.category.categorySubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => this.updateMenu(), 1000);
  }
  
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) { if (event.key === 'Escape') this.exitLoop = true; }
  
  async updateMenu() {
    this.started = new Date();
    while(!this.exitLoop){
      
      // See if we need to query the POS again if the data we are using is too old
      //const dataAge: number = new Date().getTime() - this.started.getTime();
      //const ts: TimeSpan = TimeSpan.fromTime(this.started.getHours(), this.started.getMinutes(), this.started.getSeconds())
      //if(ts.totalMinutes > this.config.maxDataAge){
      await this.loadProductsByCategoryAsync();
      this.started = new Date();
      //}
      
      this.productsByCategory.forEach(async (keyValue: KeyValue<string, Product[]>) => {
        this.category$.next(keyValue.key);
        let numberOfProductsLeft:number =  keyValue.value.length,
          startIndex: number = 0;
        while(numberOfProductsLeft > 0)
        {
          Object.assign(this.products, keyValue.value.splice(startIndex, this.config.productsPerPage));
          startIndex += this.config.productsPerPage;
          numberOfProductsLeft -= this.config.productsPerPage;
          await delay(600 * 1000);
        }
      }); 
    }
  }

  async loadProductsByCategoryAsync(): Promise<void> {
    const productApiResponse: ProductApiResponse = await this.flowHubApiService.getProductsAsync();
    const allProducts: Product[] = productApiResponse.status === HttpStatusCode.Ok ?
      productApiResponse.data.sort((a, b) => a.category.localeCompare(b.category)) : [];
    const categories: string[] = await this.flowHubApiService.getCategoriesAsync();
    
    this.productsByCategory = [];
    categories.sort().forEach(category => 
      this.productsByCategory.push({
        key: category, 
        value: allProducts.filter((product: Product) => product.category === category && product.quantity > 0).sort()
      })
    );
  }
}


