<div class="container">
  <form [formGroup]="configForm" name="configForm" *ngIf="configForm" (ngSubmit)="onSubmit($event)">
    <div class="form-group row">
      <div class="col-lg-4">
        <label for="amountToScroll">Amount to Scroll:</label>
        <input name="amountToScroll" class="form-control" formControlName="amountToScroll" placeholder="Number 0f pixels to scroll when the timer fires.">
      </div>
      <div class="col-lg-4">
        <label for="autoScrollTimeout">Auto Scroll Timeout:</label>
        <input name="autoScrollTimeout" class="form-control" formControlName="autoScrollTimeout" placeholder="How often the timer fires (in ms)">
      </div>
      <div class="col-lg-4">
        <label for="dataAgeThreshold" >Data Age Threshold:</label>
        <input name="dataAgeThreshold" class="form-control" formControlName="dataAgeThreshold" placeholder="How often the products are pulled from Flow Hub (in sec)">
      </div>
    </div>

   <div class="form-group row">
     <button class="btn btn-primary btn-lg btn-block" type="submit">Save</button>
   </div>
</form>