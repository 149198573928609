<div class="container app-table">
    <ngx-datatable class="material container striped" #dataTable 
        [loadingIndicator]="showBusyIndicator" 
        [rows]="allProducts" 
        [columnMode]="'flex'" 
        [headerHeight]="25" 
        [footerHeight]="25" 
        [scrollbarV]="false"
        [scrollbarH]="true"
        [rowHeight]="'auto'">
        <ngx-datatable-column name="#" [flexGrow]="0.5">
            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                {{(rowIndex + 1)}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Brand" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row?.brand}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Name" [flexGrow]="4">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.productName }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Price" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{ ((row?.postTaxPriceInPennies ?? 0) / 100) | currency:'USD':"symbol":'1.2-2'}}
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>
