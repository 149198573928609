<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="card">
    <div class="card-body text-center">
        <div class="modal-header">
            <a style="text-align:left; cursor: pointer" (click)="showMyAccount();">Manage</a>
            <a style="text-align:center; cursor: pointer" (click)="logout();">Logout</a>
            <a style="text-align:right; cursor: pointer" (click)="close();">Close</a>
        </div>

        <div class="modal-body">
            <div>
                <ngx-avatars src="{{photoUrl}}" [size]="140" [name]="'{{profile.displayName}}'"></ngx-avatars>
            </div>
            <h2>{{profile.displayName}}</h2>

            <p>{{profile.mail}}</p>
            <p>{{profile.mobilePhone}}</p>
        </div>

        <div class="modal-footer">
            <div *ngFor="let account of accounts" class="profile-button">
                <app-profile-button *ngIf="account.name !== profile.displayName" [accountInfo]="account" (click)="loginUser(account)"></app-profile-button>
            </div>
            <div class="profile-button">
                <app-profile-button [accountInfo]="null" (click)="loginUser(null)"></app-profile-button>
            </div>

        </div>
    </div>
</div>
