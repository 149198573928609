import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../models/product';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FlowHubApiService } from '../../services/flowhub-api.service';
import { HttpStatusCode } from '@angular/common/http';
import { Slide } from '../../models/slide';
import { AppComponent } from '../app/app.component';
import { Configuration } from '../../models/config/configuration';
import { ConfigService } from '../../services/config.service';
import { KeyValue } from '@angular/common';
import { ProductApiResponse } from '../../models/product-api-response';

@Component({
  selector: 'app-online-menu-board',
  templateUrl: './online-menu-board.component.html',
  styleUrls: ['./online-menu-board.component.css']
})
export class OnlineMenuBoardComponent implements OnInit {
  public category : string = '';
  public productsListByCategory: KeyValue<string, Product[]>[] = [];
  private config!: Configuration;
  public allProducts: Product[] = [];
  showBusyIndicator: boolean = true;

  @ViewChild('dataTable', { static: true })
  private ngxDatatable!: DatatableComponent;

  @Input()
  public categorySlideConfig = {
    "slidesToShow": 6, 
    "slidesToScroll": 2, 
    "dots": true,
    "infinite": true, 
    "autoplay": true, 
    "autoplaySpeed": 10000, 
    "speed": 500, 
    "arrows": true
  };
  
  @Input()
  public categorySlides : Slide[] = [];
  
  constructor(private flowHubApiService: FlowHubApiService,
    private configService: ConfigService, 
    private appComponent: AppComponent) { }

  async ngOnInit() {
    this.config = await this.configService.getConfigAsync();
    this.appComponent.ShowCategoryCarousel = true;
    this.appComponent.ScrollableContent = true;
    await this.loadProductsByCategory();
  }

  async loadProductsByCategory() {
    this.showBusyIndicator = true;
    const productApiResponse: ProductApiResponse = await this.flowHubApiService.getProductsAsync();
     
    this.allProducts = productApiResponse.status === HttpStatusCode.Ok ?
      productApiResponse.data.sort((a, b) => a.category.localeCompare(b.category)) : [];
    const categories: string[] = await this.flowHubApiService.getCategoriesAsync();
    categories.sort().forEach((category: string) => {
        this.productsListByCategory.push({
          key: category, 
          value: this.allProducts.filter((product: Product) => product.category === category && product.quantity > 0).sort()
        });
        this.categorySlides.push({
          title: category, 
          image: "/assets/images/" + category.toLowerCase() + '.avif', 
          description: '', 
          highlighted: false, 
          textColor: 'white', 
          backgroundColor: 'black'
        });
      });
    this.showBusyIndicator = false;
  }
}
