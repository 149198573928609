<div class="container">
  <form [formGroup]="configForm" name="configForm" *ngIf="configForm" (ngSubmit)="onSubmit($event)">
    <div class="form-group row">
      <div class="col-lg-4">
        <label for="slidesToShow">Slides to show</label>
        <input name="slidesToShow" type="number" class="form-control" formControlName="slidesToShow" placeholder="1000000">
      </div>
      <div class="col-lg-4">
        <label for="slidesToScroll">Slides to scroll</label>
        <input name="slidesToScroll" type="number" class="form-control" formControlName="slidesToShow" placeholder="/api">
      </div>
      <div class="form-check col-lg-4">
        <br>
        <input name="dots" class="form-check-input" type="checkbox" formControlName="dots" placeholder="">
        <label for="dots" class="form-check-label">Dots</label>
      </div>
    </div>
    
    <div class="form-group row">
      <div class="form-check col-lg-4">
        <br>
        <input name="infinite" class="form-check-input" type="checkbox" formControlName="infinite" placeholder="">
        <label for="infinite" class="form-check-label">Infinite</label>
      </div>
      <div class="form-check col-lg-4">
        <br>
        <input name="autoplay" class="form-check-input" type="checkbox" formControlName="autoplay" placeholder="">
        <label for="autoplay" class="form-check-label">Autoplay</label>
      </div>
      <div class="col-lg-4">
        <label for="autoplaySpeed">Autoplay Speed</label>
        <input name="autoplaySpeed" type="number" class="form-control" formControlName="autoplaySpeed" placeholder="">
      </div>
    </div>
    
    <div class="form-group row">
        <div class="col-lg-4">
          <label for="speed">Speed</label>
          <input name="speed" class="form-control" type="number" formControlName="speed" placeholder="">
        </div>
        <div class="form-check col-lg-4">
          <br>
          <input name="arrows" class="form-check-input" type="checkbox" formControlName="arrows" placeholder="">
          <label for="arrows" class="form-check-label">Arrows</label>
        </div>
    </div>
    
    <div class="form-group row">
      <button class="btn btn-primary btn-lg btn-block" type="submit">Save Configuration</button>
    </div>
</form>