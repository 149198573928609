import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { Product } from '../../models/product';
import { FlowHubApiService } from '../../services/flowhub-api.service';
import { AppComponent } from '../app/app.component';
import { KeyValue } from '@angular/common';
import { Subject } from 'rxjs';
import { ConfigService } from '../../services/config.service';
import { MenuBoardConfig } from '../../models/config/menu-board-config';
import { ProductApiResponse } from '../../models/product-api-response';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CategoryCarouselComponent } from '../category-carousel/category-carousel.component';
import Aos from 'aos';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-digital-menu-board',
  templateUrl: './digital-menu-board.component.html',
  styleUrl: './digital-menu-board.component.css',
  changeDetection: ChangeDetectionStrategy.Default
})
export class DigitalMenuBoardComponent implements AfterViewInit, OnInit, OnDestroy {
  public productsByCategory: KeyValue<string, Product[]>[] = [];
  private dataAge: Date = new Date();
  private autoScrollTimer: NodeJS.Timeout | number | undefined = undefined;
  private category$: Subject<string | null | undefined> = new Subject<string | null | undefined>();
  private get category(): CategoryCarouselComponent{       
    return this.appComponent.category || new CategoryCarouselComponent(this.flowHubApiService);
  }
  
  private appComponent!: AppComponent;
  private get appContent(): undefined | null {return  this.productsDivByCategory?.nativeElement.parentElement?.parentElement;}
  
  private get config(): MenuBoardConfig {
    return this.appComponent.config.menuBoardConfig;
  }

  @ViewChild('productsDivByCategory') 
  private productsDivByCategory: ElementRef | null = null;
 
  constructor(private flowHubApiService: FlowHubApiService,
              private configService: ConfigService,
              private viewContainerRef: ViewContainerRef,
              private logService: LogService) 
              {
                this.logService.className = "DigitalMenuBoardComponent";
                this.logService.methodName = "";
              }

  async ngOnInit(): Promise<void> {
    this.logService.methodName = "ngOnInit()";
    Aos.init();
    this.appComponent = this.viewContainerRef.parentInjector.get<AppComponent>(AppComponent); 
    this.appComponent.ShowCategoryCarousel = false;
    this.appComponent.ScrollableContent = false;
    this.appComponent.ShowFooter = true;
    this.logService.log("Menu Board Config Data", this.config);

    this.category.categorySubscription = this.category$.asObservable()
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((category: string | null | undefined) => { this.category.value = category || "" });
    await this.loadProductsByCategoryAsync(); 
    this.logService.methodName = "";
  }
  
  ngOnDestroy() {
    this.logService.methodName = "ngOnDestroy()";
    if ( this.autoScrollTimer ) clearTimeout(this.autoScrollTimer);
    if ( this.category.categorySubscription ) this.category.categorySubscription?.unsubscribe();
    this.logService.methodName = "";
  }

  ngAfterViewInit() {
    this.logService.methodName = "ngAfterViewInit()";
    try {
      if(this.config) {
        this.logService.log("Starting the autoScrollTimer with a timeout value of: / scrollAmount value of:", 1000, this.config.amountToScroll);
        this.autoScrollTimer = setTimeout(() => this.autoScroll(this.config.amountToScroll), 1000);
      }
      else {
        this.logService.warn("Configuration is still not present at this time so we are not able to read the value for 'amountToScroll'. The autoscroll timer wasn't started.", this.config);
      }
    }
    catch(error: unknown) {
      this.logService.error(error, this.config);
    }
    finally {
      this.logService.methodName = "";
    }
  }

  async autoScroll(scrollAmount: number): Promise<void> {
    try {
      this.logService.methodName = "autoScroll(" + scrollAmount + ")";
      
      // Look to see if the current element at the top of the app-content Div is a product so we can extract it's category
      const currentElement: Element | null = window.document.elementFromPoint(50, 475); 
      this.logService.log("currentElement (window.document.elementFromPoint(50, 475))", currentElement);

      if(currentElement?.className === 'product'){ 
        const category: string = currentElement?.parentElement?.parentElement?.firstElementChild?.textContent || ""; 
        this.category$.next(category);
        this.logService.log("Category located in currentElement and broadcast ", category);
      }

      // Scroll the app-content Div by the amount specified in the environment
      const appContent: HTMLElement = this.appContent as unknown as HTMLElement;
      if(appContent) {
        this.logService.log("appContent located", appContent);
        const scrollTop: number = appContent.scrollTop || 0;
        this.logService.log("appContent.scrollTop", scrollTop);
        appContent.scrollBy(0, scrollAmount);
        this.logService.log("scrollBy() called", scrollAmount);

        // Check to see if we've reached the bottom of the page and reset the scroll position to the top and 
        // possibly reload the products if dateAge is greater than the configured threshold
        if(scrollTop >= appContent.scrollTop) {
          this.logService.log("appContent didn't scroll to the point that we expected, new appContent.scrollTop value", appContent.scrollTop);
          const timeSpan: number  = new Date().getTime() - this.dataAge.getTime();
          this.logService.log("Time span since last product data refresh", timeSpan);
          if(timeSpan > this.config.dataAgeThreshold) {
            this.logService.log("Product data is older than the configured threshold. The configured threshold is:", this.config.dataAgeThreshold)
            await this.loadProductsByCategoryAsync();
          }
          this.logService.log("Scrolling to top of page and setting category to blank")
          appContent.scrollTo(0, 0);
          this.category$.next("");                                  
        }
      }
    }
    catch(error: unknown) {
      this.logService.error(error);
    }
    finally {
      // Call the autoScroll method again after the timeout specified in the environment
      this.logService.log("Starting the autoScrollTimer with a timeout value of: / scrollAmount value of:", this.config.autoScrollTimeout, scrollAmount);
      this.autoScrollTimer = setTimeout(() => this.autoScroll(scrollAmount), this.config.autoScrollTimeout);

      this.logService.methodName = "";
    }
  }  
  
  async loadProductsByCategoryAsync(): Promise<void> {
    this.logService.methodName = "loadProductsByCategoryAsync()";
    this.logService.log("Retrieving all products from FlowHub")
    const productApiResponse: ProductApiResponse = await this.flowHubApiService.getProductsAsync();
    
    this.logService.log("Sorting all products in the list by category");
    const allProducts: Product[] = productApiResponse.status === HttpStatusCode.Ok ?
      productApiResponse.data.sort((a, b) => a.category.localeCompare(b.category)) : [];

    this.logService.log("Retrieving all categories in the list");
    const categories: string[] = await this.flowHubApiService.getCategoriesAsync();
    
    this.logService.log("Creating a dictionary of products that is keyed by the category");
    this.productsByCategory = [];
    categories.sort().forEach(category => 
      this.productsByCategory.push({
        key: category, 
        value: allProducts.filter((product: Product) => product.category === category && product.quantity > 0).sort()
      })
    );
    this.logService.methodName = "";
  }
}

