import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { AppComponent } from '../app/app.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit  {
  protected get loginDisplay(): boolean{
    return this.appComponent.loginDisplay;
  }
  
  protected get photoUrl(): string {
    return this.appComponent.photoUrl;
  }
  
  protected get profile(): Profile {
    return this.appComponent.profile;
  }
  
  private appComponent!: AppComponent;
  
  constructor(private viewContainerRef: ViewContainerRef) { }
  
  ngOnInit(): void {
    this.appComponent = this.viewContainerRef.parentInjector.get<AppComponent>(AppComponent); 
  }
  
  protected login(): void {
    this.appComponent.login();
  }
  
  protected logout(): void {
    this.appComponent.logout();
  }

  protected showProfile(): void {
    //this.appComponent();
  }
}
