import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Slide } from '../../models/slide';
import { ConfigService } from '../../services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Configuration } from 'src/app/models/config/configuration';

@Component({
  selector: 'app-footer-carousel-slide-config',
  templateUrl: './footer-carousel-slide-config.component.html',
  styleUrls: ['./footer-carousel-slide-config.component.css']
})
export class FooterCarouselSlideConfigComponent implements AfterViewInit {
  configForm!: FormGroup;
  showBusyIndicator: boolean = false;
  
  @ViewChild(DatatableComponent)
  footerCarouselSlidesTable!: DatatableComponent;
  
  @Input()
  public slides!: Slide[];
  
  constructor(private formBuilder: FormBuilder,
              private configService: ConfigService) { }
  
  ngAfterViewInit(): void {
    this.createConfigForm(); 
  }
  
  createConfigForm() {
    this.configForm = this.formBuilder.group({
      slides: [this.slides, Validators.required]
    });
  }

  async onSubmit($event: SubmitEvent) {
    $event.preventDefault();
    //this.slides = this.configForm.controls['slides'].value;
    const config: Configuration = await this.configService.getConfigAsync();
    config.footerCarouselSlideConfig = this.slides;
    await this.configService.saveConfigAsync(config);
  }
}
