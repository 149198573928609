// Disable no-explicit-any so that this classes method signatures can match the console methods signatures
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/*
The LogService is really just a wrapper around the existing console logging functionality
The three main reasons for using this service are: 
  1. The abstraction layer will allow us to pick a different logging  endpoint if needed
  2. This service allows you to specify what level verbosity you want this service tp log with
  3. Has logic to log method entry and exit points automagically
*/
export class LogService { 
  public logLevel: "Error" | "Warn" | "Debug" | "Info" | "None" = "None";

  private _className: string = "";
  public get className(): string { return this._className; }
  public set className(value: string) { 
    this._className = value; 
    this.methodName = "constructor()"
  }
  
  private _methodName: string = "";
  public get methodName(): string {return this._methodName; }
  public set methodName(value: string) { 
    if(value === "") {
      this.info("End of method");
      console.groupEnd();
      console.timeEnd(this.targetName);
      this._methodName = value;
    }
    else {
      this._methodName = value;
      console.group(this.targetName);
      console.time(this.targetName);
      this.info("Start of method");
    }
  }
  
  private get targetName(): string { 
    if(this.methodName === "")
      return this.className;
    else 
      return this.className + "." + this.methodName;
  }

  private get prefix(): string { 
    return Date.now().toLocaleString() + " " + this.targetName + "> ";
  }

  constructor() {}

  public log(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel != "None") { 
      if( this.targetName != this.className )
        console.timeLog(this.targetName, this.prefix + " " + message, optionalParams);
      else
        console.log(this.prefix + " " + message, optionalParams);
    } 
  }  

  public info(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel === "Debug" || this.logLevel === "Info") { 
      if( this.targetName != this.className )
        console.timeLog(this.targetName, this.prefix + " " + message, optionalParams);
      else
        console.info(this.prefix + " " + message, optionalParams);
    } 
  }  

  public warn(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel === "Warn" || this.logLevel === "Debug" || this.logLevel === "Info") { 
      if( this.targetName != this.className )
        console.time(this.targetName);  
      console.warn(this.prefix + " " + message, optionalParams);
    } 
  }  

  public error(message?: any, ...optionalParams: any[]): void {
    if (this.logLevel != "None") { 
      if( this.targetName != this.className )
        console.time(this.targetName);  
      console.error(this.prefix + " " + message, optionalParams);
    } 
  }
}
