import { Injectable } from '@angular/core';
import { Configuration } from  '../models/config/configuration';
import { AppConfigurationClient, SetConfigurationSettingParam } from '@azure/app-configuration';
import { MockConfig } from './mocks/mock-location-config';
import { Profile } from '../models/profile';
import { CookieService } from 'ngx-cookie-service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  private readonly mockData: boolean = false;
  private readonly configClient!: AppConfigurationClient;
  private configConnectString!: string;
  private profile: Profile | undefined = undefined;
  private readonly key: string = "App.Config.Json";
  
  constructor(private cookieService: CookieService,
              private logService: LogService) {
    this.logService.className = "ConfigService";
    this.configConnectString = "Endpoint=https://cheap-weed-menus-appconfig.azconfig.io;Id=tyjA;Secret=1FgL95lHkXViZX4Qf2GcRqn26mhTYDVYany8ToXpTnO68AzrdUUEJQQJ99AHAC8vTInIcYexAAACAZACsteF"; 
    this.configClient = new AppConfigurationClient(this.configConnectString);
    this.logService.log("Config Client", this.configClient);
    this.logService.methodName = "";
  }

 public async getConfigAsync(): Promise<Configuration>{
  this.logService.methodName = "getConfigAsync()";
   if(this.configCache == undefined) {
    this.logService.log("configCache == undefined")
    // If we want to use Mock data then load the Mock data into the cache
    if(this.mockData){
       this.configCache = JSON.stringify(MockConfig);
    }
    else {
      this.logService.log("Cookie Profile", this.cookieService.get("profile"));
      this.profile = JSON.parse(this.cookieService.get("profile") ?? "{}"); 

      this.logService.log("Profile Object", this.profile);
      const setting = await this.configClient.getConfigurationSetting({ key: this.key, label: this.profile?.companyName });
      this.logService.log("Setting", setting);
      this.configCache = setting.value;
    }
   }
   this.logService.log("Config Cache", this.configCache);
   const config:Configuration = JSON.parse(this.configCache ?? "{}") satisfies Configuration
   this.logService.methodName = "";

   return config;
 }

  public async saveConfigAsync(config: Configuration): Promise<void> {
    this.logService.methodName = "saveConfigAsync()";

    // Update configuration cache
    this.configCache = JSON.stringify(config);
    this.logService.log("Config Cache", this.configCache);

    // If we are using mock data we are done here
    if(this.mockData) { return ;}
   
    // We aren't using MockData so we need to save the changes back to the Configuration Service
    this.profile = JSON.parse(this.cookieService.get("profile") ?? "{}"); 
    const setting: SetConfigurationSettingParam = {
      key: this.key,
      label: this.profile?.companyName,
      value: this.configCache,
      contentType: 'JSON',
    };
    this.logService.log("Setting", setting);
    await this.configClient.setConfigurationSetting(setting);
    this.logService.methodName = "";
  }

  private  _configCache: string | undefined = undefined;
  protected get configCache(): string | undefined {
    return this._configCache;
  }
  protected set configCache(value: string | undefined) {
    this._configCache = value; 
  }
}
