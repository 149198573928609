<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="container">
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" routerLink='/home' href="#">Home <span class="sr-only">(current)</span></a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <li *ngIf="loginDisplay" class="nav-item">
        <a class="nav-link" routerLink='/autoscroll' href="#">Auto Scroll</a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <li *ngIf="loginDisplay" class="nav-item">
        <a class="nav-link" routerLink='/multicolumn' href="#">Multi-Column</a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <li *ngIf="loginDisplay" class="nav-item">
        <a class="nav-link" routerLink='/animation' href="#">Animation</a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <li *ngIf="loginDisplay" class="nav-item">
        <a class="nav-link" routerLink='/online' href="#">Online</a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <li *ngIf="loginDisplay" class="nav-item">
        <a class="nav-link" routerLink='/setup' href="#">Setup</a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <li *ngIf="loginDisplay" class="nav-item">
        <a class="nav-link" routerLink='/config' href="#">Configuration Service</a>
        
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;     
      <li *ngIf="!loginDisplay" class="nav-item">
        <a class="nav-link" (click)="login()"><i class="btn btn-outline-secondary btn-search fa fa-windows"> Login</i></a>
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;  
      <li *ngIf="loginDisplay && photoUrl === ''" class="nav-item">
        <a class="nav-link" (click)="logout()"><i class="btn btn-outline-secondary btn-search fa fa-windows"> Logout</i></a>
      </li>   
      <li *ngIf="loginDisplay && photoUrl !== ''" class="nav-item">
        <ngx-avatars src="{{photoUrl}}" [size]="30" name="{{profile.displayName}}" (click)="logout()"></ngx-avatars>
      </li>
      &nbsp; &nbsp; &nbsp; &nbsp;     
    </ul>
  </div>
</nav>
</div>