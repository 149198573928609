<div *ngIf="config" class="container config-tab-group" >
<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem class="active">
    <a ngbNavLink>FlowHub API</a>
    <ng-template ngbNavContent *ngIf="config?.flowHubConfig">
      <app-flowhub-api-config #flowhubApiConfig  [flowHubConfig]="config.flowHubConfig"/>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Digital Menu Board</a>
    <ng-template ngbNavContent *ngIf="config?.menuBoardConfig">
      <app-digital-menu-board-config  [menuBoardConfig]="config.menuBoardConfig" #digitalMenuBoardConfig/>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Footer Carousel</a>
    <ng-template ngbNavContent *ngIf="config?.footerCarouselConfig">
      <app-footer-carousel-config [footerCarouselConfig]="config.footerCarouselConfig" #footerCarouselConfig/>
    </ng-template>
  </li>
  <li ngbNavItem> 
    <a ngbNavLink>Footer Carousel Slides</a>
    <ng-template ngbNavContent *ngIf="config?.footerCarouselSlideConfig">
      <app-footer-carousel-slide-config [slides]="config.footerCarouselSlideConfig" #footerCarouselSlideConfig/>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
