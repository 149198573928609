<div class="modal modal-sm fade" bsModal #editorModal="bs-modal" id="myModal" tabindex="-1" role="dialog" data-backdrop="false">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <app-profile #profileEditor [parent]="editorModal"></app-profile>
      </div>
  </div>
</div>
<div #appContainer class="app-container">
  <div #appHeader class="app-header">
    <a routerLink='/home' href="#"><img #appImage class="app-logo" alt="app-logo" title="app-logo" src="assets/images/PPS-Logo-Black-Blue-Green.jpg"/></a>
    <app-category-carousel #appCategory [showCarousel]="showCategoryCarousel$"/>
  </div>
  <div #appContent *ngIf="ScrollableContent" class="app-scrollable-content">   
     <router-outlet *ngIf="!isIframe"></router-outlet>
  </div> 
  <div #appContent *ngIf="!ScrollableContent" class="app-content">   
    <router-outlet *ngIf="!isIframe"></router-outlet>
 </div> 
  <div #appFooter *ngIf="ShowFooter" class="app-footer">
    <app-footer-carousel #footerCarousel/>
  </div>
</div>
