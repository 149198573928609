// Components - declarations
import { ConfigurationComponent } from '../components/configuration/configuration.component';
import { DigitalMenuBoardComponent } from '../components/digital-menu-board/digital-menu-board.component';
import { ProductComponent } from '../components/product/product.component';
import { AppComponent } from '../components/app/app.component';
import { MainComponent } from '../components/main/main.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { OnlineMenuBoardComponent } from '../components/online-menu-board/online-menu-board.component';
import { FooterCarouselComponent } from '../components/footer-carousel/footer-carousel.component';
import { CategoryCarouselComponent } from '../components/category-carousel/category-carousel.component';
import { FooterCarouselSlideConfigComponent } from '../components/footer-carousel-slide-config/footer-carousel-slide-config.component';
import { FlowhubApiConfigComponent } from '../components/flowhub-api-config/flowhub-api-config.component';
import { FooterCarouselConfigComponent } from '../components/footer-carousel-config/footer-carousel-config.component';
import { DigitalMenuBoardConfigComponent } from '../components/digital-menu-board-config/digital-menu-board-config.component';
import { MultiColumnMenuComponent } from '../components/multi-column-menu/multi-column-menu.component';
import { AnimationMenuComponent } from '../components/animation-menu/animation-menu.component';
import { ProductListItemComponent } from '../components/product-list-item/product-list-item.component';
import { ConfigurationServiceExplorerComponent } from '../components/configuration-service-explorer/configuration-service-explorer.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { ProfileButtonComponent } from '../components/profile-button/profile-button.component';
import { WellKnownComponent } from '../components/well-known/well-known.component';

// Modules - imports
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { provideHttpClient, withInterceptorsFromDi, HttpClientJsonpModule, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule, NoopAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatars';

// Services - providers
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from '../services/local-storage.service';
import { ConfigService } from '../services/config.service';
import { FlowHubApiService } from '../services/flowhub-api.service';
import { NavBarComponent } from '../components/nav-bar/nav-bar.component';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor, MsalGuardConfiguration,
    MsalInterceptorConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { Utilities } from '../helpers/utilities';
import { AuthConfig } from '../models/config/auth-config';
import { AuthService } from '../services/auth.service';
import { LogService } from '../services/log.service';
  
  const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  const baseUrl = Utilities.baseUrl();
  const apiUri = "api://" + window.location.hostname;
  const authConfig: AuthConfig = AuthService.getAuthConfig(); 
  
  //configService.getAuthConfig();
// App module declarations
@NgModule({ declarations: [
        AppComponent,
        MainComponent,
        OnlineMenuBoardComponent,
        PageNotFoundComponent,
        DigitalMenuBoardComponent,
        ProductComponent,
        FooterCarouselComponent,
        ConfigurationComponent,
        CategoryCarouselComponent,
        FooterCarouselSlideConfigComponent,
        FooterCarouselConfigComponent,
        DigitalMenuBoardConfigComponent,
        FlowhubApiConfigComponent,
        ConfigurationComponent,
        NavBarComponent,
        AnimationMenuComponent,
        MultiColumnMenuComponent,
        ProductListItemComponent,
        ConfigurationServiceExplorerComponent,
        ProfileComponent,
        ProfileButtonComponent,
        WellKnownComponent
    ],
    imports: [
        AvatarModule,
        ModalModule,
        MatDialogModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        AppRoutingModule,
        NgxDatatableModule,
        SlickCarouselModule,
        FormsModule,
        ReactiveFormsModule ,
        NgbModule,
        HttpClientJsonpModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
              clientId: authConfig.clientId,
              authority: authConfig.authority,
              redirectUri: authConfig.redirect_uri,
              postLogoutRedirectUri: authConfig.logout_redirect_uri
            },
            cache: {
              cacheLocation: 'localStorage',
              storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            }
          }), { 
            interactionType: InteractionType.Redirect, 
            authRequest: {
              scopes: ['user.read']
            },
          } as MsalGuardConfiguration, { 
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
              ['https://graph.microsoft.com/v1.0/me', ['user.read']],
              ['https://graph.microsoft.com/v1.0/me/photo/$value', ['user.read']],
              [baseUrl + '/api/account', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/account/GetAccountsById', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/account/GetAccountsByName', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/account/CreateAccount', [apiUri + '/Admin.Add']],
              [baseUrl + '/api/account/UpdateAccount', [apiUri + '/Admin.Edit']],
              [baseUrl + '/api/account/DeleteAccount', [apiUri + '/Admin.Delete']],
              [baseUrl + '/api/location', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/location/GetLocationsById', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/location/GetLocationsByName', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/location/GetLocationsByAccount', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/location/CreateLocation', [apiUri + '/Admin.Add']],
              [baseUrl + '/api/location/UpdateLocation', [apiUri + '/Admin.Edit']],
              [baseUrl + '/api/location/DeleteLocation', [apiUri + '/Admin.Delete']],
              [baseUrl + '/api/terminal', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/terminal/GetTerminalsById', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/terminal/GetTerminalsByTerminalNumber', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/terminal/GetTerminalsByLocation', [apiUri + '/Admin.Read']],
              [baseUrl + '/api/terminal/CreateTerminal', [apiUri + '/Admin.Add']],
              [baseUrl + '/api/terminal/UpdateTerminal', [apiUri + '/Admin.Edit']],
              [baseUrl + '/api/terminal/DeleteTerminal', [apiUri + '/Admin.Delete']]
            ])
          } as MsalInterceptorConfiguration)
    ],
    providers: [
        LogService,
        CookieService,
        LocalStorageService,
        ConfigService,
        FlowHubApiService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
          provide: MatDialogRef,
          useValue: {}
        },
        {
          provide: MAT_DIALOG_DATA,
          useValue: {}
        }
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
