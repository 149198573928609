import { AfterViewInit, Component, Input } from '@angular/core';
import { FlowHubConfig } from '../../models/config/flowHub-config';
import { ConfigService } from '../../services/config.service';
import { FormGroup, FormBuilder, Validators } from  '@angular/forms';
import { Configuration } from 'src/app/models/config/configuration';

@Component({
  selector: 'app-flowhub-api-config',
  templateUrl: './flowhub-api-config.component.html',
  styleUrls: ['./flowhub-api-config.component.css']
})
export class FlowhubApiConfigComponent implements AfterViewInit{
  configForm!: FormGroup;

  @Input()
  public flowHubConfig!: FlowHubConfig;

  constructor(private formBuilder: FormBuilder, private configService: ConfigService) { }
  
  ngAfterViewInit(): void {
    this.createConfigForm(); 
  }

  createConfigForm()   {
    this.configForm = this.formBuilder.group({
      apiBaseUrl: [this.flowHubConfig.apiBaseUrl, Validators.required],
      productApiPath: [this.flowHubConfig.productApiPath, Validators.required],
      apiKey: [this.flowHubConfig.apiKey, Validators.required],
      clientId: [this.flowHubConfig.clientId, Validators.required],
      locationId: [this.flowHubConfig.locationId, Validators.required]
    });
  }

  async onSubmit($event: SubmitEvent): Promise<void> {
    $event.preventDefault();
    this.flowHubConfig.apiBaseUrl =  this.configForm.controls['apiBaseUrl'].value;
    this.flowHubConfig.productApiPath =  this.configForm.controls['productApiPath'].value;
    this.flowHubConfig.apiKey =  this.configForm.controls['apiKey'].value;
    this.flowHubConfig.clientId =  this.configForm.controls['clientId'].value;
    this.flowHubConfig.locationId =  this.configForm.controls['locationId'].value;

    const config: Configuration = await this.configService.getConfigAsync();
    Object.assign(config.flowHubConfig, this.flowHubConfig);
    await this.configService.saveConfigAsync(config);
  }
}
