import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FooterCarouselComponent } from '../footer-carousel/footer-carousel.component';
import { ConfigService } from '../../services/config.service';
import { Configuration } from '../../models/config/configuration';
import { CategoryCarouselComponent } from '../category-carousel/category-carousel.component';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Profile } from 'src/app/models/profile';
import { HttpClient } from '@angular/common/http';
import { ProfileComponent } from '../profile/profile.component';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LogService } from 'src/app/services/log.service';
import { Utilities } from 'src/app/helpers/utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  public config!: Configuration ;
  protected isIframe = false;
  public loginDisplay = false;
  public profile: Profile = {};
  public photoUrl: string = "";

  protected readonly _destroying$ = new Subject<void>();
  
  // ShowCategoryCarousel
  @Input()
  public get ShowCategoryCarousel(): boolean {
    return this.showCategoryCarousel;
  }
  public set ShowCategoryCarousel(value: boolean) {
    this.showCategoryCarousel$.next(value);
  }
  protected showCategoryCarousel$: Subject<boolean> = new Subject<boolean>();
  private showCategoryCarouselSubscription!: Subscription;
  private showCategoryCarousel: boolean = false;
  
  // ShowFooter
  @Input() public ShowFooter: boolean = true;
  
  // ScrollableContent
  @Input() public get ScrollableContent(): boolean {
    return this.scrollableContent;
  }
  public set ScrollableContent(value: boolean) {
    this.scrollableContent = value;
    if (this.appContent && this.appContent.style) {
      this.appContent.style.overflow = this.scrollableContent ? 'auto' : 'hidden';
    }
  }
  private scrollableContent: boolean = false;
  
  // HTML Element / Component references
  @ViewChild('footerCarousel')
  private footerCarousel: FooterCarouselComponent | null = null;
  
  @ViewChild('appContent')
  private appContent: HTMLDivElement | null = null;
  
  @ViewChild('appCategory')                                                                 
  public category: CategoryCarouselComponent | null = null;

  @ViewChild('editorModal', { static: true })
  private editorModal!: ModalDirective;

  @ViewChild('profileEditor', { static: true })
  private profileEditor!: ProfileComponent;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private broadcastService: MsalBroadcastService,
              private authService: MsalService, 
              private httpClient: HttpClient,
              private changeDetectorRef: ChangeDetectorRef, 
              private configService: ConfigService,
              private storageService: LocalStorageService,
              private router: Router,
              private cookieService: CookieService,
              private logService: LogService) {
                this.logService.className = "AppComponent";
                this.logService.methodName = "";
               }
  
  // Events
  ngOnInit(): void {
    this.logService.methodName = "ngOnInit()";
    this.showCategoryCarouselSubscription = this.showCategoryCarousel$.subscribe((showCategoryCarousel: boolean) => {
      this.showCategoryCarousel = showCategoryCarousel;
    });
    
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        this.storageService.InitializeStorageSyncListener();
        this.storageService.savePermanentData((<AuthenticationResult>result.payload).idToken, "user:idToken");
        this.storageService.savePermanentData((<AuthenticationResult>result.payload).accessToken, "user:accessToken");
      });
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
      this.logService.methodName = "";
  }

  ngAfterContentChecked(): void {
    this.logService.methodName = "ngAfterContentChecked()";
    this.changeDetectorRef.detectChanges();
    this.logService.methodName = "";
  }
  
  ngOnDestroy(): void {
    this.logService.methodName = "ngOnDestroy()";
    this._destroying$.next(undefined);
    if(this.showCategoryCarouselSubscription) this.showCategoryCarouselSubscription.unsubscribe();
    this._destroying$.complete();
    this.logService.methodName = "";
  }
  
  login() {
    this.logService.methodName = "login()";
    this.logService.log("Auth Request", this.msalGuardConfig.authRequest)
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
    this.logService.methodName = "";
  }

  logout() {
    this.logService.methodName = "logout()"; 
    this.authService.logoutRedirect({
      postLogoutRedirectUri: Utilities.baseUrl()
    });
    this.logService.methodName = "";
  }

  setLoginDisplay() {
    this.logService.methodName = "setLoginDisplay()";
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.logService.log("Retrieving profile data from MSGraph", this.loginDisplay);
      this.httpClient.get('https://graph.microsoft.com/v1.0/me?$select=displayName,id,jobTitle,companyName,mail,officeLocation,photoUrl')
        .subscribe(profile => {
          this.profile = profile;
          this.profileEditor.profile = profile;
          const jsonProfile: string = JSON.stringify(profile);
          // Put profile into cookie
          this.cookieService.set("profile", jsonProfile);
          this.logService.log("Profile data object", profile);
          this.logService.log("Profile data JSON", jsonProfile);
          if(this.ShowFooter && this.footerCarousel != null){
            this.logService.log("ShowFooter == true and Footer Carousel != null");
            // Profile cookie needed to get proper configuration
            this.configService.getConfigAsync().then(config => {
              this.logService.log("Config data retrieved from getConfigAsync()", config);
              this.config = config;
              if( this.footerCarousel != null){
                this.logService.log("Populating footer carousel with config data");
                Object.assign( this.footerCarousel.slideConfig, this.config.footerCarouselConfig);
                Object.assign( this.footerCarousel.slides, this.config.footerCarouselSlideConfig);
                }
            })
          }
          else
            this.logService.log("this.ShowFooter and this.footerCarousel values", this.ShowFooter, this.footerCarousel)

          this.logService.log("Retrieving Profile Photo from MSGraph");
          this.httpClient.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' })
              .subscribe({
              next: photo => {
                this.photoUrl = window.URL.createObjectURL(photo);
                this.profileEditor.photoUrl = this.photoUrl;
                this.logService.log("Photo found for current user", photo, this.photoUrl);
              },  
              error: () => { 
                this.logService.warn('No photo found for the current user');
                this.profile = {};
                this.photoUrl = ""; 
              }
          });
          
          // if officeLocation (landing page) is provided then navigate to its value.
          if(this.profile.officeLocation != null && this.profile.officeLocation != "") {
            this.logService.log("Profile's 'officeLocation' field was populated. Navigating to the value of this field as a landing page", this.profile.officeLocation);
            const router: Router = this.router;
            const landingPage: string = this.profile.officeLocation;
            setTimeout(() => { router.navigate([landingPage]); }, 1000);
          }
        });
    }
    else {
      this.logService.log("loginDisplay == false");
      this.profile = {};
      this.photoUrl = "";
    }
    this.logService.methodName = "";
  }
}