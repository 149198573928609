import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Configuration } from '../../models/config/configuration';
import { AppComponent } from '../app/app.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ConfigurationComponent  implements OnInit {
  protected get config(): Configuration{
    return this.appComponent.config;
  } 
  protected set config(value: Configuration)  {
    this.appComponent.config = value;
  }
  
  @ViewChild('container') 
  protected configTabGroup!: ElementRef;
  protected get appContent(): HTMLElement {return  this.configTabGroup?.nativeElement.parentElement?.parentElement;}
 
  constructor(private appComponent: AppComponent) {}
  
  async ngOnInit(): Promise<void> {
    if(this.appComponent.category)
      this.appComponent.category.value = "Configuration";
    this.appComponent.ScrollableContent = true;
    this.appComponent.ShowFooter = false;
    this.appComponent.ShowCategoryCarousel = false;
  }
}
