import { Component, Input, ViewChild } from '@angular/core';
import { Slide } from '../../models/slide';
import { CarouselConfig } from '../../models/config/carousel-config';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-footer-carousel',
  templateUrl: './footer-carousel.component.html',
  styleUrls: ['./footer-carousel.component.css']
})
export class FooterCarouselComponent {
  
  @ViewChild('slickModal') 
  slickModal!: SlickCarouselComponent;
  
  @Input()
  public slideConfig: CarouselConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1, 
    "dots": true,
    "infinite": true, 
    "autoplay": true, 
    "autoplaySpeed": 10000, 
    "speed": 500, 
    "arrows": true
  };

  @Input()
  public slides : Slide[] = [];
  
  constructor() {  }

  getSlideStyle(slide: Slide) {
    return {
      'color': slide.textColor,
      'background-color': slide.backgroundColor,
      'background-image': slide.image ? `url(${slide.image})` : 'none',
      'background-size': '485px 300px',
    };
  }
}
