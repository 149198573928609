import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AppComponent } from '../app/app.component';
import { CategoryCarouselComponent } from '../category-carousel/category-carousel.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  protected appComponent!: AppComponent;
  private categoryComponent!: CategoryCarouselComponent;
  
  constructor(private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    this.appComponent = this.viewContainerRef.parentInjector.get<AppComponent>(AppComponent); 
    if(this.appComponent && this.appComponent != null){
      //this.appComponent.ShowCategoryCarousel = false;
      //this.appComponent.ScrollableContent = false;
      if(this.appComponent.category && this.appComponent.category != null)
        this.appComponent.category.value = "Cheap Weed Menu";

    }
  }  
}
